<template>
  <div v-resize="onResize">
    <slot name="breadcrumbsWrp"></slot>
    <!-- TABS -->
    <v-tabs
      class="menu-tabs"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5'"
      v-if="viewTabs && frameType === 'tabs'"
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in viewTabs.tabs"
        :key="tab.name"
        :to="{ name: tab.name }"
        :id="tab.name"
        >{{ traduceItem(tab.name) }}</v-tab
      >
    </v-tabs>
    <div v-if="actualGroup">
      <router-view></router-view>
      <v-progress-circular
        v-if="loadingPage && !Array.isArray(dataComponent)"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else>
        <div
          class="d-flex align-center"
          :class="
            !actualView.parent && !(actualView.meta && actualView.meta.parent)
              ? 'justify-end mb-4'
              : 'justify-space-between'
          "
          ref="searchActions"
          v-if="
            (actualView.name !== 'ProcessHistory') || 
            (processData && processData.length && actualView.name === 'ProcessHistory')
          "
        >
          <SearchEngine
            v-if="((actualView.search && dataView && dataView.length && actualView.name !== 'ProcessHistory') || (processData && processData.length && actualView.name === 'ProcessHistory')) && 
            actualView.search"
            :toSearch="toSearch"
            :autofocus="false"
            class="flex-grow-1"
            @search="debounceSearch"
            :results="dataComponent.length"
            :fieldToSearchWhenWriteOnInput="toSearch?.fields[0]?.field"
          />
          <div
            class="name-detail-header"
            v-if="(actualView.meta && actualView.meta.parent && dataView) || (actualView.parent && dataView)"
          >
            {{ dataView.name }}
          </div>
          <Actions
            class="ml-2 mr-0"
            v-if="((actualView.actions && actualView.actions.length) || (actualView.parent && actualView.parent.actions && actualView.parent.actions.length)) && 
                  ((actualView.name === 'ProcessHistory' && processData?.length) || (actualView.name !== 'ProcessHistory'))"
            type="text-buttons"
            :actions="actions"
            @action-selected="onActionSelected"
          ></Actions>
        </div>
         
        <component
          v-if="
            (dataComponent && Array.isArray(dataComponent) && dataComponent.length &&
              (!actualView.meta || (actualView.meta && !actualView.meta.parent))) ||
            (typeof dataComponent === 'object' && Object.keys(dataComponent).length && actualView.meta && actualView.meta.parent) || 
            (actualView.parent)
          "
          :is="moduleComponent"
          :headerHeight="headerHeight"
          :dataComponent="dataComponent"
          @table-action="onActionSelected"
        />       
        <div v-else>
          <NoData :text="configNodata.text" :image="configNodata.image"></NoData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";
import List from "@/components/List";
import ListDetail from "@/components/ListDetail";
import ProcessList from "@/components/ProcessList";
import Detail from "@/components/Detail";
import NoData from "./NoData.vue";
import i18n from "@/plugins/i18n";
import editableDataFields from "@/mixins/editable-data-fields";

export default {
  name: "ViewFrame",
  mixins: [firebaseTools, tools],
  components: {
    ListDetail,
    Detail,
    List,
    ProcessList,
    NoData,
  },
  props: {
    frameType: { type: String },
  },
  data: () => ({
    tab: null,
    viewTabs: undefined,
    timeout: undefined,
    search: undefined,
    dataComponent: undefined,
    searching: false,
    headerHeight: undefined,
  }),
  computed: {
    ...mapState([
      "actualGroup",
      "actualView",
      "loadingPage",
      "dataView",
      "envTheme",
      "headerAndBreadcrumsHeight",
      "processData",
      "inputDefinitionsData",
      "outputDefinitionsData",
      "dictionariesData",
      "listsData",
      "userLogged",
      "unsubscribe",
      "itemToImport"
    ]),
    toSearch() {
      if (this.dataComponent) {
        const isAdvanced = typeof this.actualView.search !== 'boolean';
        return {
          label: i18n.t(`searchLabel.${this.actualView.name}`),
          resultsLabel: i18n.t('resultsFound'),
          selectAllLabel: i18n.t('selectAll'),
          hasAdvancedSearch: isAdvanced,
          fields: !isAdvanced
            ? [
                {
                  field: 'name',
                  label: i18n.t('name'),
                  type: 'text',
                },
              ]
            : this.actualView.search.fields
                .map(({ field, label, type, deepField }) => {
                  const data = [
                    ...new Set(
                      this.dataComponent
                        .map((el) => {
                          if (Array.isArray(el[field]))
                            return el[field].map((elem) => elem[deepField]);
                          else
                            return deepField === 'hashedKey'
                              ? el[field] && Object.values(el[field])
                              : (deepField && el[field][deepField]) ||
                                  el[field];
                        })
                        .filter(Boolean)
                        .flatMap((el) =>
                          (type === 'select'
                            ? (i18n.te(el) && i18n.t(el)) || el
                            : el
                          ).toString()
                        )
                    ),
                  ];

                  if (data.length)
                    return {
                      field,
                      label: i18n.t(label || field),
                      type,
                      data: type !== 'date' && data,
                    };
                  else return;
                })
                .filter(Boolean),
        };
      } else return [];
    },
    configNodata() {
      return {
        text: i18n.t(
          !this.searching ? `noData.${this.actualView.name}` : "noSearchData"
        ),
        image: !this.searching ? "noData" : "search",
      };
    },
    moduleComponent() {
      return this.actualView && this.actualView.nestedComponent
        ? this.actualView.nestedComponent
        : null;
    },
    actions() {
      const actualView = this.actualView.parent || this.actualView
      return this.setActions(actualView.actions.map(obj=>({...obj,disabledTooltip: !!obj.label})));
    },
  },
  methods: {
    ...mapActions([
      "setItemToAddEdit",
      "setItemToConfirmAction",
      "setLoadingPage",
      "setRouteVuex",
      "setDataView",
      "setItemToImport"
    ]),
    onResize() {
      this.$nextTick(() => {
        this.headerHeight =
          this.$refs && this.$refs.searchActions
            ? this.headerAndBreadcrumsHeight +
              this.$refs.searchActions.clientHeight
            : this.headerAndBreadcrumsHeight;
      });
    },
    debounceSearch(selectedValues) {
      this.setDataComponent()
      let fieldsSearched = selectedValues && Object.keys(selectedValues).filter(
        (item) =>  selectedValues[item]?.length && selectedValues[item]
        );
      this.searching = fieldsSearched.length ? true : false;
      if (this.searching) {
        let data = [];
        this.dataComponent.forEach(item => {
          let containField = true;
          fieldsSearched.forEach(field => {
            if(typeof this.dataComponent[0][field] === 'object' && !Array.isArray(item[field]) && item[field]){
              const deepField = this.actualView.search.fields[this.actualView.search.fields.findIndex(el=>el.field===field)]['deepField']
              if(deepField !== 'hashedKey' && field !== 'startDate') {
                if(!item[field][deepField].toLowerCase().includes(selectedValues[field].toLowerCase())) containField=false
                
              } else if (deepField === 'seconds') {
                const rawDate = new Date(item[field][deepField]*1000)
                const month = String(rawDate.getMonth() + 1).padStart(2, "0")
                const day = String(rawDate.getDate()).padStart(2, "0")
                const date = `${rawDate.getFullYear()}-${month}-${day}`
                  if(date !== selectedValues[field]) containField = false
                } else if (deepField === 'hashedKey'){
                if(!Object.values(item[field]).map(el=>el.toLowerCase()).some(el=>el.includes(selectedValues[field].toLowerCase()))) containField=false
                } 
                
              } else  if (typeof selectedValues[field] === "string" && typeof item[field] === "string") {
              if(!item[field].toLowerCase().includes(selectedValues[field].toLowerCase())) containField = false
            } else if (Array.isArray(item[field])) {
             if (Array.isArray(selectedValues[field])) {
              selectedValues[field].forEach(element => {
                if (!item[field].includes(element)) containField = false;
              })
              } else if ((!item[field].includes(selectedValues[field]))) {
              containField = false
              }

            } else if (typeof item[field] === 'string' && Array.isArray(selectedValues[field]) && !selectedValues[field].includes(item[field])) {
              containField = false;
            } else if(!item[field]) {
              containField = false
            } else if(typeof item[field] === 'number'){
              if(selectedValues[field] != item[field]) containField = false
            } 
          })
          if (containField) data.push(item);
        })
        this.dataComponent = data
      } else {
        this.setDataComponent()
      }
    },

    iterateTabs(tabs) {
      for (const tab of tabs) {
        if (tab.name === this.$route.name) return true;
      }
    },
    getTabs(view) {
      if (!view.parent && !view.parent?.tabs) this.viewTabs = view.tabs;
      else if (view.parent && !view.parent.tabs) this.getTabs(view.parent);
    },
    saveData(dictionaries) {
      this.insertDocument(this.actualView.collection, dictionaries).then(
        (response) => {
          console.log(response);
        }
      );
    },
    updateData(documentId, dictionariesUpdated) {
      this.updateDocument(
        this.actualView.collection,
        documentId,
        dictionariesUpdated
      ).then((response) => {
        console.log(response);
      });
    },
    deleteData(documentId) {
      const actualView = this.actualView.parent || this.actualView
      this.deleteDocument(actualView.collection, documentId);
    },
    onActionSelected(event) {
      if(event.action === 'deleteAllProcess') {
        this.checkToken();
        return this.setItemToConfirmAction({
            title: i18n.t('deleteAllProcessTitle'),
            text: i18n.t('deleteAllProcessQuestion'),
            data: {
              action: 'deleteAllProcess'
            }
          })
      } 

      const actualView = this.actualView.parent || this.actualView
      let fields = this.deepCopyFunction(
        editableDataFields.computed[actualView.name]()[event.action]
          ?.fields
      );
      let configEditableDataFields = this.deepCopyFunction(
        editableDataFields.computed[actualView.name]()[event.action]
          ?.config
      );
      let objectItemToAddEdit = {};
      let dataSource = this.getDataSource();
      const actions = [actualView.actions, this.actualView.tableActions, actualView.componentActions].flat().filter(Boolean)
      let actionOnActualView = actions.find(
        (action) => action.action === event.action
      );
      let globalValues =
        actionOnActualView && actionOnActualView.globalValues
          ? this.deepCopyFunction(this.dataView[actionOnActualView.globalValues])
          : this.deepCopyFunction(this.dataView);
      if (actionOnActualView.dynamicRequireds) {
        actionOnActualView.dynamicRequireds.forEach((required) => {
          if (!this.dataView[required.dependsOn]) {
            let findField = fields.find(
              (field) => field.name === required.field
            );
            if (findField.validators)
              findField.validators.required = { msg: i18n.t("required") };
            else
              findField.validators = { required: { msg: i18n.t("required") } };
          }
        });
      }
      let updateValuesFB = actionOnActualView.updateValuesFB
        ? actionOnActualView.updateValuesFB
        : null;
      if (
        event.action === "edit" ||
        event.action === "addColumn" ||
        event.action === "editParent"
      ) {
        if(globalValues && globalValues.length && actionOnActualView?.restOneToPosition) globalValues.forEach(value => value.position = value.position + 1);
        objectItemToAddEdit = {
          section: actualView.name,
          fields,
          configEditableDataFields,
          currentDataValues: this.dataView,
          globalValues,
          dataSource,
          updateValidations: ['checkUniqueValuesValidations'],
          data: {
            params: {
              collectionName: actualView.get[0].collection,
              documentId: this.$router.history.current.params.id,
              updateValuesFB,
              msgAction: event.action,
              addPositionDynamicByDefault:
                  actionOnActualView.addPositionDynamicByDefault ? true : false,
            },
            method: "updateDocument",
          },
        };
      }
      switch (event.action) {
        case "add":
          this.setItemToAddEdit({
            section: actualView.name,
            fields,
            globalValues,
            configEditableDataFields,
            dataSource,
            data: {
              params: { collectionName: actualView.get[0].collection },
              method: "insertDocument",
            },
          });
          break;
        case "delete":
          this.setItemToConfirmAction({
            title: i18n.t(`delete${actualView.name}`),
            text: i18n.t(`delete${actualView.name}Question`),
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                documentId: this.$router.history.current.params.id,
                name: this.dataView.name,
              },
              method: "deleteDocument",
            },
          });
          break;
        case "edit":
        case "addColumn":
        case "editParent":
          if (actionOnActualView && actionOnActualView.requestDataSouce) {
            dataSource[actionOnActualView.requestDataSouce.atributeDataSource] =
              [];
            actionOnActualView.requestDataSouce.requests.forEach(
              (request, index) => {
                  const data = this[
                    request.collection+"Data"
                  ]
                  let dataOfRequest = data.map((element) => {
                    return { name: element.name, id: element.id };
                  });
                  let itemDataSource = actionOnActualView.requestDataSouce.addHeaders ? [
                    { header: request.header },
                    ...dataOfRequest,
                  ] : dataOfRequest;
                  dataSource[
                    actionOnActualView.requestDataSouce.atributeDataSource
                  ] = [
                    ...dataSource[
                      actionOnActualView.requestDataSouce.atributeDataSource
                    ],
                    ...itemDataSource,
                  ];
                  if (
                    actionOnActualView.requestDataSouce.requests.length - 1 ===
                    index
                  ) {
                    this.setItemToAddEdit(objectItemToAddEdit);
                  }
              }
            );
          } else if (
            actionOnActualView &&
            actionOnActualView.requestGlobalValues
          ) {
              const data = this[
                (
                  actionOnActualView.requestGlobalValues.query
                    ? (actionOnActualView.requestGlobalValues.query.value ? "Out" : "In")+ "putDefinitions"
                    : actionOnActualView.requestGlobalValues.collection
                )
                + "Data"
              ]
              objectItemToAddEdit.globalValues = data;
              this.setItemToAddEdit(objectItemToAddEdit);
          } else this.setItemToAddEdit(objectItemToAddEdit);
          break;
        case "goBack":
          if (actualView.meta?.parent) {
            this.unsubscribeElement('Detail')
            this.setRouteVuex(actualView.meta.parent);
          }
          break;
          case "clone":
          if (actionOnActualView && actionOnActualView.requestGlobalValues) {
          this.getAllData(actionOnActualView.requestGlobalValues.collection, actionOnActualView.requestGlobalValues.query)
          .then(data=>{
              this.setItemToAddEdit({
                section: actualView.name,
                fields,
                globalValues:data,
                currentDataValues: {...this.dataView, name: this.setCloneName(this.dataView, data)},
                configEditableDataFields,
                dataSource,
                cloneItem:true,
                data: {
                  params: { collectionName: actualView.get[0].collection },
                  method: "insertDocument",
                },
              })
            })   
          }
          break;
          case 'importColumns':
            this.checkToken();
            this.setItemToImport({
              config: {
                title: i18n.t('importColumns'),
                secondaryButton: {
                  icon: "mdi-close",
                  text: i18n.t("cancel")
                },
                primaryButton: {
                  icon: "mdi-cloud-upload",
                  text: i18n.t('import')
                }
              },
              currentDataValues: {
                fileType: null,
                sheetName: null,
                alias: null,
                position: null,
                nullable: null,
                dataType: null,
                filterColumnName: null,
                filterValue: null
              }
            })
          break;
      }
    },
     setDataComponent(){
      if (this.actualView.name === "ProcessHistory") {
        this.dataComponent = this.processData
        this.setLoadingPage(false)
        this.unsubscribeElement('Detail')   
      } else if (!this.actualView.meta?.parent && !this.actualView.parent) {
        this.dataComponent  = this[this.actualView.name.charAt(0).toLowerCase() + this.actualView.name.slice(1) + "Data"]
        this.unsubscribeElement('Detail')
      } else {
        this.dataComponent = this.dataView
      }
    },
  },
  watch: {
    loadingPage(value) {
      if (!value) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.onResize();
        }, 1);
      }
    },
    processData() {
      if (this.processData)
        this.setDataComponent();
      else  this.dataComponent = undefined;
    },
    dataView() {
      if (this.dataView)
        this.setDataComponent();
      else  this.dataComponent = undefined;
    },
    actualView(newView, oldView) {
      if (newView.parent.name !== oldView.parent.name) this.tab = 0;
      this.getTabs(this.actualView);
    },
    userLogged(){
      this.dataComponent = undefined
    },
    actualGroup(){
      this.searching = false
    }
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  mounted() {
    this.setDataComponent();
    this.getTabs(this.actualView);
    if (this.actualView.parent && this.actualView.parent.tabs) {
      this.tab = this.actualView.parent.tabs.indexOf(this.actualView);
    } 
    if(!this.actualView.meta?.parent && !this.actualView.parent) this.setDataView(this[this.actualView.name.charAt(0).toLowerCase()+this.actualView.name.slice(1)+"Data"])
  },
};
</script>
<style lang="scss" >
.v-main {
  color: var(--fontColor);
}
.name-detail-header {
  color: var(--primary);
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
