<template>
  <section class="info-wrp">
    <div
      v-if="
        createKeyValueListModel(item).defaultItems &&
        createKeyValueListModel(item).defaultItems.length
      "
      class="d-flex flex-wrap flex-row justify-content-center"
    >
      <v-col
        cols="4"
        v-for="(element, index) in createKeyValueListModel(item).defaultItems"
        :key="index"
      >
        <div class="mr-1 expandible-key">
          {{ element.key }}:
          <span
            class="valueOfKey"
            v-if="
              typeof element.value === 'string' ||
              typeof element.value === 'number'
            "
          >
            {{ element.value !== "" ? element.value : "--" }}
          </span>
          <v-switch
            class="toogle ml-1 d-inline-flex"
            v-else
            v-model="element.value"
            hide-details
            readonly
          ></v-switch>
        </div>
      </v-col>
    </div>
    <p
      v-if="item && (item.validationOptionalType || item.allowedValuesValidation || item.range)"
      class="expandibleSubtitle mb-4 "
      :class="{'single-subtitle': item &&( ['Double', 'Integer'].includes(item.dataType) || item.decimalSeparator || item.datePattern)}"
    >
      {{$t('validationOf', { validation: (item.range ? $t('range'): $t('allowedValuesValidation')).toLowerCase() })}}
    </p>
    <div
      class="d-flex flex-wrap flex-row justify-content-center"
      v-if="
        createKeyValueListModel(item).section.values &&
        createKeyValueListModel(item).section.values.length
      "
      :class="{'expandibleHolder': item && item.validationOptionalType}"
    >
      <v-col
        cols="4"
        v-for="(element, index) in createKeyValueListModel(item).section.values"
        :key="index"
      >
        <div class="expandible-key my-2">
          {{ element.key }}:
          <span
            class="valueOfKey"
            v-if="
              typeof element.value === 'string' ||
              typeof element.value === 'number'
            "
          >
            {{ element.value !== "" ? element.value : "--" }}
          </span>
          <v-switch
            class="toogle ml-1"
            v-else
            v-model="element.value"
            readonly
            hide-details
          ></v-switch>
        </div>
      </v-col>
    </div>
    <div
      v-if="
        createKeyValueListModel(item).defaultItems &&
        createKeyValueListModel(item).section.values &&
        !createKeyValueListModel(item).defaultItems.length &&
        !createKeyValueListModel(item).section.values.length
      "
    >
      <!--JOINCOLUMNS-->
      <div v-if="item.joinColumns">
        <div
          v-for="(column, index) in item.joinColumns"
          :key="index"
          :class="[
            item.joinColumns &&
            item.joinColumns.length &&
            index !== item.joinColumns.length - 1
              ? 'borderBottomExpandible'
              : '',
            index && 'expandibleHolder',
          ]"
        >
          <p class="expandibleTitle">
            {{ traduceItem("value") }} {{ index + 1 }}
          </p>
          <p class="expandibleSubtitle">
            {{ traduceItem("assignedValueBy") }}
            {{ traduceItem(setAssignedValue(column)) }}
          </p>
          <p
            v-if="
              createKeyValueListModel(column).defaultItems &&
              createKeyValueListModel(column).defaultItems.length
            "
          >
            <v-col
              cols="4"
              v-for="(element, index) in createKeyValueListModel(column)
                .defaultItems"
              :key="index"
            >
              <div class="mr-1 expandible-key my-2">
                {{ element.key }}:
                <span
                  class="valueOfKey"
                  v-if="
                    typeof element.value === 'string' ||
                    typeof element.value === 'number'
                  "
                >
                  {{ element.value !== "" ? element.value : "--" }}
                </span>
                <v-switch
                  class="toogle ml-1"
                  v-else
                  v-model="element.value"
                  readonly
                  hide-details
                ></v-switch>
              </div>
            </v-col>
          </p>
          <p
            class="d-flex flex-wrap flex-row justify-content-center"
            v-if="
              createKeyValueListModel(column).section.values &&
              createKeyValueListModel(column).section.values.length
            "
          >
            <v-col
              cols="4"
              v-for="(element, index) in createKeyValueListModel(column).section
                .values"
              :key="index"
            >
              <p class="expandible-key my-2">
                {{ element.key }}:
                <span
                  class="valueOfKey"
                  v-if="
                    typeof element.value === 'string' ||
                    typeof element.value === 'number'
                  "
                >
                  {{ element.value !== "" ? element.value : "--" }}
                </span>
                <v-switch
                  class="toogle ml-1"
                  v-else
                  v-model="element.value"
                  readonly
                  hide-details
                ></v-switch>
              </p>
            </v-col>
          </p>
        </div>
      </div>
      <!--FILTROS/CONDICIONALES-->
      <div
        v-else-if="item.conditionals && item.conditionals !== '--'"
      >
        <div
          v-for="(conditional, index) in item.conditionals"
          :key="index"
          :class="[
            item.conditionals &&
            item.conditionals.length &&
            index !== item.conditionals.length - 1
              ? 'borderBottomExpandible'
              : '',
            index && 'expandibleHolder',
          ]"
        >
          <p class="expandibleTitle">{{ traduceItem("case") }} {{ index + 1 }}</p>
          <div>
            <p class="expandibleSubtitle">{{ traduceItem("conditions") }}</p>
            <p v-if="conditional.conditions && conditional.conditions.length">
              <v-chip
                v-for="(condition, i) in conditional.conditions"
                :key="i"
                class="mr-2"
                :text-color="'var(--fontColor)'"
              >
                {{ formatCondition(condition) }}
              </v-chip>
            </p>
            <p v-else style="color:var(--fontColorTerciary)">
              {{ traduceItem("noConditionsApplied") }}
            </p>
            <div
              v-if="
                Object.values(conditional.conditionalColumn).some(
                  (val) => val !== null && typeof val !== 'boolean'
                )
              "
            >
              <p class="expandibleSubtitle">
                {{ traduceItem("assignedValueBy") }}
                {{ traduceItem(setAssignedValue(conditional.conditionalColumn)) }}
              </p>
              <!--KEYVALUE-->
              <p
                v-if="
                  createKeyValueListModel(conditional.conditionalColumn)
                    .defaultItems &&
                  createKeyValueListModel(conditional.conditionalColumn)
                    .defaultItems.length
                "
              >
                <v-col
                  cols="4"
                  v-for="(element, index) in createKeyValueListModel(
                    conditional.conditionalColumn
                  ).defaultItems"
                  :key="index"
                >
                  <div class="mr-1 expandible-key my-2">
                    {{ element.key }}:
                    <span
                      class="valueOfKey"
                      v-if="
                        typeof element.value === 'string' ||
                        typeof element.value === 'number'
                      "
                    >
                      {{ element.value !== "" ? element.value : "--" }}
                    </span>
                    <v-switch
                      class="toogle ml-1"
                      v-else
                      v-model="element.value"
                      readonly
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>
              </p>
              <p
                class="d-flex flex-wrap flex-row justify-content-center"
                v-if="
                  createKeyValueListModel(conditional.conditionalColumn).section
                    .values &&
                  createKeyValueListModel(conditional.conditionalColumn).section
                    .values.length
                "
              >
                <v-col
                  cols="4"
                  v-for="(element, index) in createKeyValueListModel(
                    conditional.conditionalColumn
                  ).section.values"
                  :key="index"
                >
                  <div class="expandible-key my-2">
                    {{ element.key }}:
                    <span
                      class="valueOfKey"
                      v-if="
                        typeof element.value === 'string' ||
                        typeof element.value === 'number'
                      "
                    >
                      {{ element.value !== '' ? element.value : '--' }}
                    </span>
                    <v-switch
                      class="toogle ml-1"
                      v-else
                      v-model="element.value"
                      readonly
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>
              </p>
              <!--JOINCOLUMNS IN CONDITIONALS-->
              <div v-if="conditional.conditionalColumn.joinColumns">
                <div
                  v-for="(column, index) in conditional.conditionalColumn
                    .joinColumns"
                  :key="index"
                  class="mt-6 conditionalJoinColumn"
                >
                  <p style="font-weight: 500; font-size: 15px">
                    {{ traduceItem("value") }} {{ index + 1 }}
                  </p>
                  <p class="expandibleSubtitle">
                    {{ traduceItem("assignedValueBy") }}
                    {{ traduceItem(setAssignedValue(column)) }}
                  </p>
                  <p
                    v-if="
                      createKeyValueListModel(column).defaultItems &&
                      createKeyValueListModel(column).defaultItems.length
                    "
                  >
                    <v-col
                      cols="4"
                      v-for="(element, index) in createKeyValueListModel(column)
                        .defaultItems"
                      :key="index"
                    >
                      <div class="mr-1 expandible-key my-2">
                        {{ element.key }}:
                        <span
                          class="valueOfKey"
                          v-if="
                            typeof element.value === 'string' ||
                            typeof element.value === 'number'
                          "
                        >
                          {{ element.value !== "" ? element.value : "--" }}
                        </span>
                        <v-switch
                          class="toogle ml-1"
                          v-else
                          v-model="element.value"
                          readonly
                          hide-details
                        ></v-switch>
                      </div>
                    </v-col>
                  </p>
                  <p
                    class="d-flex flex-wrap flex-row justify-content-center"
                    v-if="
                      createKeyValueListModel(column).section.values &&
                      createKeyValueListModel(column).section.values.length
                    "
                  >
                    <v-col
                      cols="4"
                      v-for="(element, index) in createKeyValueListModel(column)
                        .section.values"
                      :key="index"
                    >
                      <p class="expandible-key my-2">
                        {{ element.key }}:
                        <span
                          class="valueOfKey"
                          v-if="
                            typeof element.value === 'string' ||
                            typeof element.value === 'number'
                          "
                        >
                          {{ element.value !== "" ? element.value : "--" }}
                        </span>
                        <v-switch
                          class="toogle ml-1"
                          v-else
                          v-model="element.value"
                          readonly
                          hide-details
                        ></v-switch>
                      </p>
                    </v-col>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        {{ traduceItem("noDataInfoColumn") }}
      </div>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugins/i18n";
import { tools } from "@/mixins/tools";
import { mapState } from "vuex";

export default {
  name: "ExtraInformation",
  mixins: [ tools ],
  props: {
    item: {required: false},
    dataOfRequests: {required: false}
  },
  data: () => ({
    defaultInConfiguration: [
      {
        value: "datePattern",
        dependsOn: ["Date"],
        fieldDependsOn: "dataType",
      },
      {
        value: "decimalSeparator",
        dependsOn: [ "Double", "Percentage"],
        fieldDependsOn: "dataType",
      },
      {
        value: "sumValues",
        dependsOn: ["Double", "Integer", "Percentage"],
        fieldDependsOn: "dataType",
      },
    ],
    sectionInConfiguration: [
      {
        label: "rangeValidation",
        dependsOn: "range",
        values: [
          { value: "from", accessValue: "range" },
          { value: "to", accessValue: "range" },
          { value: "type", accessValue: "range", label: "rangeType" },
        ],
      },
      {
        label: "validationOfAllowedValues",
        dependsOn: "allowedValuesValidation",
        values: [
          {
            value: "allowedValuesValidation",
            findOnRequest: "id",
            accessValue: "allowedValuesValidation",
            attributeRequest: "name",
          },
        ],
      },
    ],
    defaultOutConfiguration: [
      { 
        value: "datePattern" 
      }, 
      { 
        value: "decimalSeparator" 
      },
      { 
        value: "alias", 
        label: "COLUMN_NAME"
      }
    ],
    sectionOutConfiguration: [
      {
        label: "parametersTransfDictionary",
        dependsOn: "dictionaryTransformation",
        values: [
          {
            label: 'inputColumnName',
            accessValue: "dictionaryTransformation",
            value: "columnName"
          },
          {
            value: "dictionaryTransformation",
            findOnRequest: "id",
            attributeRequest: "name",
            accessValue: "dictionaryTransformation",
          },
        ],
      },
      {
        label: "addTimeStepTransformation",
        dependsOn: "addTimeStepTransformation",
        values: [
          {
            value: "dateColumnName",
            label: "inputColumnName",
            accessValue: "addTimeStepTransformation",
          },
          {
            value: "timeStepColumnName",
            accessValue: "addTimeStepTransformation",
          },
          {
            value: "timeStepValue",
            accessValue: "addTimeStepTransformation",
          },
          {
            value: "arithmeticOperator",
            accessValue: "addTimeStepTransformation",
          },
          {
            value: "limitDate",
            accessValue: "addTimeStepTransformation"
          },
          {
            dynamicLabel: 'option',
            accessValue: 'addTimeStepTransformation',
            nestedAccess: 'tenorReference',
            value: 'value',
          },
          {
            label: "targetDate",
            accessValue: 'addTimeStepTransformation',
            nestedAccess: 'limitDateReference',
            value: 'value',
          }
        ],
      },
      {
        label: "columnReference",
        dependsOn: "columnReference",
        values: [
          {
            accessValue: 'columnReference',
            nestedAccess: 'option',
            value: 'value',
            dynamicLabel: 'option'
          }
        ]
      },
    ],
  }),
  computed: {
    ...mapState(["actualView"]),
    expandibleElements () {
      return {
        columnsIn: {
          default: this.defaultInConfiguration,
          section: this.sectionInConfiguration,
        },
        filters: {
          default: [],
          section: [],
        },
        columnsOut: {
          default: this.defaultOutConfiguration,
          section: this.sectionOutConfiguration,
        },
        splitConfiguration: {
          default: this.defaultOutConfiguration,
          section: this.sectionOutConfiguration,
        },
      };
    },
  },
  methods: {
    createKeyValueListModel(item) {
      let copyItem = this.deepCopyFunction(item);
      let model = { defaultItems: [], section: { values: [] } };
      const { default:defaultConfig, section:sectionConfig } = this.expandibleElements[this.actualView.name];
      defaultConfig.forEach(
        (element) => {
          const { fieldDependsOn, dependsOn, value, label } = element
          if (
            ((fieldDependsOn &&
              dependsOn?.includes(copyItem[fieldDependsOn])) ||
              !fieldDependsOn) &&
            value in copyItem &&
            (copyItem[value] ||
              typeof copyItem[value] === "boolean")
          ) {
            model.defaultItems.push({
              key: i18n.t(label ? label : value),
              value:
                i18n.te(copyItem[value])
                  ? i18n.t(copyItem[value])
                  : copyItem[value],
            });
          }
        }
      );
      let sectionValues = sectionConfig.find((section) => copyItem[section.dependsOn]);
      if (sectionValues) {
        sectionValues.values.forEach((element) => {
          if (
            ("accessValue" in element &&
              copyItem[element.accessValue] &&
              (copyItem[element.accessValue][element.value] ||
                copyItem[element.accessValue][element.value] === '' ||
                copyItem[element.accessValue][element.value] === 0 ||
                typeof copyItem[element.accessValue][element.value] ===
                  "boolean")) ||
            (element.value in copyItem &&
              (copyItem[element.value] ||
                typeof copyItem[element.value] === "boolean"))
          ) {
            let valueItem;
            if (element.findOnRequest) {
              if (this.dataOfRequests && this.dataOfRequests?.length) {
                valueItem = this.dataOfRequests.find(
                  (req) =>  req[element.findOnRequest] ===
                    ("accessValue" in element
                      ? copyItem[element.accessValue][element.findOnRequest]
                      : copyItem[element.findOnRequest])
                )[element.attributeRequest];
              }
            } else {
              valueItem =
                "accessValue" in element
                  ? copyItem[element.accessValue][element.value]
                  : copyItem[element.value];
            }
            model.section.values.push({
              key: i18n.t(copyItem[element.accessValue][element.dynamicLabel] || element.label || element.value),
              value:
                i18n.te(valueItem) ? i18n.t(valueItem) : valueItem,
            });
          } else if(element.nestedAccess && copyItem[element.accessValue] && copyItem[element.accessValue][element.nestedAccess] && (copyItem[element.accessValue][element.nestedAccess][element.value] || copyItem[element.accessValue][element.nestedAccess][element.value] === '')) {
            const dynamicLabel = () => {
              const hashMap = {
                COLUMN_NAME: "nameOfTenorTypeColumn",
                INPUT_VALUE: "fixedValueForTenor",
                WILDCARD: "wildcard"
              }
              return hashMap[copyItem[element.accessValue][element.nestedAccess][element.dynamicLabel]] || undefined
            }
            model.section.values.push({
              key: i18n.t(dynamicLabel() || element.label || element.value),
              value: copyItem[element.accessValue][element.nestedAccess][element.value]
            })
          }
        });
        if (model.section.values.length)
          model.section.label = sectionValues.label;
      }
      return model;
    },
    formatCondition({initialColumnName, comparisonOperator, endValueReference}) {
      const formatedOperatorMap = {
        NOT_EMPTY: i18n.t("NOT_EMPTY"),
        EMPTY: i18n.t("EMPTY"),
        EQUAL_TO: "=",
        NOT_EQUAL: "!=",
        GREATER_THAN: ">",
        LESS_THAN: "<",
        GREATER_THAN_OR_EQUAL_TO: ">=",
        LESS_THAN_OR_EQUAL_TO: "<=",
        REGULAR_EXPRESSION: i18n.t("REGULAR_EXPRESSION")
      };
      return `${initialColumnName} ${formatedOperatorMap[comparisonOperator]} ${
        endValueReference ? (endValueReference.value || '--') : ''
      }`;
    },
    setAssignedValue(item) {
      let value;
      if (item.conditionals && item.conditionals !== '--') value = 'eachCase'
      else value = ['addTimeStepTransformation', 'dictionaryTransformation', 'columnReference', 'joinColumns']
                    .map(el => item[el]
                      ? el !== 'columnReference'
                        ? el === 'joinColumns'
                          ? item[el].length && el
                          : el
                        : item[el]['option']
                      : null).filter(Boolean)[0]
      if (!value) value = 'COLUMN_NAME'
      return value
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrp {
  font-size: 14px;
}
.col {
  padding: 0px;
}
.expandible-key {
  color: var(--fontColorTerciary);
}
.borderBottomExpandible {
  border-bottom: solid 1px var(--borderGray);
}
.expandibleHolder {
  margin-top: 18px;
  p:last-child {
    margin-bottom: 0px;
  }
}
.conditionalJoinColumn {
  p:last-child {
    margin-bottom: 0px;
  }
}
.expandibleTitle {
  font-size: 19px;
}
.valueOfKey {
  color: var(--fontColor);
}
.expandibleSubtitle {
  font-size: 15px;
  color: var(--tertiary);
  font-weight: 500;
  &.single-subtitle {
    margin-top: 20px;
  }
}
::v-deep {
  .toogle {
    margin: 0px;
    padding: 0px;
    height: 20px;
    vertical-align: text-bottom;
  }
}
</style>