import i18n from "@/plugins/i18n";

export default {
  computed: {
    primaryButtonAdd() {
      return {
        icon: "mdi-plus",
        text: i18n.t("add"),
      };
    },
    secondaryButton() {
      return {
        icon: "mdi-close",
        text: i18n.t("cancel"),
      };
    },
    primaryButtonEdit() {
      return {
        icon: "mdi-content-save",
        text: i18n.t("save"),
      };
    },
    InputDefinitionsDetail() {
      return {
        addFilter: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addFilters"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          steps: [
            {
              title: i18n.t("indicateFilterName"),
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("filterName"),
                    value: "filterName"
                  }
                ]
              },
              fields: [
                {
                  name: "filterName",
                  label: i18n.t("filterName"),
                  fieldType: "textBox",
                  validators: {
                    required: {msg: i18n.t("required")},
                    alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                  }
                },
              ],
            },
            {
              title: i18n.t("defineConditions"),
              dynamicForm: {
                title: i18n.t("conditions"),
                namesForm: i18n.t("condition"),
                forms: [i18n.t("condition") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsFilters"),
                showChips: true,
                chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                fields: [
                  {
                    name: "alias",
                    label: i18n.t("alias"),
                    fieldType: "selectBox",
                    componentDataSource: [
                      {name: 'columns', containedIn: 'object'},
                      {name: 'alias', containedIn: 'objectArray'}
                    ],
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                    }
                  },
                  {
                    name: "conditional",
                    label: i18n.t("conditional"),
                    fieldType: "selectBox",
                    responseDataSource: "filterConditionals",
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")}
                    }
                  },
                  {
                    name: "secondTerm",
                    label: i18n.t("secondTerm"),
                    hideTitle:true,
                    fieldType: "radioGroup",
                    responseDataSource: 'termsType',
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "conditional",
                          type: "selectBox",
                          values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                        },
                      ]
                    },
                    optionsToCheck: [
                      {
                        field: "columnAlias",
                        value: "columnAlias"
                      },
                      {
                        field: "fixedValue",
                        value: "fixedValue",
                        setWhenValue: ''
                      },
                      {
                        field: "wildcard",
                        value: "wildcard"
                      },
                    ],
                  },
                  {
                    name: 'columnAlias',
                    label: i18n.t("columnAlias"),
                    fieldType: 'selectBox',
                    componentDataSource: [
                      {name: 'columns', containedIn: 'object'},
                      {name: 'alias', containedIn: 'objectArray'}
                    ],
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "columnAlias",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                ],
              },
            },
          ],
        },
        editFilter: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editFilters"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          steps: [
            {
              title: i18n.t("indicateFilterName"),
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("filterName"),
                    value: "filterName"
                  }
                ]
              },
              fields: [
                {
                  name: "filterName",
                  label: i18n.t("filterName"),
                  fieldType: "textBox",
                  validators: {
                    required: {msg: i18n.t("required")},
                    alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                  }
                },
              ],
            },
            {
              title: i18n.t("defineConditions"),
              dynamicForm: {
                title: i18n.t("conditions"),
                namesForm: i18n.t("condition"),
                forms: [i18n.t("condition") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsFilters"),
                showChips: true,
                chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                fields: [
                  {
                    name: "alias",
                    label: i18n.t("alias"),
                    fieldType: "selectBox",
                    componentDataSource: [{name: 'columns', containedIn: 'object'},{name: 'alias', containedIn: 'objectArray'}],
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")}
                    }
                  },
                  {
                    name: "conditional",
                    label: i18n.t("conditional"),
                    fieldType: "selectBox",
                    responseDataSource: "filterConditionals",
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")}
                    }
                  },
                  {
                    name: "secondTerm",
                    label: i18n.t("secondTerm"),
                    hideTitle:true,
                    fieldType: "radioGroup",
                    responseDataSource: 'termsType',
                    optionsToCheck: [
                      {
                        field: "columnAlias",
                        value: "columnAlias"
                      },
                      {
                        field: "fixedValue",
                        value: "fixedValue",
                        setWhenValue: ''
                      },
                      {
                        field: "wildcard",
                        value: "wildcard"
                      },
                    ],
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "conditional",
                          type: "selectBox",
                          values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                        },
                      ]
                    },
                  },
                  {
                    name: 'columnAlias',
                    label: i18n.t("columnAlias"),
                    fieldType: 'selectBox',
                    componentDataSource: [
                      {name: 'columns', containedIn: 'object'},
                      {name: 'alias', containedIn: 'objectArray'}
                    ],
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "columnAlias",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      showInChips:true,
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "secondTerm",
                          type: "radioGroup",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                ],
              },
            },
          ],
        }
      };
    },
    OutputDefinitionsDetail() {
      return {
        editColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editColumn"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          steps: [
            {
              title: i18n.t("indicateColumnName"),
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("name"),
                    value: "nameColumn"
                  }
                ]
              },
              fields: [
                {
                  name: "nameColumn",
                  label: i18n.t("nameColumn"),
                  fieldType: "textBox",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCreateColumn"),
              nativeForm: true,
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("createColumn"),
                    value: "defineCreateColumn",
                    translateValue: true
                  }
                ]
              },
              fields: [
                {
                  name: "defineCreateColumn",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'withOutConditions', name: i18n.t("withOutConditions")}, {value: 'withConditions', name: i18n.t("withConditions")}],
                  optionsToCheck: [
                    {
                      field: "withOutConditions",
                      value: "withOutConditions"
                    },
                    {
                      field: "withConditions",
                      value: "withConditions"
                    }
                  ],
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              submitForm: 'normalForm',
              dependsOn: [
                {
                  step: "defineCreateColumn",
                  value: "withOutConditions"
                }
              ],
              dynamicForm: {
                title: i18n.t("valuesToConcatenate"),
                namesForm: i18n.t("value"),
                forms: [i18n.t("value") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsColumns"),
                dependsOn: [
                  {
                    field: "assignValue",
                    value: "concatenateValues"
                  }
                ],
                fields: [
                  {
                    name: "assignValueDynamic",
                    label: i18n.t("assignValue"),
                    fieldType: "selectBox",
                    responseDataSource: "assignValuesWithOutConcatenate",
                    validators: {
                      required: {msg: i18n.t("required")},
                    }
                  },
                  {
                    name: 'columnReferenced',
                    label: i18n.t("columnReferenced"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "columnReference",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                  {
                    name: 'columnNameDictionary',
                    label: i18n.t("inputColumnName"),
                    fieldType: 'selectBox',
                    fieldTypeIfNotDatasource: 'textBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'dictionary',
                    label: i18n.t("dictionary"),
                    fieldType: 'selectBox',
                    responseDataSource:'dictionariesData',
                    returnObject: true,
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'inputColumnName',
                    label: i18n.t("inputColumnName"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: 'operator',
                    label: i18n.t("operator"),
                    fieldType: 'selectBox',
                    responseDataSource:'operators',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: "radioGroupAddTenor",
                    hideTitle: true,
                    fieldType: "radioGroup",
                    dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOption', name: i18n.t("wildcard")}],
                    optionsToCheck: [
                      {
                        field: "nameOfTenorTypeColumn",
                        value: "nameOfTenorTypeColumn"
                      },
                      {
                        field: "fixedValueForTenor",
                        value: "fixedValueForTenor",
                        setWhenValue: ''
                      },
                      {
                        field: "wildcardOption",
                        value: "wildcardOption"
                      }
                    ],
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  fieldTypeIfNotDatasource: 'textBox',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcardOption",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  fieldTypeIfNotDatasource: 'textBox',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
                ]
              },
              fields: [
                {
                  name: "assignValue",
                  label: i18n.t("assignValue"),
                  fieldType: "selectBox",
                  responseDataSource: "assignValues",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
                {
                  name: 'columnReferenced',
                  label: i18n.t("columnReferenced"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "columnReference",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcard',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValue',
                  label: i18n.t("fixedValue"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "fixedValue",
                      },
                    ]
                  }
                },
                {
                  name: 'columnNameDictionary',
                  label: i18n.t("inputColumnName"),
                  fieldType: 'selectBox',
                  fieldTypeIfNotDatasource: 'textBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'dictionary',
                  label: i18n.t("dictionary"),
                  fieldType: 'selectBox',
                  responseDataSource:'dictionariesData',
                  returnObject: true,
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'inputColumnName',
                  label: i18n.t("inputColumnName"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'operator',
                  label: i18n.t("operator"),
                  fieldType: 'selectBox',
                  responseDataSource:'operators',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenor",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOption', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfTenorTypeColumn",
                      value: "nameOfTenorTypeColumn"
                    },
                    {
                      field: "fixedValueForTenor",
                      value: "fixedValueForTenor",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOption",
                      value: "wildcardOption"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcardOption",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  fieldTypeIfNotDatasource: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCases"),
              dependsOn: [
                {
                  step: "defineCreateColumn",
                  value: "withConditions"
                }
              ],
              submitForm: 'bottomFields',
              dynamicFormGroup: {
                namesFormGroup: i18n.t("case"),
                alertLabel: i18n.t("reviewValidationsColumns"),
                forms: [
                  {
                    title: i18n.t("case") + ' ' + 1,
                    dynamicForm: {
                      title: i18n.t("caseConditions"),
                      newFormEmpty: true,
                      labelNewFormEmpty: i18n.t("notConditionsApllied"),
                      alertInfoNewFormEmpty: i18n.t("alertInfoConditionsEmpty"),
                      alertErrorNewFormEmpty: i18n.t("alertErrorConditionsEmpty"),
                      namesForm: i18n.t("condition"),
                      forms: [i18n.t("condition") + ' ' + 1],
                      showChips: true,
                      chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                      fields: [
                        {
                          name: "firstTerm",
                          label: i18n.t("firstTermField"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            showInChips:true
                          }
                        },
                        {
                          name: "conditional",
                          label: i18n.t("conditional"),
                          fieldType: "selectBox",
                          responseDataSource: "filterConditionals",
                          validators: {
                            required: {msg: i18n.t("required")},
                            showInChips:true
                          }
                        },
                        {
                          name: "secondTerm",
                          label: i18n.t("secondTerm"),
                          hideTitle:true,
                          fieldType: "radioGroup",
                          responseDataSource: 'termsTypeColumn',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "conditional",
                                type: "selectBox",
                                values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                              },
                            ]
                          },
                          optionsToCheck: [
                            {
                              field: "inputColumnName",
                              value: "inputColumnName"
                            },
                            {
                              field: "fixedValue",
                              value: "fixedValue",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcard",
                              value: "wildcard"
                            },
                          ],
                        },
                        {
                          name: 'inputColumnName',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            showInChips:true,
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "inputColumnName",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValue',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            showInChips:true,
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcard',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                      ]
                    },
                    bottomFields:  [
                      {
                        name: "indicateHowToAssingValueCase",
                        label: i18n.t("indicateHowToAssingValueCase"),
                        fieldType: "sectionTitle",
                      },
                      {
                        name: "assignValueConditions",
                        label: i18n.t("assignValue"),
                        fieldType: "selectBox",
                        responseDataSource: "assignValues",
                        validators: {
                          required: {msg: i18n.t("required")},
                        }
                      },
                      {
                        name: 'columnReferenced',
                        label: i18n.t("columnReferenced"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "columnReference",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValue',
                        label: i18n.t("fixedValue"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "fixedValue",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcard',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: 'columnNameDictionary',
                        label: i18n.t("inputColumnName"),
                        fieldType: 'selectBox',
                        fieldTypeIfNotDatasource: 'textBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'dictionary',
                        label: i18n.t("dictionary"),
                        fieldType: 'selectBox',
                        responseDataSource:'dictionariesData',
                        returnObject: true,
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'inputColumnName',
                        label: i18n.t("inputColumnName"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'operator',
                        label: i18n.t("operator"),
                        fieldType: 'selectBox',
                        responseDataSource:'operators',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenor",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOption', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfTenorTypeColumn",
                            value: "nameOfTenorTypeColumn"
                          },
                          {
                            field: "fixedValueForTenor",
                            value: "fixedValueForTenor",
                            setWhenValue: ''
                          },
                          {
                            field: "wildcardOption",
                            value: "wildcardOption"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfTenorTypeColumn',
                        label: i18n.t("nameOfTenorTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedTenor',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "nameOfTenorTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForTenor',
                        label: i18n.t("fixedValueForTenor"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "fixedValueForTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOption',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "wildcardOption",
                            },
                          ]
                        }
                      },
                      {
                        name: "indicateDateToReach",
                        label: i18n.t("indicateDateToReach"),
                        fieldType: "sectionTitle",
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenorTypeDate",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfDateTypeColumn",
                            value: "nameOfDateTypeColumn"
                          },
                          {
                            field: "fixedValueForDate",
                            value: "fixedValueForDate",
                            setWhenValue: ''
                          },
                          {
                            field: "wildcardOptionDate",
                            value: "wildcardOptionDate"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfDateTypeColumn',
                        label: i18n.t("nameOfDateTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedDate',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "nameOfDateTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForDate',
                        label: i18n.t("fixedValueForDate"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "fixedValueForDate",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOptionDate',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcardsToday',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "wildcardOptionDate",
                            },
                          ]
                        }
                      },
                    ],
                    dynamicBottomForm: {
                      dependsOn: [
                        {
                          field: "assignValueConditions",
                          value: "concatenateValues"
                        }
                      ],
                      title: i18n.t("valuesToConcatenate"),
                      namesForm: i18n.t("value"),
                      forms: [i18n.t("value") + ' ' + 1],
                      fields: [
                        {
                          name: "assignValueBottomDynamic",
                          label: i18n.t("assignValue"),
                          fieldType: "selectBox",
                          responseDataSource: "assignValuesWithOutConcatenate",
                          validators: {
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: 'columnReferencedBottomDynamic',
                          label: i18n.t("columnReferenced"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "columnReference",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueBottomDynamic',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                        {
                          name: 'columnNameDictionaryBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldType: 'selectBox',
                          fieldTypeIfNotDatasource: 'textBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'dictionaryBottomDynamic',
                          label: i18n.t("dictionary"),
                          fieldType: 'selectBox',
                          responseDataSource:'dictionariesData',
                          returnObject: true,
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'inputColumnNameBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'operatorBottomDynamic',
                          label: i18n.t("operator"),
                          fieldType: 'selectBox',
                          responseDataSource:'operators',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfTenorTypeColumnBottomDynamic', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenorBottomDynamic', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOptionBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfTenorTypeColumnBottomDynamic",
                              value: "nameOfTenorTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForTenorBottomDynamic",
                              value: "fixedValueForTenorBottomDynamic",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcardOptionBottomDynamic",
                              value: "wildcardOptionBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfTenorTypeColumnBottomDynamic',
                          fieldTypeIfNotDatasource: 'textBox',
                          label: i18n.t("nameOfTenorTypeColumn"),
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedTenor',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfTenorTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForTenorBottomDynamic',
                          label: i18n.t("fixedValueForTenor"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForTenorBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardOptionBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardOptionBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: "indicateDateToReachBottomDynamic",
                          label: i18n.t("indicateDateToReach"),
                          fieldType: "sectionTitle",
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorTypeDateBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfDateTypeColumnBottomDynamic', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDateBottomDynamic', name: i18n.t("fixedValueForDate")}, {value: 'wildcardForDateBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfDateTypeColumnBottomDynamic",
                              value: "nameOfDateTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForDateBottomDynamic",
                              value: "fixedValueForDateBottomDynamic",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcardForDateBottomDynamic",
                              value: "wildcardForDateBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfDateTypeColumnBottomDynamic',
                          fieldTypeIfNotDatasource: 'textBox',
                          label: i18n.t("nameOfDateTypeColumn"),
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedDate',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfDateTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForDateBottomDynamic',
                          label: i18n.t("fixedValueForDate"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardForDateBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcardsToday',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                      ]
                    }
                  }
                ]
              },
            },
          ]
        },
        addColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addColumn"),
          },
          steps: [
            {
              title: i18n.t("indicateColumnName"),
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("name"),
                    value: "nameColumn"
                  }
                ]
              },
              fields: [
                {
                  name: "nameColumn",
                  label: i18n.t("nameColumn"),
                  fieldType: "textBox",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCreateColumn"),
              nativeForm: true,
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("createColumn"),
                    value: "defineCreateColumn",
                    translateValue: true
                  }
                ]
              },
              fields: [
                {
                  name: "defineCreateColumn",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'withOutConditions', name: i18n.t("withOutConditions")}, {value: 'withConditions', name: i18n.t("withConditions")}],
                  optionsToCheck: [
                    {
                      field: "withOutConditions",
                      value: "withOutConditions"
                    },
                    {
                      field: "withConditions",
                      value: "withConditions"
                    }
                  ],
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              submitForm: 'normalForm',
              dependsOn: [
                {
                  step: "defineCreateColumn",
                  value: "withOutConditions"
                }
              ],
              dynamicForm: {
                title: i18n.t("valuesToConcatenate"),
                namesForm: i18n.t("value"),
                forms: [i18n.t("value") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsColumns"),
                dependsOn: [
                  {
                    field: "assignValue",
                    value: "concatenateValues"
                  }
                ],
                fields: [
                  {
                    name: "assignValueDynamic",
                    label: i18n.t("assignValue"),
                    fieldType: "selectBox",
                    responseDataSource: "assignValuesWithOutConcatenate",
                    validators: {
                      required: {msg: i18n.t("required")},
                    }
                  },
                  {
                    name: 'columnReferenced',
                    label: i18n.t("columnReferenced"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "columnReference",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                  {
                    name: 'columnNameDictionary',
                    label: i18n.t("inputColumnName"),
                    fieldType: 'selectBox',
                    fieldTypeIfNotDatasource: 'textBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'dictionary',
                    label: i18n.t("dictionary"),
                    fieldType: 'selectBox',
                    responseDataSource:'dictionariesData',
                    returnObject: true,
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'inputColumnName',
                    label: i18n.t("inputColumnName"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: 'operator',
                    label: i18n.t("operator"),
                    fieldType: 'selectBox',
                    responseDataSource:'operators',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: "radioGroupAddTenor",
                    hideTitle: true,
                    fieldType: "radioGroup",
                    dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                    optionsToCheck: [
                      {
                        field: "nameOfTenorTypeColumn",
                        value: "nameOfTenorTypeColumn"
                      },
                      {
                        field: "fixedValueForTenor",
                        value: "fixedValueForTenor",
                        setWhenValue: ''
                      },
                      {
                        field: "wilcard",
                        value: "wilcard"
                      }
                    ],
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
                ]
              },
              fields: [
                {
                  name: "assignValue",
                  label: i18n.t("assignValue"),
                  fieldType: "selectBox",
                  responseDataSource: "assignValues",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
                {
                  name: 'columnReferenced',
                  label: i18n.t("columnReferenced"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "columnReference",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcard',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValue',
                  label: i18n.t("fixedValue"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "fixedValue",
                      },
                    ]
                  }
                },
                {
                  name: 'columnNameDictionary',
                  label: i18n.t("inputColumnName"),
                  fieldType: 'selectBox',
                  fieldTypeIfNotDatasource: 'textBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'dictionary',
                  label: i18n.t("dictionary"),
                  fieldType: 'selectBox',
                  responseDataSource:'dictionariesData',
                  returnObject: true,
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'inputColumnName',
                  label: i18n.t("inputColumnName"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'operator',
                  label: i18n.t("operator"),
                  fieldType: 'selectBox',
                  responseDataSource:'operators',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenor",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfTenorTypeColumn",
                      value: "nameOfTenorTypeColumn"
                    },
                    {
                      field: "fixedValueForTenor",
                      value: "fixedValueForTenor",
                      setWhenValue: ''
                    },
                    {
                      field: "wilcard",
                      value: "wilcard"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCases"),
              dependsOn: [
                {
                  step: "defineCreateColumn",
                  value: "withConditions"
                }
              ],
              submitForm: 'bottomFields',
              dynamicFormGroup: {
                namesFormGroup: i18n.t("case"),
                alertLabel: i18n.t("reviewValidationsColumns"),
                forms: [
                  {
                    title: i18n.t("case") + ' ' + 1,
                    dynamicForm: {
                      title: i18n.t("caseConditions"),
                      newFormEmpty: true,
                      labelNewFormEmpty: i18n.t("notConditionsApllied"),
                      alertInfoNewFormEmpty: i18n.t("alertInfoConditionsEmpty"),
                      alertErrorNewFormEmpty: i18n.t("alertErrorConditionsEmpty"),
                      namesForm: i18n.t("condition"),
                      forms: [i18n.t("condition") + ' ' + 1],
                      showChips: true,
                      chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                      fields: [
                        {
                          name: "firstTerm",
                          label: i18n.t("firstTermField"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: "conditional",
                          label: i18n.t("conditional"),
                          fieldType: "selectBox",
                          responseDataSource: "filterConditionals",
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")}
                          }
                        },
                        {
                          name: "secondTerm",
                          label: i18n.t("secondTerm"),
                          hideTitle:true,
                          fieldType: "radioGroup",
                          responseDataSource: 'termsTypeColumn',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "conditional",
                                type: "selectBox",
                                values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                              },
                            ]
                          },
                          optionsToCheck: [
                            {
                              field: "inputColumnName",
                              value: "inputColumnName"
                            },
                            {
                              field: "fixedValue",
                              value: "fixedValue",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcard",
                              value: "wildcard"
                            },
                          ],
                        },
                        {
                          name: 'inputColumnName',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "inputColumnName",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValue',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            showInChips:true,
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcard',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                      ]
                    },
                    bottomFields:  [
                      {
                        name: "indicateHowToAssingValueCase",
                        label: i18n.t("indicateHowToAssingValueCase"),
                        fieldType: "sectionTitle",
                      },
                      {
                        name: "assignValueConditions",
                        label: i18n.t("assignValue"),
                        fieldType: "selectBox",
                        responseDataSource: "assignValues",
                        validators: {
                          required: {msg: i18n.t("required")},
                        }
                      },
                      {
                        name: 'columnReferenced',
                        label: i18n.t("columnReferenced"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "columnReference",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValue',
                        label: i18n.t("fixedValue"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "fixedValue",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcard',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: 'columnNameDictionary',
                        label: i18n.t("inputColumnName"),
                        fieldType: 'selectBox',
                        fieldTypeIfNotDatasource: 'textBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'dictionary',
                        label: i18n.t("dictionary"),
                        fieldType: 'selectBox',
                        responseDataSource:'dictionariesData',
                        returnObject: true,
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'inputColumnName',
                        label: i18n.t("inputColumnName"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'operator',
                        label: i18n.t("operator"),
                        fieldType: 'selectBox',
                        responseDataSource:'operators',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenor",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfTenorTypeColumn",
                            value: "nameOfTenorTypeColumn"
                          },
                          {
                            field: "fixedValueForTenor",
                            value: "fixedValueForTenor",
                            setWhenValue: ''
                          },
                          {
                            field: "wilcard",
                            value: "wilcard"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfTenorTypeColumn',
                        label: i18n.t("nameOfTenorTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedTenor',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "nameOfTenorTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForTenor',
                        label: i18n.t("fixedValueForTenor"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "fixedValueForTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOption',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: "indicateDateToReach",
                        label: i18n.t("indicateDateToReach"),
                        fieldType: "sectionTitle",
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenorTypeDate",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfDateTypeColumn",
                            value: "nameOfDateTypeColumn"
                          },
                          {
                            field: "fixedValueForDate",
                            value: "fixedValueForDate",
                            setWhenValue: ''
                          },
                          {
                            field: "wildcardOptionDate",
                            value: "wildcardOptionDate"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfDateTypeColumn',
                        label: i18n.t("nameOfDateTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedDate',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "nameOfDateTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForDate',
                        label: i18n.t("fixedValueForDate"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "fixedValueForDate",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOptionDate',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcardsToday',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "wildcardOptionDate",
                            },
                          ]
                        }
                      },
                    ],
                    dynamicBottomForm: {
                      dependsOn: [
                        {
                          field: "assignValueConditions",
                          value: "concatenateValues"
                        }
                      ],
                      title: i18n.t("valuesToConcatenate"),
                      namesForm: i18n.t("value"),
                      forms: [i18n.t("value") + ' ' + 1],
                      fields: [
                        {
                          name: "assignValueBottomDynamic",
                          label: i18n.t("assignValue"),
                          fieldType: "selectBox",
                          responseDataSource: "assignValuesWithOutConcatenate",
                          validators: {
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: 'columnReferencedBottomDynamic',
                          label: i18n.t("columnReferenced"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "columnReference",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueBottomDynamic',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                        {
                          name: 'columnNameDictionaryBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldType: 'selectBox',
                          fieldTypeIfNotDatasource: 'textBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'dictionaryBottomDynamic',
                          label: i18n.t("dictionary"),
                          fieldType: 'selectBox',
                          responseDataSource:'dictionariesData',
                          returnObject: true,
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'inputColumnNameBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'operatorBottomDynamic',
                          label: i18n.t("operator"),
                          fieldType: 'selectBox',
                          responseDataSource:'operators',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfTenorTypeColumnBottomDynamic', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenorBottomDynamic', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOptionBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfTenorTypeColumnBottomDynamic",
                              value: "nameOfTenorTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForTenorBottomDynamic",
                              value: "fixedValueForTenorBottomDynamic"
                            },
                            {
                              field: "wildcardOptionBottomDynamic",
                              value: "wildcardOptionBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfTenorTypeColumnBottomDynamic',
                          label: i18n.t("nameOfTenorTypeColumn"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedTenor',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfTenorTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForTenorBottomDynamic',
                          label: i18n.t("fixedValueForTenor"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForTenorBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardOptionBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardOptionBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: "indicateDateToReachBottomDynamic",
                          label: i18n.t("indicateDateToReach"),
                          fieldType: "sectionTitle",
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorTypeDateBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfDateTypeColumnBottomDynamic', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDateBottomDynamic', name: i18n.t("fixedValueForDate")}, {value: 'wildcardForDateBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfDateTypeColumnBottomDynamic",
                              value: "nameOfDateTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForDateBottomDynamic",
                              value: "fixedValueForDateBottomDynamic"
                            },
                            {
                              field: "wildcardForDateBottomDynamic",
                              value: "wildcardForDateBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfDateTypeColumnBottomDynamic',
                          fieldTypeIfNotDatasource: 'textBox',
                          label: i18n.t("nameOfDateTypeColumn"),
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedDate',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfDateTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForDateBottomDynamic',
                          label: i18n.t("fixedValueForDate"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardForDateBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcardsToday',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                      ]
                    }
                  }
                ]
              },
            },
          ]
        },
        addCellValue: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: "addCellValue",
          },
          steps: [
            {
              title: i18n.t("defineHowToCreateValue"),
              nativeForm: true,
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("createValue"),
                    value: "defineHowToCreateValue",
                    translateValue: true
                  }
                ]
              },
              fields: [
                {
                  name: "defineHowToCreateValue",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'withOutConditions', name: i18n.t("withOutConditions")}, {value: 'withConditions', name: i18n.t("withConditions")}],
                  optionsToCheck: [
                    {
                      field: "withOutConditions",
                      value: "withOutConditions"
                    },
                    {
                      field: "withConditions",
                      value: "withConditions"
                    }
                  ],
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              submitForm: 'normalForm',
              dependsOn: [
                {
                  step: "defineHowToCreateValue",
                  value: "withOutConditions"
                }
              ],
              dynamicForm: {
                title: i18n.t("valuesToConcatenate"),
                namesForm: i18n.t("value"),
                forms: [i18n.t("value") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsColumns"),
                dependsOn: [
                  {
                    field: "assignValue",
                    value: "concatenateValues"
                  }
                ],
                fields: [
                  {
                    name: "assignValueDynamic",
                    label: i18n.t("assignValue"),
                    fieldType: "selectBox",
                    responseDataSource: "assignValuesWithOutConcatenate",
                    validators: {
                      required: {msg: i18n.t("required")},
                    }
                  },
                  {
                    name: 'columnReferenced',
                    label: i18n.t("columnReferenced"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "columnReference",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                  {
                    name: 'columnNameDictionary',
                    label: i18n.t("inputColumnName"),
                    fieldType: 'selectBox',
                    fieldTypeIfNotDatasource: 'textBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'dictionary',
                    label: i18n.t("dictionary"),
                    fieldType: 'selectBox',
                    responseDataSource:'dictionariesData',
                    returnObject: true,
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'inputColumnName',
                    label: i18n.t("inputColumnName"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: 'operator',
                    label: i18n.t("operator"),
                    fieldType: 'selectBox',
                    responseDataSource:'operators',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: "radioGroupAddTenor",
                    hideTitle: true,
                    fieldType: "radioGroup",
                    dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                    optionsToCheck: [
                      {
                        field: "nameOfTenorTypeColumn",
                        value: "nameOfTenorTypeColumn"
                      },
                      {
                        field: "fixedValueForTenor",
                        value: "fixedValueForTenor",
                        setWhenValue: ''
                      },
                      {
                        field: "wilcard",
                        value: "wilcard"
                      }
                    ],
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
                ]
              },
              fields: [
                {
                  name: "assignValue",
                  label: i18n.t("assignValue"),
                  fieldType: "selectBox",
                  responseDataSource: "assignValues",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
                {
                  name: 'columnReferenced',
                  label: i18n.t("columnReferenced"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "columnReference",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcard',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValue',
                  label: i18n.t("fixedValue"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "fixedValue",
                      },
                    ]
                  }
                },
                {
                  name: 'columnNameDictionary',
                  label: i18n.t("inputColumnName"),
                  fieldType: 'selectBox',
                  fieldTypeIfNotDatasource: 'textBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'dictionary',
                  label: i18n.t("dictionary"),
                  fieldType: 'selectBox',
                  responseDataSource:'dictionariesData',
                  returnObject: true,
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'inputColumnName',
                  label: i18n.t("inputColumnName"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'operator',
                  label: i18n.t("operator"),
                  fieldType: 'selectBox',
                  responseDataSource:'operators',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenor",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfTenorTypeColumn",
                      value: "nameOfTenorTypeColumn"
                    },
                    {
                      field: "fixedValueForTenor",
                      value: "fixedValueForTenor",
                      setWhenValue: ''
                    },
                    {
                      field: "wilcard",
                      value: "wilcard"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCases"),
              dependsOn: [
                {
                  step: "defineHowToCreateValue",
                  value: "withConditions"
                }
              ],
              submitForm: 'bottomFields',
              dynamicFormGroup: {
                namesFormGroup: i18n.t("case"),
                alertLabel: i18n.t("reviewValidationsColumns"),
                forms: [
                  {
                    title: i18n.t("case") + ' ' + 1,
                    dynamicForm: {
                      title: i18n.t("caseConditions"),
                      newFormEmpty: true,
                      labelNewFormEmpty: i18n.t("notConditionsApllied"),
                      alertInfoNewFormEmpty: i18n.t("alertInfoConditionsEmpty"),
                      alertErrorNewFormEmpty: i18n.t("alertErrorConditionsEmpty"),
                      namesForm: i18n.t("condition"),
                      forms: [i18n.t("condition") + ' ' + 1],
                      showChips: true,
                      chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                      fields: [
                        {
                          name: "firstTerm",
                          label: i18n.t("firstTermField"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: "conditional",
                          label: i18n.t("conditional"),
                          fieldType: "selectBox",
                          responseDataSource: "filterConditionals",
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")}
                          }
                        },
                        {
                          name: "secondTerm",
                          label: i18n.t("secondTerm"),
                          fieldType: "radioGroup",
                          responseDataSource: 'termsTypeColumn',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "conditional",
                                type: "selectBox",
                                values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                              },
                            ]
                          },
                          optionsToCheck: [
                            {
                              field: "inputColumnName",
                              value: "inputColumnName"
                            },
                            {
                              field: "fixedValue",
                              value: "fixedValue",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcard",
                              value: "wildcard"
                            },
                          ],
                        },
                        {
                          name: 'inputColumnName',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "inputColumnName",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValue',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            showInChips:true,
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcard',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                      ]
                    },
                    bottomFields:  [
                      {
                        name: "indicateHowToAssingValueCase",
                        label: i18n.t("indicateHowToAssingValueCase"),
                        fieldType: "sectionTitle",
                      },
                      {
                        name: "assignValueConditions",
                        label: i18n.t("assignValue"),
                        fieldType: "selectBox",
                        responseDataSource: "assignValues",
                        validators: {
                          required: {msg: i18n.t("required")},
                        }
                      },
                      {
                        name: 'columnReferenced',
                        label: i18n.t("columnReferenced"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "columnReference",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValue',
                        label: i18n.t("fixedValue"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "fixedValue",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcard',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: 'columnNameDictionary',
                        label: i18n.t("inputColumnName"),
                        fieldType: 'selectBox',
                        fieldTypeIfNotDatasource: 'textBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'dictionary',
                        label: i18n.t("dictionary"),
                        fieldType: 'selectBox',
                        responseDataSource:'dictionariesData',
                        returnObject: true,
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'inputColumnName',
                        label: i18n.t("inputColumnName"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'operator',
                        label: i18n.t("operator"),
                        fieldType: 'selectBox',
                        responseDataSource:'operators',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenor",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfTenorTypeColumn",
                            value: "nameOfTenorTypeColumn"
                          },
                          {
                            field: "fixedValueForTenor",
                            value: "fixedValueForTenor",
                            setWhenValue: ''
                          },
                          {
                            field: "wilcard",
                            value: "wilcard"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfTenorTypeColumn',
                        label: i18n.t("nameOfTenorTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedTenor',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "nameOfTenorTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForTenor',
                        label: i18n.t("fixedValueForTenor"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "fixedValueForTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOption',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: "indicateDateToReach",
                        label: i18n.t("indicateDateToReach"),
                        fieldType: "sectionTitle",
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenorTypeDate",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfDateTypeColumn",
                            value: "nameOfDateTypeColumn"
                          },
                          {
                            field: "fixedValueForDate",
                            value: "fixedValueForDate",
                            setWhenValue: ''
                          },
                          {
                            field: "wildcardOptionDate",
                            value: "wildcardOptionDate"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfDateTypeColumn',
                        label: i18n.t("nameOfDateTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedDate',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "nameOfDateTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForDate',
                        label: i18n.t("fixedValueForDate"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "fixedValueForDate",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOptionDate',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcardsToday',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "wildcardOptionDate",
                            },
                          ]
                        }
                      },
                    ],
                    dynamicBottomForm: {
                      dependsOn: [
                        {
                          field: "assignValueConditions",
                          value: "concatenateValues"
                        }
                      ],
                      title: i18n.t("valuesToConcatenate"),
                      namesForm: i18n.t("value"),
                      forms: [i18n.t("value") + ' ' + 1],
                      fields: [
                        {
                          name: "assignValueBottomDynamic",
                          label: i18n.t("assignValue"),
                          fieldType: "selectBox",
                          responseDataSource: "assignValuesWithOutConcatenate",
                          validators: {
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: 'columnReferencedBottomDynamic',
                          label: i18n.t("columnReferenced"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "columnReference",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueBottomDynamic',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                        {
                          name: 'columnNameDictionaryBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldType: 'selectBox',
                          fieldTypeIfNotDatasource: 'textBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'dictionaryBottomDynamic',
                          label: i18n.t("dictionary"),
                          fieldType: 'selectBox',
                          responseDataSource:'dictionariesData',
                          returnObject: true,
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'inputColumnNameBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'operatorBottomDynamic',
                          label: i18n.t("operator"),
                          fieldType: 'selectBox',
                          responseDataSource:'operators',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfTenorTypeColumnBottomDynamic', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenorBottomDynamic', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOptionBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfTenorTypeColumnBottomDynamic",
                              value: "nameOfTenorTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForTenorBottomDynamic",
                              value: "fixedValueForTenorBottomDynamic"
                            },
                            {
                              field: "wildcardOptionBottomDynamic",
                              value: "wildcardOptionBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfTenorTypeColumnBottomDynamic',
                          label: i18n.t("nameOfTenorTypeColumn"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedTenor',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfTenorTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForTenorBottomDynamic',
                          label: i18n.t("fixedValueForTenor"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForTenorBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardOptionBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardOptionBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: "indicateDateToReachBottomDynamic",
                          label: i18n.t("indicateDateToReach"),
                          fieldType: "sectionTitle",
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorTypeDateBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfDateTypeColumnBottomDynamic', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDateBottomDynamic', name: i18n.t("fixedValueForDate")}, {value: 'wildcardForDateBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfDateTypeColumnBottomDynamic",
                              value: "nameOfDateTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForDateBottomDynamic",
                              value: "fixedValueForDateBottomDynamic"
                            },
                            {
                              field: "wildcardForDateBottomDynamic",
                              value: "wildcardForDateBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfDateTypeColumnBottomDynamic',
                          fieldTypeIfNotDatasource: 'textBox',
                          label: i18n.t("nameOfDateTypeColumn"),
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedDate',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfDateTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForDateBottomDynamic',
                          label: i18n.t("fixedValueForDate"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardForDateBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcardsToday',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                      ]
                    }
                  }
                ]
              },
            },
          ]
        },
        editCell: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: "editCellValue",
            alertNoChanges: i18n.t("alertNoChanges")
          },
          steps: [
            {
              title: i18n.t("defineHowToCreateValue"),
              nativeForm: true,
              resume: {
                from: 'form',
                fields: [
                  {
                    label: i18n.t("createValue"),
                    value: "defineHowToCreateValue",
                    translateValue: true
                  }
                ]
              },
              fields: [
                {
                  name: "defineHowToCreateValue",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'withOutConditions', name: i18n.t("withOutConditions")}, {value: 'withConditions', name: i18n.t("withConditions")}],
                  optionsToCheck: [
                    {
                      field: "withOutConditions",
                      value: "withOutConditions"
                    },
                    {
                      field: "withConditions",
                      value: "withConditions"
                    }
                  ],
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              submitForm: 'normalForm',
              dependsOn: [
                {
                  step: "defineHowToCreateValue",
                  value: "withOutConditions"
                }
              ],
              dynamicForm: {
                title: i18n.t("valuesToConcatenate"),
                namesForm: i18n.t("value"),
                forms: [i18n.t("value") + ' ' + 1],
                alertLabel: i18n.t("reviewValidationsColumns"),
                dependsOn: [
                  {
                    field: "assignValue",
                    value: "concatenateValues"
                  }
                ],
                fields: [
                  {
                    name: "assignValueDynamic",
                    label: i18n.t("assignValue"),
                    fieldType: "selectBox",
                    responseDataSource: "assignValuesWithOutConcatenate",
                    validators: {
                      required: {msg: i18n.t("required")},
                    }
                  },
                  {
                    name: 'columnReferenced',
                    label: i18n.t("columnReferenced"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "columnReference",
                        },
                      ]
                    }
                  },
                  {
                    name: 'fixedValue',
                    label: i18n.t("fixedValue"),
                    fieldType: 'textBox',
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "fixedValue",
                        },
                      ]
                    }
                  },
                  {
                    name: 'wildcard',
                    label: i18n.t("wildcard"),
                    fieldType: 'selectBox',
                    responseDataSource:'wildcards',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "wildcard",
                        },
                      ]
                    }
                  },
                  {
                    name: 'columnNameDictionary',
                    label: i18n.t("inputColumnName"),
                    fieldType: 'selectBox',
                    fieldTypeIfNotDatasource: 'textBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'dictionary',
                    label: i18n.t("dictionary"),
                    fieldType: 'selectBox',
                    responseDataSource:'dictionariesData',
                    returnObject: true,
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "dictionary",
                        },
                      ]
                    }
                  },
                  {
                    name: 'inputColumnName',
                    label: i18n.t("inputColumnName"),
                    fieldTypeIfNotDatasource: 'textBox',
                    fieldType: 'selectBox',
                    responseDataSource:'columnsReferenced',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: 'operator',
                    label: i18n.t("operator"),
                    fieldType: 'selectBox',
                    responseDataSource:'operators',
                    validators: {
                      required: {msg: i18n.t("required")},
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                    name: "radioGroupAddTenor",
                    hideTitle: true,
                    fieldType: "radioGroup",
                    dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                    optionsToCheck: [
                      {
                        field: "nameOfTenorTypeColumn",
                        value: "nameOfTenorTypeColumn"
                      },
                      {
                        field: "fixedValueForTenor",
                        value: "fixedValueForTenor",
                        setWhenValue: ''
                      },
                      {
                        field: "wilcard",
                        value: "wilcard"
                      }
                    ],
                    validators: {
                      dependsOn: [
                        {
                          field: "assignValueDynamic",
                          type: "selectBox",
                          value: "addTenor",
                        },
                      ]
                    }
                  },
                  {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValueDynamic",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
                ]
              },
              fields: [
                {
                  name: "assignValue",
                  label: i18n.t("assignValue"),
                  fieldType: "selectBox",
                  responseDataSource: "assignValues",
                  validators: {
                    required: {msg: i18n.t("required")},
                  }
                },
                {
                  name: 'columnReferenced',
                  label: i18n.t("columnReferenced"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "columnReference",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcard',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValue',
                  label: i18n.t("fixedValue"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "fixedValue",
                      },
                    ]
                  }
                },
                {
                  name: 'columnNameDictionary',
                  label: i18n.t("inputColumnName"),
                  fieldType: 'selectBox',
                  fieldTypeIfNotDatasource: 'textBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'dictionary',
                  label: i18n.t("dictionary"),
                  fieldType: 'selectBox',
                  responseDataSource:'dictionariesData',
                  returnObject: true,
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "dictionary",
                      },
                    ]
                  }
                },
                {
                  name: 'inputColumnName',
                  label: i18n.t("inputColumnName"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferenced',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'operator',
                  label: i18n.t("operator"),
                  fieldType: 'selectBox',
                  responseDataSource:'operators',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenor",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfTenorTypeColumn",
                      value: "nameOfTenorTypeColumn"
                    },
                    {
                      field: "fixedValueForTenor",
                      value: "fixedValueForTenor",
                      setWhenValue: ''
                    },
                    {
                      field: "wilcard",
                      value: "wilcard"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfTenorTypeColumn',
                  label: i18n.t("nameOfTenorTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedTenor',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "nameOfTenorTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForTenor',
                  label: i18n.t("fixedValueForTenor"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "fixedValueForTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOption',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcards',
                  validators: {
                    required: {msg: i18n.t("required")},
                    dependsOn: [
                      {
                        field: "radioGroupAddTenor",
                        type: "radioGroup",
                        value: "wildcard",
                      },
                    ]
                  }
                },
                {
                  name: "indicateDateToReach",
                  label: i18n.t("indicateDateToReach"),
                  fieldType: "sectionTitle",
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: "radioGroupAddTenorTypeDate",
                  hideTitle: true,
                  fieldType: "radioGroup",
                  dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                  optionsToCheck: [
                    {
                      field: "nameOfDateTypeColumn",
                      value: "nameOfDateTypeColumn"
                    },
                    {
                      field: "fixedValueForDate",
                      value: "fixedValueForDate",
                      setWhenValue: ''
                    },
                    {
                      field: "wildcardOptionDate",
                      value: "wildcardOptionDate"
                    }
                  ],
                  validators: {
                    dependsOn: [
                      {
                        field: "assignValue",
                        type: "selectBox",
                        value: "addTenor",
                      },
                    ]
                  }
                },
                {
                  name: 'nameOfDateTypeColumn',
                  label: i18n.t("nameOfDateTypeColumn"),
                  fieldTypeIfNotDatasource: 'textBox',
                  fieldType: 'selectBox',
                  responseDataSource:'columnsReferencedDate',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "nameOfDateTypeColumn",
                      },
                    ]
                  }
                },
                {
                  name: 'fixedValueForDate',
                  label: i18n.t("fixedValueForDate"),
                  fieldType: 'textBox',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "fixedValueForDate",
                      },
                    ]
                  }
                },
                {
                  name: 'wildcardOptionDate',
                  label: i18n.t("wildcard"),
                  fieldType: 'selectBox',
                  responseDataSource:'wildcardsToday',
                  validators: {
                    dependsOn: [
                      {
                        field: "radioGroupAddTenorTypeDate",
                        type: "radioGroup",
                        value: "wildcardOptionDate",
                      },
                    ]
                  }
                },
              ],
            },
            {
              title: i18n.t("defineCases"),
              dependsOn: [
                {
                  step: "defineHowToCreateValue",
                  value: "withConditions"
                }
              ],
              submitForm: 'bottomFields',
              dynamicFormGroup: {
                namesFormGroup: i18n.t("case"),
                alertLabel: i18n.t("reviewValidationsColumns"),
                forms: [
                  {
                    title: i18n.t("case") + ' ' + 1,
                    dynamicForm: {
                      title: i18n.t("caseConditions"),
                      newFormEmpty: true,
                      labelNewFormEmpty: i18n.t("notConditionsApllied"),
                      alertInfoNewFormEmpty: i18n.t("alertInfoConditionsEmpty"),
                      alertErrorNewFormEmpty: i18n.t("alertErrorConditionsEmpty"),
                      namesForm: i18n.t("condition"),
                      forms: [i18n.t("condition") + ' ' + 1],
                      showChips: true,
                      chipsTransform: [{field: 'conditional', transformFromDataSource: 'filterConditionals'}],
                      fields: [
                        {
                          name: "firstTerm",
                          label: i18n.t("firstTermField"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: "conditional",
                          label: i18n.t("conditional"),
                          fieldType: "selectBox",
                          responseDataSource: "filterConditionals",
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")}
                          }
                        },
                        {
                          name: "secondTerm",
                          label: i18n.t("secondTerm"),
                          fieldType: "radioGroup",
                          responseDataSource: 'termsTypeColumn',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "conditional",
                                type: "selectBox",
                                values: ['EQUAL_TO','NOT_EQUAL','GREATER_THAN','LESS_THAN','GREATER_THAN_OR_EQUAL_TO','LESS_THAN_OR_EQUAL_TO', 'REGULAR_EXPRESSION'],
                              },
                            ]
                          },
                          optionsToCheck: [
                            {
                              field: "inputColumnName",
                              value: "inputColumnName"
                            },
                            {
                              field: "fixedValue",
                              value: "fixedValue",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcard",
                              value: "wildcard"
                            },
                          ],
                        },
                        {
                          name: 'inputColumnName',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "inputColumnName",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValue',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            showInChips:true,
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcard',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            showInChips:true,
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "secondTerm",
                                type: "radioGroup",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                      ]
                    },
                    bottomFields:  [
                      {
                        name: "indicateHowToAssingValueCase",
                        label: i18n.t("indicateHowToAssingValueCase"),
                        fieldType: "sectionTitle",
                      },
                      {
                        name: "assignValueConditions",
                        label: i18n.t("assignValue"),
                        fieldType: "selectBox",
                        responseDataSource: "assignValues",
                        validators: {
                          required: {msg: i18n.t("required")},
                        }
                      },
                      {
                        name: 'columnReferenced',
                        label: i18n.t("columnReferenced"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "columnReference",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValue',
                        label: i18n.t("fixedValue"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "fixedValue",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcard',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: 'columnNameDictionary',
                        label: i18n.t("inputColumnName"),
                        fieldType: 'selectBox',
                        fieldTypeIfNotDatasource: 'textBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'dictionary',
                        label: i18n.t("dictionary"),
                        fieldType: 'selectBox',
                        responseDataSource:'dictionariesData',
                        returnObject: true,
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "dictionary",
                            },
                          ]
                        }
                      },
                      {
                        name: 'inputColumnName',
                        label: i18n.t("inputColumnName"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferenced',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'operator',
                        label: i18n.t("operator"),
                        fieldType: 'selectBox',
                        responseDataSource:'operators',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenor",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfTenorTypeColumn', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenor', name: i18n.t("fixedValueForTenor")}, {value: 'wildcard', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfTenorTypeColumn",
                            value: "nameOfTenorTypeColumn"
                          },
                          {
                            field: "fixedValueForTenor",
                            value: "fixedValueForTenor",
                            setWhenValue: ''
                          },
                          {
                            field: "wilcard",
                            value: "wilcard"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfTenorTypeColumn',
                        label: i18n.t("nameOfTenorTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedTenor',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "nameOfTenorTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForTenor',
                        label: i18n.t("fixedValueForTenor"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "fixedValueForTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOption',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcards',
                        validators: {
                          required: {msg: i18n.t("required")},
                          dependsOn: [
                            {
                              field: "radioGroupAddTenor",
                              type: "radioGroup",
                              value: "wildcard",
                            },
                          ]
                        }
                      },
                      {
                        name: "indicateDateToReach",
                        label: i18n.t("indicateDateToReach"),
                        fieldType: "sectionTitle",
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: "radioGroupAddTenorTypeDate",
                        hideTitle: true,
                        fieldType: "radioGroup",
                        dataSource: [{value: 'nameOfDateTypeColumn', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDate', name: i18n.t("fixedValueForDate")}, {value: 'wildcardOptionDate', name: i18n.t("wildcard")}],
                        optionsToCheck: [
                          {
                            field: "nameOfDateTypeColumn",
                            value: "nameOfDateTypeColumn"
                          },
                          {
                            field: "fixedValueForDate",
                            value: "fixedValueForDate",
                            setWhenValue: ''
                          },
                          {
                            field: "wildcardOptionDate",
                            value: "wildcardOptionDate"
                          }
                        ],
                        validators: {
                          dependsOn: [
                            {
                              field: "assignValueConditions",
                              type: "selectBox",
                              value: "addTenor",
                            },
                          ]
                        }
                      },
                      {
                        name: 'nameOfDateTypeColumn',
                        label: i18n.t("nameOfDateTypeColumn"),
                        fieldTypeIfNotDatasource: 'textBox',
                        fieldType: 'selectBox',
                        responseDataSource:'columnsReferencedDate',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "nameOfDateTypeColumn",
                            },
                          ]
                        }
                      },
                      {
                        name: 'fixedValueForDate',
                        label: i18n.t("fixedValueForDate"),
                        fieldType: 'textBox',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "fixedValueForDate",
                            },
                          ]
                        }
                      },
                      {
                        name: 'wildcardOptionDate',
                        label: i18n.t("wildcard"),
                        fieldType: 'selectBox',
                        responseDataSource:'wildcardsToday',
                        validators: {
                          dependsOn: [
                            {
                              field: "radioGroupAddTenorTypeDate",
                              type: "radioGroup",
                              value: "wildcardOptionDate",
                            },
                          ]
                        }
                      },
                    ],
                    dynamicBottomForm: {
                      dependsOn: [
                        {
                          field: "assignValueConditions",
                          value: "concatenateValues"
                        }
                      ],
                      title: i18n.t("valuesToConcatenate"),
                      namesForm: i18n.t("value"),
                      forms: [i18n.t("value") + ' ' + 1],
                      fields: [
                        {
                          name: "assignValueBottomDynamic",
                          label: i18n.t("assignValue"),
                          fieldType: "selectBox",
                          responseDataSource: "assignValuesWithOutConcatenate",
                          validators: {
                            required: {msg: i18n.t("required")},
                          }
                        },
                        {
                          name: 'columnReferencedBottomDynamic',
                          label: i18n.t("columnReferenced"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "columnReference",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueBottomDynamic',
                          label: i18n.t("fixedValue"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "fixedValue",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "wildcard",
                              },
                            ]
                          }
                        },
                        {
                          name: 'columnNameDictionaryBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldType: 'selectBox',
                          fieldTypeIfNotDatasource: 'textBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'dictionaryBottomDynamic',
                          label: i18n.t("dictionary"),
                          fieldType: 'selectBox',
                          responseDataSource:'dictionariesData',
                          returnObject: true,
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "dictionary",
                              },
                            ]
                          }
                        },
                        {
                          name: 'inputColumnNameBottomDynamic',
                          label: i18n.t("inputColumnName"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferenced',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'operatorBottomDynamic',
                          label: i18n.t("operator"),
                          fieldType: 'selectBox',
                          responseDataSource:'operators',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfTenorTypeColumnBottomDynamic', name: i18n.t("nameOfTenorTypeColumn")}, {value: 'fixedValueForTenorBottomDynamic', name: i18n.t("fixedValueForTenor")}, {value: 'wildcardOptionBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfTenorTypeColumnBottomDynamic",
                              value: "nameOfTenorTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForTenorBottomDynamic",
                              value: "fixedValueForTenorBottomDynamic",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcardOptionBottomDynamic",
                              value: "wildcardOptionBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfTenorTypeColumnBottomDynamic',
                          label: i18n.t("nameOfTenorTypeColumn"),
                          fieldTypeIfNotDatasource: 'textBox',
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedTenor',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfTenorTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForTenorBottomDynamic',
                          label: i18n.t("fixedValueForTenor"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForTenorBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardOptionBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcards',
                          validators: {
                            required: {msg: i18n.t("required")},
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardOptionBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: "indicateDateToReachBottomDynamic",
                          label: i18n.t("indicateDateToReach"),
                          fieldType: "sectionTitle",
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: "radioGroupAddTenorTypeDateBottomDynamic",
                          hideTitle: true,
                          fieldType: "radioGroup",
                          dataSource: [{value: 'nameOfDateTypeColumnBottomDynamic', name: i18n.t("nameOfDateTypeColumn")}, {value: 'fixedValueForDateBottomDynamic', name: i18n.t("fixedValueForDate")}, {value: 'wildcardForDateBottomDynamic', name: i18n.t("wildcard")}],
                          optionsToCheck: [
                            {
                              field: "nameOfDateTypeColumnBottomDynamic",
                              value: "nameOfDateTypeColumnBottomDynamic"
                            },
                            {
                              field: "fixedValueForDateBottomDynamic",
                              value: "fixedValueForDateBottomDynamic",
                              setWhenValue: ''
                            },
                            {
                              field: "wildcardForDateBottomDynamic",
                              value: "wildcardForDateBottomDynamic"
                            }
                          ],
                          validators: {
                            dependsOn: [
                              {
                                field: "assignValueBottomDynamic",
                                type: "selectBox",
                                value: "addTenor",
                              },
                            ]
                          }
                        },
                        {
                          name: 'nameOfDateTypeColumnBottomDynamic',
                          fieldTypeIfNotDatasource: 'textBox',
                          label: i18n.t("nameOfDateTypeColumn"),
                          fieldType: 'selectBox',
                          responseDataSource:'columnsReferencedDate',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "nameOfDateTypeColumnBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'fixedValueForDateBottomDynamic',
                          label: i18n.t("fixedValueForDate"),
                          fieldType: 'textBox',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "fixedValueForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                        {
                          name: 'wildcardForDateBottomDynamic',
                          label: i18n.t("wildcard"),
                          fieldType: 'selectBox',
                          responseDataSource:'wildcardsToday',
                          validators: {
                            dependsOn: [
                              {
                                field: "radioGroupAddTenorTypeDateBottomDynamic",
                                type: "radioGroup",
                                value: "wildcardForDateBottomDynamic",
                              },
                            ]
                          }
                        },
                      ]
                    }
                  }
                ]
              },
            },
          ]
        },
      }
    },

  },
};
