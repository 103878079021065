import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
import VIEWS from "../config/views.json";
import { tools } from "../mixins/tools";
import { firebaseTools } from "../mixins/firebase-tools";
import store from "../store";
import Data from "../views/overview/Data.vue";
import ViewFrame from "../views/common/ViewFrame.vue";
import Home from '../views/overview/Home.vue'; 
import { authentication } from "../mixins/authentication";
import LayoutAuth from "../views/authentication/LayoutAuth.vue";
import Login from "../views/authentication/Login.vue";
import Logout from "../views/authentication/Logout.vue";
import NotAllowed from "../views/authentication/NotAllowed.vue";
import Roles from "@/plugins/roles";

let components = {
  ViewFrame,
  Data,
  Home
};

Vue.use(VueRouter);


let routes = [
  {
    path: "/",
    component: Layout,
    meta: { isLayout: true },
    children: [
      {
        path: "",
        redirect: { name: "ProcessHistory" },
      }
    ],
  },
  {
    path: "/notFound",
    name: "Error404",
    component: () => import("../views/Error404.vue"),
    meta: { isPublic: true },
  },
  {
    path: "*",
    component: () => import("../views/Error404.vue"),
    meta: { isPublic: true },
  },
];

let layoutItem = routes.find((route) => route.meta && route.meta.isLayout);

function loadViewsJson(list, checkRoles) {
  list.forEach((section) => {
    
    if (section.children && checkRoles) {
      section["children"] = setMenu(section, "children");
    }

    if (section.tabs) {
      if (checkRoles) section.tabs = setMenu(section, "tabs");
      section["children"] = section.tabs;
    }

    section.component = components[section.component];

    section.children && loadViewsJson(section.children);
  });
}

function setMenu(section, field) {
  return (section[field] = section[field].filter(
    (item) => !item.meta || (item.meta && Roles.checkRoles(item.meta.rolesView, true) && (!section.meta.notRole || !Roles.checkRoles(section.meta.notRole)) && (!item.meta.mustHaveRoles || Roles.checkRoles(item.meta.mustHaveRoles)))));
}

function createAppMenu() {
  let copyMenu = tools.methods.deepCopyFunction(VIEWS);
  copyMenu.viewsScheme = copyMenu.viewsScheme.filter(section => !section.meta || (section.meta && Roles.checkRoles(section.meta.rolesView, true) && (!section.meta.notRole || !Roles.checkRoles(section.meta.notRole)) && (!section.meta.mustHaveRoles || Roles.checkRoles(section.meta.mustHaveRoles))));
  loadViewsJson(copyMenu.viewsScheme, true);
  store.dispatch("setAppMenu", copyMenu.viewsScheme);
}
let copyScheme = tools.methods.deepCopyFunction(VIEWS.viewsScheme);
loadViewsJson(copyScheme, false);
layoutItem.children = [...layoutItem.children, ...copyScheme];
// Authentication routes

const authenticationRoutes = [
  {
    path: "/notAllowed",
    name: "NotAllowed",
    component: NotAllowed,
    meta: { isPublic: true },
  },
  {
    path: "/",
    component: LayoutAuth,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { isPublic: true },
      },
      {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: { isPublic: true },
      },
    ],
  },
];

routes = [...routes, ...authenticationRoutes];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return new Promise(() => {
      this.app.$root.$once('triggerScroll', () => {
        document.getElementById('app').scrollIntoView();
        return null;
      })
    })
  },
  routes,
});

let actualView = undefined;

function findView(list, name, parent = undefined) {
  list.forEach((section) => {
    section["parent"] = parent;
    if (section.name === name) {
      actualView = section;
      store.dispatch("setActualView", actualView);
    } else if (section.children || section.tabs) {
      findView(
        section.children || section.tabs,
        name,
        section
      );
    }
  });
}


router.beforeEach((to, from, next) => {
  store.dispatch("setDataView", undefined);
  store.dispatch("setExtraDataView", undefined);
  store.dispatch("setLoadingPage", true);
  
  createAppMenu();
  checkRoutes(to, from, next);
});

function checkRoutes(to, from, next) {
  let rolesToAddRolesView = [];
  if((['Login', 'Logout'].includes(to.name)) && store?.state?.userLogged) return next(from);
  if (to.meta && to.meta.mustHaveRoles) to.meta.mustHaveRoles.forEach(item => rolesToAddRolesView.push(item));
  const { rolesView } = to.meta;
  let roles = rolesView && rolesView.length ? [...rolesView, ...rolesToAddRolesView] : rolesToAddRolesView;
  if (!to.name) next({ name: "Error404" });
  else if (!authentication.methods.checkUserRoles(roles) || (to.meta.notRole && Roles.checkRoles(to.meta.notRole))) {
    console.warn(
      `You do not have the roles that allow you to access route '${to.path}'`
    );
    next({ name: "NotAllowed" });
  } else {
    next();
  }
}

router.afterEach((to) => {
  findView(store.state.appMenu || VIEWS.viewsScheme, to.name);
  const actualView = store.state.actualView?.parent || store.state.actualView;
  if (store.state.actualGroup && actualView.get && store.state.actualView.name !== "ProcessHistory" ) {
    actualView.get.forEach(item => {
      firebaseTools.methods[item.get](item.collection, item.query || null,  to?.params?.id || true)
    })
  }
});

export default router;
