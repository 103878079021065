<template>
  <v-dialog
    :value="itemToAddEdit ? true : false"
    :width="$vuetify.breakpoint.xs ? '90%' : '500'"
    v-click-outside="cancel"
    content-class="dialog addEditModal"
    scrollable
  >
  <v-card ref="containerModal" class="contaninerModal">
    <v-card-title ref="header" class="pb-5">
      <div class="modalHeader">
        <div class="modalTitle" v-if="textProps && textProps.title">{{ textProps.title }}</div>
        <div
          v-if="textProps && textProps.subtitle"
          class="modalSubtitle my-3"
        >{{ textProps.subtitle }}</div>
        <template v-if="alerts && alerts.length">
          <v-alert
            ref="alertRef"
            v-for="alert in alerts"
            :key="alert.label"
            outlined
            :type="alert.type"
            :icon="alert.icon"
            class="mt-4"
          >{{ alert.label }}</v-alert>
        </template>
      </div>
    </v-card-title>
    <v-card-text class="px-4 pt-0 formContainer" >
      <Form
        v-if="itemToAddEdit"
        :textProps="textProps"
        :fields="fields"
        :mustBeChangesValidation="itemToAddEdit && itemToAddEdit.data && itemToAddEdit.data.params.msgAction && itemToAddEdit.data.params.msgAction === 'addColumn' || itemToAddEdit.cloneItem ? false : true"
        :currentDataValues="currentDataValues"
        @updatedForm="getStatus = false; submitForm = false"
        :globalValues="globalValues"
        :getStatus="getStatus"
        @formStatus="formSubmitted"
        @formSubmitted="sendForm"
        :submitForm="submitForm"
        :formContainerToScroll="formContainer"
        :resetForm="resetForm"
        :returnAlerts="true"
        @alerts="alerts = $event"
        @isValidForm="disabled = !$event.valid"
        primaryColor="var(--primary)"
      >
      <template v-slot:[slotFieldName]="{form, field, currentValues, onChange}">
        <v-combobox
          :items="field.dataSource"
          :label="field.label"
          :id="field.id"
          ref="combobox"
          :rules="[...field.validations]"
          v-model="currentValues[field.name]"
          @change="onChange()"
          clearable
        >
        </v-combobox>
      </template>
      </Form>
    </v-card-text>
    <v-card-actions ref="actions">
      <div class="modalFooter px-1 pt-5 pb-3" v-if="textProps">
        <v-btn @click="cancel('buttonCancel')" depressed rounded outlined class="cancelButton">
          <v-icon left v-if="textProps.secondaryButton.icon">{{ textProps.secondaryButton.icon }}</v-icon>
          <img
            v-if="textProps.secondaryButton.img"
            :src="textProps.secondaryButton.img"
            :alt="textProps.secondaryButton.text"
            class="mr-2"
          />
          {{ textProps.secondaryButton.text }}
        </v-btn>
        <v-btn
          @click="submitForm = true"
          color="var(--primary)"
          rounded
          depressed
          :style="{ color: 'white' }"
        >
          <v-icon left v-if="textProps.primaryButton.icon">{{ textProps.primaryButton.icon }}</v-icon>
          <img
            v-if="textProps.primaryButton.img"
            :src="textProps.primaryButton.img"
            :alt="textProps.primaryButton.text"
            class="mr-2"
          />
          {{ textProps.primaryButton.text }}
        </v-btn>
      </div>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";
import editableDataFields from "@/mixins/editable-data-fields";
import { tools } from "@/mixins/tools";
import { mapState } from "vuex";

export default {
  name: "AddEditModal",
  data: () => ({
    getStatus: false,
    dirty: false,
    submitForm: false,
    heightForm: 0,
    formContainer: ".addEditModal .v-navigation-drawer__content",
    resetForm: false,
    alerts: undefined,
    disabled: false,
    alertRefHeight: undefined
  }),
  mixins: [tools],
  computed: {
    ...mapState(["itemToAddEdit", "actualView"]),
    textProps() {
      if (this.itemToAddEdit) {    
        const { configEditableDataFields, action, section, cloneItem, currentDataValues } = this.itemToAddEdit       
        return configEditableDataFields
                ? configEditableDataFields
                : action
                  ? editableDataFields.computed[section]().config[action]
                  : (currentDataValues && !cloneItem)
                    ? editableDataFields.computed[section]().config["edit"]
                    : editableDataFields.computed[section]().config["add"];          
      } else return null;
    },
    fields() {     
      if (this.itemToAddEdit) {
        const section = this.itemToAddEdit.section ? editableDataFields.computed[
          this.itemToAddEdit.section
        ]() : undefined;

        let fields = section ? this.deepCopyFunction(section.fields) : undefined;
          
        if (
          this.itemToAddEdit.action && section &&
          section.config[this.itemToAddEdit.action].fields
        ) {
          if (this.itemToAddEdit.displayedFields)
            section.config[
              this.itemToAddEdit.action
            ].fields = this.itemToAddEdit.displayedFields;

          fields = fields.filter(f =>
            section.config[this.itemToAddEdit.action].fields.includes(f.name)
          );
        } else if (this.itemToAddEdit.fields) {
          fields = this.itemToAddEdit.fields;
        }

        if (this.itemToAddEdit.dataSource) {
          let menusMissing;
          fields.forEach(field => {
            if (field.responseDataSource) {
              field.dataSource = this.itemToAddEdit.dataSource[
                field.responseDataSource
              ];
              if (!field.dataSource && !this.actualView?.parent) menusMissing = true;
            }
            if(field.fieldType === 'selectBox' && field.name === "allowedValuesValidation" && field.dataSource){
                let filtered = [];
                field.dataSource.forEach((el,index) => {
                  filtered.push(el.header ? {header: i18n.t(el.header.charAt(0).toUpperCase()+el.header.slice(1))} : {...el, value: el.id});
                  if(el.header && (!field.dataSource[index+1] || field.dataSource[index+1].header)) filtered.push({name:i18n.t(`noData.allowedValuesValidation.${i18n.t(el.header)}`), disabled: true});
                })
                this.itemToAddEdit.dataSource.allowedValuesValidation = filtered
            }
          });
          if (menusMissing)
            fields.unshift({
              name: "alert",
              alert: {
                config: {
                  type: "error",
                  label: i18n.t("hasBeenProblemTryAgain"),
                  icon: "mdi-alert-circle"
                }
              }
            });
        }

        fields.forEach(field => {
          if (
            field.fieldType === "radioGroup" &&
            this.itemToAddEdit.currentDataValues &&
            this.itemToAddEdit.currentDataValues[field.name]
          )
            field.optionsToCheck = [
              {
                field: field.name,
                value: this.itemToAddEdit.currentDataValues[field.name]
              }
            ];

          if (this.itemToAddEdit.dataSource)
            field.dataSource = this.itemToAddEdit.dataSource[
              field.responseDataSource
            ];
        });
        if(fields.some(({name})=>name==="position"))this.setPosition()
        return fields;
      } else return null;
    },
    currentDataValues() {
      return this.itemToAddEdit?.currentDataValues || null
    },
    globalValues() {
      if (this.itemToAddEdit && this.itemToAddEdit.globalValues)
        return this.itemToAddEdit.globalValues;
      else return null;
    },
    slotFieldName() {
      if (this.itemToAddEdit.section === "InputDefinitions" || this.itemToAddEdit.section === "OutputDefinitions") {
        return "defaultDatePattern"
      } else if (this.itemToAddEdit.section === "InputDefinitionsDetail" || this.itemToAddEdit.section === "OutputDefinitionsDetail") {
        return this.itemToAddEdit.data.params.msgAction === "editParent" ? "defaultDatePattern" : "datePattern"  
      } else return undefined
    },
  },
  methods: {
    formSubmitted(form) {
      this.dirty = form.dirty;
      this.executeAction({ dirty: this.dirty, action: "cancel" });
      this.getStatus = false;
    },
    cancel(event) {
      if (!((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") || !this.itemToAddEdit) return;
      this.getStatus = true;
    },
    sendForm($event){
      const { currentDataValues, data, section, transFormValuesBeforeSendForm, cloneItem, originalItem } = this.itemToAddEdit || {};
      if ((this.actualView.tableActions?.some(({restOneToPosition}) => restOneToPosition) || 
      this.actualView.componentActions?.some(({restOneToPosition}) => restOneToPosition) || 
      this.actualView.actions?.some(({restOneToPosition}) => restOneToPosition)) && 
      data?.params?.msgAction === "addColumn") {
        $event.values.position -= 1
      }
      if (cloneItem) {
        Object.keys($event.values).forEach(val=>{
          delete this.itemToAddEdit.currentDataValues[val]
        })
        $event.action = 'add'
        $event.values = {...$event.values,...currentDataValues}
      }
      if (transFormValuesBeforeSendForm) $event.values = {...this.transformDataForSplitConfiguration($event.values, originalItem, currentDataValues)}

      this.executeAction({ ...$event, section, data })
    },
    transformDataForSplitConfiguration(e, { splitConfiguration }, currentValues) {
      if (!splitConfiguration || !splitConfiguration.linesReferences || !splitConfiguration.linesReferences.length) {
        splitConfiguration = { linesReferences: [ { columnReferences: [] } ] }
      }
      const { linesReferences } = splitConfiguration;

      if (e.value && typeof e.value === 'object' && Array.isArray(e.value)) {
        let newColumns = e.value.map((name, index) => {
          return ({
            newAlias: name,
            position: index
          })
        })

        for (let row of linesReferences) {
          const { columnReferences } = row
          newColumns.forEach(column => {
            this.$set(columnReferences, columnReferences.length  ,{ ...column, position: columnReferences.length   })
          })
        }
  
        return splitConfiguration
      }

      for (let row of linesReferences) {
        const { columnReferences } = row
        columnReferences.map((column) => {
          if (column.newAlias === currentValues.text) {
            column.newAlias = e.value 
          } 
        })
      }

      return {...splitConfiguration, nameNotification: e.value}
    },
    setPosition(){
      if(!this.itemToAddEdit?.currentDataValues?.position) {
        const positions = this.itemToAddEdit.globalValues?.length ? this.itemToAddEdit?.globalValues?.map(obj => obj.position) : [0];
        const maxPos = Math.max(...positions);
        for (let i=1; i<=maxPos+1; i++) {
          if(!positions.includes(i)) {
            return this.itemToAddEdit.currentDataValues.position = i;
          }
        }
      }
    },
  },
  watch: {
    itemToAddEdit() {
      this.resetForm = this.itemToAddEdit ? false : true;
      if (!this.itemToAddEdit) this.alerts = undefined;
      this.submitForm = false;
      this.getStatus = false;
      this.dirty = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.v-dialog__content--active {
  ::v-deep {
    .addEditModal {
    border-radius: 20px;
    max-height: 100%;
    min-height: 95vh;
    overflow-x: auto;
    z-index: 7;
    position: fixed;
  .contaninerModal {
    height: 95vh;
     ::-webkit-scrollbar {
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f2f5;
      transition: background-color .3s ease-out;
    }
    
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f1f2f5;
      transition: background-color .3s ease-out;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 120px;
      background-color: #a2a2a2;
      transition: background-color .3s ease-out;
    }
  }
  .modalHeader {
    width: 100%;
    .modalTitle {
      font-size: 20px;
      font-weight: 400;
    }
    .modalSubtitle {
      font-size: 0.875rem;
    }
  }
  .modalFooter {
    width: 100%;
    display: flex;
    align-items: center;
    .cancelButton {
      border-color: var(--borderGray);
      color: var(--fontColorSecondary);
    }
    button {
      flex: 1;
      text-transform: none;
      i {
        font-size: 20px;
      }
      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
      + button {
        margin-left: 10px;
      }
    }
  }
  }
}

  ::v-deep {
    .formContainer {
      padding: 12px 12px;
      .v-form {
        margin-top: 0px !important;        
      }
      .col-12 {
        padding: 6px !important;
      }
      .col-6 {
        padding: 0px 12px 0px 6px;
      }
    }
    .v-alert__icon {
      align-self: center;
    }
    .v-alert {
      font-size: 0.875rem;
    }
  }
}
.addEditModal {
  overflow: visible;
}
</style>
