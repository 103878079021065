var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info-wrp"},[(
      _vm.createKeyValueListModel(_vm.item).defaultItems &&
      _vm.createKeyValueListModel(_vm.item).defaultItems.length
    )?_c('div',{staticClass:"d-flex flex-wrap flex-row justify-content-center"},_vm._l((_vm.createKeyValueListModel(_vm.item).defaultItems),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"mr-1 expandible-key"},[_vm._v(" "+_vm._s(element.key)+": "),(
            typeof element.value === 'string' ||
            typeof element.value === 'number'
          )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1 d-inline-flex",attrs:{"hide-details":"","readonly":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(_vm.item && (_vm.item.validationOptionalType || _vm.item.allowedValuesValidation || _vm.item.range))?_c('p',{staticClass:"expandibleSubtitle mb-4",class:{'single-subtitle': _vm.item &&( ['Double', 'Integer'].includes(_vm.item.dataType) || _vm.item.decimalSeparator || _vm.item.datePattern)}},[_vm._v(" "+_vm._s(_vm.$t('validationOf', { validation: (_vm.item.range ? _vm.$t('range'): _vm.$t('allowedValuesValidation')).toLowerCase() }))+" ")]):_vm._e(),(
      _vm.createKeyValueListModel(_vm.item).section.values &&
      _vm.createKeyValueListModel(_vm.item).section.values.length
    )?_c('div',{staticClass:"d-flex flex-wrap flex-row justify-content-center",class:{'expandibleHolder': _vm.item && _vm.item.validationOptionalType}},_vm._l((_vm.createKeyValueListModel(_vm.item).section.values),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
            typeof element.value === 'string' ||
            typeof element.value === 'number'
          )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(
      _vm.createKeyValueListModel(_vm.item).defaultItems &&
      _vm.createKeyValueListModel(_vm.item).section.values &&
      !_vm.createKeyValueListModel(_vm.item).defaultItems.length &&
      !_vm.createKeyValueListModel(_vm.item).section.values.length
    )?_c('div',[(_vm.item.joinColumns)?_c('div',_vm._l((_vm.item.joinColumns),function(column,index){return _c('div',{key:index,class:[
          _vm.item.joinColumns &&
          _vm.item.joinColumns.length &&
          index !== _vm.item.joinColumns.length - 1
            ? 'borderBottomExpandible'
            : '',
          index && 'expandibleHolder',
        ]},[_c('p',{staticClass:"expandibleTitle"},[_vm._v(" "+_vm._s(_vm.traduceItem("value"))+" "+_vm._s(index + 1)+" ")]),_c('p',{staticClass:"expandibleSubtitle"},[_vm._v(" "+_vm._s(_vm.traduceItem("assignedValueBy"))+" "+_vm._s(_vm.traduceItem(_vm.setAssignedValue(column)))+" ")]),(
            _vm.createKeyValueListModel(column).defaultItems &&
            _vm.createKeyValueListModel(column).defaultItems.length
          )?_c('p',_vm._l((_vm.createKeyValueListModel(column)
              .defaultItems),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"mr-1 expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                  typeof element.value === 'string' ||
                  typeof element.value === 'number'
                )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(
            _vm.createKeyValueListModel(column).section.values &&
            _vm.createKeyValueListModel(column).section.values.length
          )?_c('p',{staticClass:"d-flex flex-wrap flex-row justify-content-center"},_vm._l((_vm.createKeyValueListModel(column).section
              .values),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('p',{staticClass:"expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                  typeof element.value === 'string' ||
                  typeof element.value === 'number'
                )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e()])}),0):(_vm.item.conditionals && _vm.item.conditionals !== '--')?_c('div',_vm._l((_vm.item.conditionals),function(conditional,index){return _c('div',{key:index,class:[
          _vm.item.conditionals &&
          _vm.item.conditionals.length &&
          index !== _vm.item.conditionals.length - 1
            ? 'borderBottomExpandible'
            : '',
          index && 'expandibleHolder',
        ]},[_c('p',{staticClass:"expandibleTitle"},[_vm._v(_vm._s(_vm.traduceItem("case"))+" "+_vm._s(index + 1))]),_c('div',[_c('p',{staticClass:"expandibleSubtitle"},[_vm._v(_vm._s(_vm.traduceItem("conditions")))]),(conditional.conditions && conditional.conditions.length)?_c('p',_vm._l((conditional.conditions),function(condition,i){return _c('v-chip',{key:i,staticClass:"mr-2",attrs:{"text-color":'var(--fontColor)'}},[_vm._v(" "+_vm._s(_vm.formatCondition(condition))+" ")])}),1):_c('p',{staticStyle:{"color":"var(--fontColorTerciary)"}},[_vm._v(" "+_vm._s(_vm.traduceItem("noConditionsApplied"))+" ")]),(
              Object.values(conditional.conditionalColumn).some(
                (val) => val !== null && typeof val !== 'boolean'
              )
            )?_c('div',[_c('p',{staticClass:"expandibleSubtitle"},[_vm._v(" "+_vm._s(_vm.traduceItem("assignedValueBy"))+" "+_vm._s(_vm.traduceItem(_vm.setAssignedValue(conditional.conditionalColumn)))+" ")]),(
                _vm.createKeyValueListModel(conditional.conditionalColumn)
                  .defaultItems &&
                _vm.createKeyValueListModel(conditional.conditionalColumn)
                  .defaultItems.length
              )?_c('p',_vm._l((_vm.createKeyValueListModel(
                  conditional.conditionalColumn
                ).defaultItems),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"mr-1 expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                      typeof element.value === 'string' ||
                      typeof element.value === 'number'
                    )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(
                _vm.createKeyValueListModel(conditional.conditionalColumn).section
                  .values &&
                _vm.createKeyValueListModel(conditional.conditionalColumn).section
                  .values.length
              )?_c('p',{staticClass:"d-flex flex-wrap flex-row justify-content-center"},_vm._l((_vm.createKeyValueListModel(
                  conditional.conditionalColumn
                ).section.values),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                      typeof element.value === 'string' ||
                      typeof element.value === 'number'
                    )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== '' ? element.value : '--')+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(conditional.conditionalColumn.joinColumns)?_c('div',_vm._l((conditional.conditionalColumn
                  .joinColumns),function(column,index){return _c('div',{key:index,staticClass:"mt-6 conditionalJoinColumn"},[_c('p',{staticStyle:{"font-weight":"500","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.traduceItem("value"))+" "+_vm._s(index + 1)+" ")]),_c('p',{staticClass:"expandibleSubtitle"},[_vm._v(" "+_vm._s(_vm.traduceItem("assignedValueBy"))+" "+_vm._s(_vm.traduceItem(_vm.setAssignedValue(column)))+" ")]),(
                    _vm.createKeyValueListModel(column).defaultItems &&
                    _vm.createKeyValueListModel(column).defaultItems.length
                  )?_c('p',_vm._l((_vm.createKeyValueListModel(column)
                      .defaultItems),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',{staticClass:"mr-1 expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                          typeof element.value === 'string' ||
                          typeof element.value === 'number'
                        )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e(),(
                    _vm.createKeyValueListModel(column).section.values &&
                    _vm.createKeyValueListModel(column).section.values.length
                  )?_c('p',{staticClass:"d-flex flex-wrap flex-row justify-content-center"},_vm._l((_vm.createKeyValueListModel(column)
                      .section.values),function(element,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('p',{staticClass:"expandible-key my-2"},[_vm._v(" "+_vm._s(element.key)+": "),(
                          typeof element.value === 'string' ||
                          typeof element.value === 'number'
                        )?_c('span',{staticClass:"valueOfKey"},[_vm._v(" "+_vm._s(element.value !== "" ? element.value : "--")+" ")]):_c('v-switch',{staticClass:"toogle ml-1",attrs:{"readonly":"","hide-details":""},model:{value:(element.value),callback:function ($$v) {_vm.$set(element, "value", $$v)},expression:"element.value"}})],1)])}),1):_vm._e()])}),0):_vm._e()]):_vm._e()])])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.traduceItem("noDataInfoColumn"))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }