<template>   
   <div :style="{ 'max-height': `calc(100vh - ${headerContainer}px)` }" class="container-list">
    <div v-for="(listItem, index) in dataComponent" :key="index" class="card-list">
      <Card class="pr-2 ma-20 mt-2">
        <CardHeader :to="actualView.path + listItem.id">
          {{ listItem.name }}

          <template v-slot:afterHeader>
            <Actions
              v-if="cardActions && cardActions.length"
              :actions="cardActions"
              @action-selected="onActionSelected($event, listItem)"
            />
          </template>
        </CardHeader>
      </Card>
    </div>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import { tools } from "@/mixins/tools";
import editableDataFields from "@/mixins/editable-data-fields";

export default {
  name: "List",
  data: () => ({    
  }),
  props: {
    dataComponent: { type: Array },
    headerHeight: { type: Number }
  },
  mixins: [tools],
  methods: {
    ...mapActions([
      "setItemToConfirmAction",
      "setItemToAddEdit"
    ]),
    onActionSelected(event, listItem) {  
      let copySelectedItem = this.deepCopyFunction(listItem);
      let fields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.fields
      );
      let configEditableDataFields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.config
      );
      let dataSource = this.getDataSource();
      switch (event.action) {
        case "delete":         
          this.setItemToConfirmAction({
            title: i18n.t(`delete${this.actualView.name}`),
            text: i18n.t(`delete${this.actualView.name}Question`),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: listItem.id,
                name: copySelectedItem.name
              },
              method: "deleteDocument",
            },
          });
          break;
        case "edit":
          this.setItemToAddEdit({
            section: this.actualView.name,
            fields,
            dataSource,
            configEditableDataFields,
            currentDataValues: copySelectedItem,
            globalValues: this.deepCopyFunction(this.dataView),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: copySelectedItem.id,
              },
              method: "updateDocument",
            },
          });
          break;
          case "clone":
            delete listItem.id
          this.setItemToAddEdit({
            section: this.actualView.name,
            fields,
            globalValues: this.deepCopyFunction(this.dataView),
            currentDataValues: {...listItem, name: this.setCloneName(listItem,this.dataView)},
            configEditableDataFields,
            dataSource,
            cloneItem:true,
            data: {
              params: { collectionName: this.actualView.get[0].collection },
              method: "insertDocument",
            },
          });
          break;
      }
    },
  },
  computed: {
    ...mapState(["actualView", "dataView"]),
    cardActions() {
      return this.setActions(this.actualView.componentActions);
    },
    headerContainer() {
       return this.headerHeight + 32;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-list {
  overflow: auto;
  padding-right: 6px;
  padding-left: 6px;
  .card-list:not(:last-child) {
    margin-bottom: 20px;
  }
  .card-list:last-child {
    margin-bottom: 10px;
  }
}
::v-deep {
  .card-after-header {
    display: flex;
  }
}
</style>
