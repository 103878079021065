import i18n from "@/plugins/i18n";

export default {
  computed: {
    primaryButtonAdd() {
      return {
        icon: "mdi-plus",
        text: i18n.t("add"),
      }
    },
    secondaryButton() {
      return {
        icon: "mdi-close",
        text: i18n.t("cancel"),
      }
    },
    primaryButtonEdit() {
      return {
        icon: "mdi-content-save",
        text: i18n.t("save"),
      }
    },
    primaryButtonClone(){
      return {
        icon: "mdi-content-copy",
        text: i18n.t("clone"),
      }
    },
    InputDefinitions() {
      return{
        edit: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editInputDefinitions"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
          ]     
        },
        add: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addInputDefinitions")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
          ]     
        },
        clone:{
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneInputDefinitions")
          },
          fields:[
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
          ]
        }
      }
    },
    OutputDefinitions() {
      return{
        edit: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editOutputDefinitions"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileName",
              label: i18n.t("fileName"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")}
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "inputDefinitionAssociate",
              label: i18n.t("inputDefinitionAssociate"),
              fieldType: "sectionTitle",
            },
            {
              name: "inputDescriptorReference",
              label: i18n.t("inputDefinitionName"),
              fieldType: "selectBox", 
              responseDataSource: "inputDefinitionsData",
              returnObject: true,
              info: {
                color: 'var(--primary)',
                text: i18n.t("inputDescriptorReferenceTooltip"),
                maxWidth: 200
              }
            },
          ]     
        },
        add: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addOutputDefinitions")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileName",
              label: i18n.t("fileName"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")}
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "inputDefinitionAssociate",
              label: i18n.t("inputDefinitionAssociate"),
              fieldType: "sectionTitle",
            },
            {
              name: "inputDescriptorReference",
              label: i18n.t("inputDefinitionName"),
              fieldType: "selectBox", 
              responseDataSource: "inputDefinitionsData",
              returnObject: true,
              info: {
                color: 'var(--primary)',
                text: i18n.t("inputDescriptorReferenceTooltip"),
                maxWidth: 200
              }
            },
          ]     
        },
        clone:{
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneOutputDefinitions")
          },
          fields:[
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
          ]
        }
      }
    },
    InputDefinitionsDetail() {
      return{
        addColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addColumn")
          },
          fields: [
            {
              name: "alias",
              label: i18n.t("alias"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "position",
              label: i18n.t("position"),
              fieldType: "number",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("positionAlreadyExists")},                 
                minValue: {msg: i18n.t("valueGreaterThanZero"), value: 1}
                 
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("positionTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "nullable",
              label: i18n.t("nullable"),
              fieldType: "toggle", 
            },
            {
              name: "dataType",
              label: i18n.t("dataType"),
              fieldType: "selectBox", 
              responseDataSource: "dataType",
              validators: {
                required: {msg: i18n.t("required")}
              },
            },
            {
              name: "datePattern",
              label: i18n.t("datePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("datePatternTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DATE"]
                }]
              },
            },
            {
              name: "decimalSeparator",
              label: i18n.t("decimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("decimalSeparatorTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "sumValues",
              label: i18n.t("sumValues"),
              fieldType: "toggle", 
              info: {
                color: 'var(--primary)',
                text: i18n.t("sumValuesTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "optionalValidations",
              label: i18n.t("optionalValidations"),
              fieldType: "sectionTitle",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["INTEGER", "DOUBLE", "DATE", "STRING", "BOOLEAN", "PERCENTAGE", "MAIL"],
                }]
              },
            },
            {
              name: "validationOptionalType",
              label: i18n.t("validationOptionalType"),
              fieldType: "selectBox", 
              responseDataSource: "validationOptionalType",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "allowedValuesValidation",
              label: i18n.t("allowedValuesValidation"),
              fieldType: "selectBox", 
              returnObject: true,
              responseDataSource: "allowedValuesValidation",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["STRING", "DATE", "BOOLEAN", "MAIL"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["allowedValuesValidation"]
                }
              ]
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("allowedValuesValidationTooltip"),
                maxWidth: 200
              },
            },
            {
              name: "from",
              label: i18n.t("from"),
              columns: 6,
              fieldType: "number",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
            {
              name: "to",
              label: i18n.t("to"),
              columns: 6,
              fieldType: "number",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
            {
              name: "rangeType",
              label: i18n.t("rangeType"),
              fieldType: "selectBox", 
              responseDataSource: "rangeType",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
          ]     
        },
        editParent: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editInputDefinitions"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
          ]     
        },
        editColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editColumn"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "alias",
              label: i18n.t("alias"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "position",
              label: i18n.t("position"),
              fieldType: "number",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("positionAlreadyExists")},
                minValue: {msg: i18n.t("valueGreaterThanZero"), value: 1}
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("positionTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "nullable",
              label: i18n.t("nullable"),
              fieldType: "toggle", 
            },
            {
              name: "dataType",
              label: i18n.t("dataType"),
              fieldType: "selectBox", 
              responseDataSource: "dataType",
              validators: {
                required: {msg: i18n.t("required")}
              },
            },
            {
              name: "datePattern",
              label: i18n.t("datePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("datePatternTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DATE"]
                }]
              },
            },
            {
              name: "decimalSeparator",
              label: i18n.t("decimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("decimalSeparatorTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "sumValues",
              label: i18n.t("sumValues"),
              fieldType: "toggle", 
              info: {
                color: 'var(--primary)',
                text: i18n.t("sumValuesTooltip"),
                maxWidth: 200
              },
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "optionalValidations",
              label: i18n.t("optionalValidations"),
              fieldType: "sectionTitle",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["INTEGER", "DOUBLE", "DATE", "STRING", "BOOLEAN", "PERCENTAGE", "MAIL"],
                }]
              },
            },
            {
              name: "validationOptionalType",
              label: i18n.t("validationOptionalType"),
              fieldType: "selectBox", 
              responseDataSource: "validationOptionalType",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                }]
              },
            },
            {
              name: "allowedValuesValidation",
              label: i18n.t("allowedValuesValidation"),
              fieldType: "selectBox", 
              returnObject: true,
              responseDataSource: "allowedValuesValidation",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["STRING", "DATE", "BOOLEAN", "MAIL"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["allowedValuesValidation"]
                }
              ]
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("allowedValuesValidationTooltip"),
                maxWidth: 200
              },
            },
            {
              name: "from",
              label: i18n.t("from"),
              columns: 6,
              fieldType: "number",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
            {
              name: "to",
              label: i18n.t("to"),
              columns: 6,
              fieldType: "number",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
            {
              name: "rangeType",
              label: i18n.t("rangeType"),
              fieldType: "selectBox", 
              responseDataSource: "rangeType",
              validators: {
                dependsOn: [{
                  field: 'dataType',
                  values: ["DOUBLE", "INTEGER", "PERCENTAGE"]
                },
                {
                  field: 'validationOptionalType',
                  values: ["range"]
                },
              ]
              },
            },
          ]   
        },
        clone:{
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneInputDefinitions")
          },
          fields:[
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
          ]
        }
      }
    },
    OutputDefinitionsDetail() {
      return{
        addColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addColumn")
          },
          fields: [
            {
              name: "alias",
              label: i18n.t("alias"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                uniqueValues: {msg: i18n.t("valueCombinationRepeated"), fields: ['alias', 'newAlias']}
              }
            },
            {
              name: "newAlias",
              label: i18n.t("newAlias"),
              fieldType: "textBox",
              validators: {
                uniqueValues: {msg: i18n.t("valueCombinationRepeated"), fields: ['alias', 'newAlias']}
              },      
              info: {
                color: 'var(--primary)',
                text: i18n.t("aliasTooltip"),
                maxWidth: 200
              }       
            },
            {
              name: "decimalSeparator",
              label: i18n.t("decimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("decimalSeparatorTooltipOutput"),
                maxWidth: 200
              }  
            },
            {
              name: "datePattern",
              label: i18n.t("datePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("datePatternTooltipOutput"),
                maxWidth: 200
              } 
            },
            {
              name: "transformationType",
              label: i18n.t("transformationType"),
              fieldType: "selectBox", 
              responseDataSource: "transformationType",
            },
            {
              name: "dictionaryTransformation",
              label: i18n.t("dictionaryTransformation"),
              fieldType: "selectBox", 
              responseDataSource: "dictionaries",
              returnObject: true,
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["DICTIONARY"]
                }]
              }
            },
            {
              name: "dateColumnName",
              label: i18n.t("dateColumnName"),
              fieldType: "textBox", 
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "timeStepColumnName",
              label: i18n.t("timeStepColumnName"),
              fieldType: "textBox", 
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "timeStepValue",
              label: i18n.t("tenor"),
              responseDataSource: "tenor",
              fieldType: "tenor",
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "arithmeticOperator",
              label: i18n.t("operator"),
              fieldType: "selectBox", 
              responseDataSource: "operators",
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "limitDate",
              label: i18n.t("limitDate"),
              fieldType: "textBox", 
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("limitDateTooltipOutput"),
                maxWidth: 200
              } 
            }
          ]     
        },
        editParent: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editOutputDefinitions"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "fileName",
              label: i18n.t("fileName"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")}
              }
            },
            {
              name: "fileType",
              label: i18n.t("fileType"),
              fieldType: "selectBox", 
              responseDataSource: "fileType",
              validators: {
                required: {msg: i18n.t("required")},
              }
            },
            {
              name: "headerRow",
              label: i18n.t("headerRow"),
              fieldType: "number",
              validators:{
                greaterThan: {
                  msg: i18n.t('shouldBeGreatherThan',{element: 0})
                  ,value:0
                }
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("headerRowTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "optionalParameters",
              label: i18n.t("optionalParameters"),
              fieldType: "sectionTitle",
            },
            {
              name: "defaultDatePattern",
              label: i18n.t("defaultDatePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDatePatternTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "defaultDecimalSeparator",
              label: i18n.t("defaultDecimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("defaultDecimalSeparatorTooltip"),
                maxWidth: 200
              }
            },
            {
              name: "inputDefinitionAssociate",
              label: i18n.t("inputDefinitionAssociate"),
              fieldType: "sectionTitle",
            },
            {
              name: "inputDescriptorReference",
              label: i18n.t("inputDefinitionName"),
              fieldType: "selectBox", 
              responseDataSource: "inputDefinitionsData",
              returnObject: true,
              info: {
                color: 'var(--primary)',
                text: i18n.t("inputDescriptorReferenceTooltip"),
                maxWidth: 200
              }
            },
          ]        
        },
        editColumn: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editColumn"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "alias",
              label: i18n.t("alias"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                uniqueValues: {msg: i18n.t("valueCombinationRepeated"), fields: ['alias', 'newAlias']}
              }
            },
            {
              name: "newAlias",
              label: i18n.t("newAlias"),
              fieldType: "textBox",
              validators: {
                uniqueValues: {msg: i18n.t("valueCombinationRepeated"), fields: ['alias', 'newAlias']}
              },      
              info: {
                color: 'var(--primary)',
                text: i18n.t("aliasTooltip"),
                maxWidth: 200
              }       
            },
            {
              name: "decimalSeparator",
              label: i18n.t("decimalSeparator"),
              fieldType: "selectBox", 
              responseDataSource: "defaultDecimalSeparator",
              info: {
                color: 'var(--primary)',
                text: i18n.t("decimalSeparatorTooltipOutput"),
                maxWidth: 200
              }  
            },
            {
              name: "datePattern",
              label: i18n.t("datePattern"),
              fieldType: 'custom',
              responseDataSource: "dateFormat",
              info: {
                color: 'var(--primary)',
                text: i18n.t("datePatternTooltipOutput"),
                maxWidth: 200
              } 
            },
            {
              name: "transformationType",
              label: i18n.t("transformationType"),
              fieldType: "selectBox", 
              responseDataSource: "transformationType",
            },
            {
              name: "dictionaryTransformation",
              label: i18n.t("dictionaryTransformation"),
              fieldType: "selectBox", 
              responseDataSource: "dictionaries",
              returnObject: true,
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["DICTIONARY"]
                }]
              }
            },
            {
              name: "dateColumnName",
              label: i18n.t("dateColumnName"),
              fieldType: "textBox", 
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "timeStepColumnName",
              label: i18n.t("timeStepColumnName"),
              fieldType: "textBox", 
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "timeStepValue",
              label: i18n.t("tenor"),
              responseDataSource: "tenor",
              fieldType: "tenor",
              validators: {
                required: {msg: i18n.t("required")},
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "arithmeticOperator",
              label: i18n.t("operator"),
              fieldType: "selectBox", 
              responseDataSource: "operators",
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              }
            },
            {
              name: "limitDate",
              label: i18n.t("limitDate"),
              fieldType: "textBox", 
              validators: {
                dependsOn: [{
                  field: 'transformationType',
                  values: ["ADD_TIME_STEP"]
                }]
              },
              info: {
                color: 'var(--primary)',
                text: i18n.t("limitDateTooltipOutput"),
                maxWidth: 200
              } 
            }
          ]     
        },
        clone: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneOutputDefinitions")
          },
          fields:[
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
          ]
        },
        addColumnSplit: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addColumns"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "value",
              label: i18n.t("name"),
              fieldType: "multipleFields",
              iconColorAddActive: "var(--primary)",
              iconColorDelete: "var(--primary)",
              validators: {
                required: { msg: i18n.t("required") },
                alreadyExists: { msg: i18n.t("nameAlreadyExists") },
                sameCombinationValuesSameField: { msg: i18n.t("columnRepeated") }
              },
            },
          ]
        },
        editHeaderCell: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editColumn"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "value",
              label: i18n.t("name"),
              fieldType: "textBox",
              validators: {
                required: { msg: i18n.t("required") },
                alreadyExists: { msg: i18n.t("nameAlreadyExists") },
                
              }
            },
          ]
        },
      }
    },
    Lists(){
      return{
        edit: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editList"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "values",
              label:  i18n.t("value"),
              fieldType: "multipleFields",
              iconColorAddActive: "var(--primary)",
              iconColorDelete: "var(--primary)",
              validators: {
                required: {msg: i18n.t("required")},
                sameCombinationValuesSameField: {msg: i18n.t("valueRepeated")}
              },
            },
          ]     
        },
        add: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addList")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "values",
              label:  i18n.t("value"),
              fieldType: "multipleFields",
              iconColorAddActive: "var(--primary)",
              iconColorDelete: "var(--primary)",
              validators: {
                required: {msg: i18n.t("required")},
                sameCombinationValuesSameField: {msg: i18n.t("valueRepeated")}
              },
            },
          ]     
        },
        
      }
    },
    Dictionaries(){
      return{
        edit: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: i18n.t("editDictionary"),
            alertNoChanges: i18n.t("alertNoChanges")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "values",
              labelKey: i18n.t("key"),
              labelValue: i18n.t("value"),
              fieldType: "multipleFields",
              iconColorAddActive: "var(--primary)",
              iconColorDelete: "var(--primary)",
              keyValue: true,
              validators: {
                availableAddFieldsWhenKeyOrValueSet: true,
                sameCombinationValuesSameField: {msg: i18n.t("keyRepeated")}
              },
            },
          ]     
        },
        add: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addDictionary")
          },
          fields: [
            {
              name: "name",
              label: i18n.t("name"),
              fieldType: "textBox",             
              validators: {
                required: {msg: i18n.t("required")},
                alreadyExists: {msg: i18n.t("nameAlreadyExists")},
              }
            },
            {
              name: "values",
              labelKey: i18n.t("key"),
              labelValue: i18n.t("value"),
              fieldType: "multipleFields",
              iconColorAddActive: "var(--primary)",
              iconColorDelete: "var(--primary)",
              keyValue: true,
              validators: {
                availableAddFieldsWhenKeyOrValueSet: true,
                sameCombinationValuesSameField: {msg: i18n.t("keyRepeated")}
              },
            },
          ]     
        },
        
      }
    },    
  },
}
