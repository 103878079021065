import store from '@/store'
export default class FormatData {
    constructor(form, dynamicForm, view, dynamicFormGroup = false, bottomFieldsForm = null, bottomDynamicFieldsForm = null, globalValues = undefined, indexesToUpdate = undefined) {
      this.form = form;
      this.dynamicForm = dynamicForm;
      this.view = view;
      this.data = {};
      this.conditionsToPush = {};
      this.indexesToUpdate = indexesToUpdate;
      this.joinColumns = [];
      this.dynamicFormGroup = dynamicFormGroup;
      this.bottomFieldsForm = bottomFieldsForm;
      this.bottomDynamicFieldsForm = bottomDynamicFieldsForm;
      this.globalValues = globalValues;
      this.atributesColumns = ['columnReference', 'fixedValue', 'wildcard', 'dictionary', 'addTenor'];
      this.atributesDynamicFieldsForm = ['columnReferencedBottomDynamic', 'fixedValueBottomDynamic', 'wildcardBottomDynamic', 'dictionaryBottomDynamic', 'addTenor'];
      this.secondTermMap = {
        INPUT_VALUE: 'fixedValue',
        WILDCARD: "wildcard",
        COLUMN_NAME: "columnAlias",
        fixedValue: 'INPUT_VALUE',
        wildcard: 'WILDCARD',
        columnAlias: 'COLUMN_NAME',
      }
    }
    setData() {
      switch (this.view) {
        case 'filters':
          this.data.name = this.form.filterName;
          this.data.conditions = [];
          this.dynamicForm.forEach(conditional => {
            this.data.conditions.push({
              initialColumnName: conditional.alias,
              comparisonOperator: conditional.conditional,
              endValueReference: conditional.secondTerm 
                ? {
                  option: this.secondTermMap[conditional.secondTerm],
                  value: conditional[conditional.secondTerm]
                } 
                :  null
            });
          });
          break;
          case 'addColumn':
          case 'addCellValue':
            if (this.dynamicFormGroup) {
              if (this.form.nameColumn) this.data.newAlias = this.form.nameColumn;
              this.data.conditionals = [];
              this.dynamicForm.forEach((itemCase, index) => {
                let eachCase = {
                  conditions: [],
                  conditionalColumn: {}
                };
                if (this.bottomDynamicFieldsForm[index]?.length && this.bottomFieldsForm[index].assignValueConditions === 'concatenateValues') {
                  eachCase.conditionalColumn.joinColumns = [];
                  this.bottomDynamicFieldsForm[index].forEach(valueToConcatenate => {
                    let objectJoinColumn = {};
                    this.atributesDynamicFieldsForm.forEach(atribute => objectJoinColumn = {
                      ...objectJoinColumn,
                      ...this.transformDataColumns(valueToConcatenate, atribute)
                    });
                    if (Object.values(objectJoinColumn).filter(Boolean).length) {
                      if (!eachCase.conditionalColumn.joinColumns) eachCase.conditionalColumn.joinColumns = [];
                      eachCase.conditionalColumn.joinColumns.push(objectJoinColumn);
                    }
                    if (!eachCase?.conditionalColumn?.joinColumns?.length) delete eachCase.conditionalColumn.joinColumns
                  })
                } else if (this.bottomFieldsForm[index].assignValueConditions !== 'concatenateValues' && eachCase.conditionalColumn.joinColumns) delete eachCase.conditionalColumn.joinColumns
                if (itemCase && itemCase.length) itemCase.forEach(element => eachCase.conditions.push(this.transformDataColumns(element, 'conditions')))
                if (this.bottomFieldsForm[index] && this.bottomFieldsForm[index].assignValueConditions !== 'concatenateValues') this.atributesColumns.forEach(atribute => eachCase.conditionalColumn = {...eachCase.conditionalColumn, ...this.transformDataColumns(this.bottomFieldsForm[index], atribute)})
                this.data.conditionals.push(eachCase);
              })
            } else {
              let assignValue = this.form.assignValue || this.form.assignValueDynamic;
              if (this.form.nameColumn) this.data.newAlias = this.form.nameColumn;
              if (this.dynamicForm && this.dynamicForm.length && assignValue === 'concatenateValues') {
                this.dynamicForm.forEach(item => {
                  this.joinColumns.push(this.transformDataColumns(item));
                });
              } else {
                this.atributesColumns.forEach(atribute => this.data = { ...this.data, ...this.transformDataColumns(this.form, atribute) })
              }
              if (this.joinColumns?.length) this.data.joinColumns = this.joinColumns
            }
            if (this.view === 'addCellValue') {
              let {position, newAlias} = this.globalValues[this.indexesToUpdate.rowIndex].columnReferences[this.indexesToUpdate.columnIndex];
              this.globalValues[this.indexesToUpdate.rowIndex].columnReferences[this.indexesToUpdate.columnIndex] = {...{newAlias, position}, ...this.data};
              this.data = {splitConfiguration: {linesReferences: this.globalValues}}
            }
            break;
        case 'dataValuesFilter':
          this.data.filterName = this.form;
          this.data.conditions = [];
          this.dynamicForm.forEach(conditional => {
            this.conditionsToPush = {
              alias: conditional.initialColumnName,
              secondTerm: conditional.endValueReference ? this.secondTermMap[conditional.endValueReference.option] : null,
              conditional: conditional.comparisonOperator
            };
            if (conditional.endValueReference && conditional.endValueReference.option) this.conditionsToPush[this.secondTermMap[conditional.endValueReference.option]] = conditional.endValueReference.value;
            this.data.conditions.push(this.conditionsToPush)
          });
          break;
        case 'columnsDataSource':
          this.data = this.form.map(element => element.alias);
          break;
        case 'dataValuesColumn':
        case 'dataValuesRowCell':
          this.data = this.tranformDataColumnEdit();
          this.data[this.view === 'dataValuesRowCell' ? 'defineHowToCreateValue' : 'defineCreateColumn'] = this.form.conditionals && this.form.conditionals.length ? 'withConditions' : 'withOutConditions';
          if (this.form.conditionals) {
            this.data.dynamicFormsFormGroup = [];
            this.data.bottomFieldsFormEdit = [];
            this.data.bottomDynamicFieldsFormEdit = [];
            this.data.formsGroup = this.form.conditionals;
            this.form.conditionals.forEach((conditional, indexConditional) => {
              let conditions = [];
              if (conditional.conditions &&  conditional.conditions.length) {
                conditional.conditions.forEach(condition => {
                  let itemCondition = {};
                  itemCondition.firstTerm = condition.initialColumnName;
                  itemCondition.conditional = condition.comparisonOperator;
                  itemCondition.secondTerm = ['EMPTY','NOT_EMPTY'].includes(condition.comparisonOperator) 
                  ? null
                  : condition.endValueReference.option === 'COLUMN_NAME' ? 'inputColumnName' : condition.endValueReference.option === 'INPUT_VALUE' ? 'fixedValue' : 'wildcard' ;
                  let options = ['inputColumnName', 'fixedValue', 'wildcard'];
                  options.forEach(option => itemCondition.secondTerm === option ? itemCondition[option] = condition.endValueReference.value : null);
                  conditions.push(itemCondition);
                })
              }
              if (conditional.conditionalColumn.joinColumns && conditional.conditionalColumn.joinColumns.length) {
                let dataObject = this.tranformDataColumnEdit(conditional.conditionalColumn, 'assignValueConditions', 'assignValueBottomDynamic');
                const { assignValueConditions, joinColumns} = dataObject;
                this.data.bottomFieldsFormEdit.push({assignValueConditions});
                this.data.bottomDynamicFieldsFormEdit[indexConditional] = joinColumns;
              }
              else this.data.bottomFieldsFormEdit.push(this.tranformDataColumnEdit(conditional.conditionalColumn, 'assignValueConditions'));
              this.data.dynamicFormsFormGroup.push(conditions);
            })
          }
          break;
        default:
          break;
      }
      return this.data;
    }
    tranformDataColumnEdit(form = this.form, assignValueDynamic = 'assignValue', assignValueJoinColumns, bottomDynamic = '') {
      let data = {};
      if (form.newAlias) data.nameColumn = form.newAlias;
      if (form.alias) {
        data[assignValueDynamic] = 'columnReference';
        data['columnReferenced' + bottomDynamic] = form.alias
      }
      if (form.columnReference) {
        if (form.columnReference.option === "INPUT_VALUE") {
          data[assignValueDynamic] = 'fixedValue';
          data['fixedValue' + bottomDynamic] = form.columnReference.value;
        } else if (form.columnReference.option === "COLUMN_NAME") {
          data[assignValueDynamic] = 'columnReference';
          data['columnReferenced' + bottomDynamic] = form.columnReference.value;
        }
        else {
          data[assignValueDynamic] = 'wildcard';
          data['wildcard' + bottomDynamic] = form.columnReference.value;
        }
      }
      if (form.dictionaryTransformation && form.dictionaryTransformation.id) {
        data[assignValueDynamic] = 'dictionary';
        data['dictionary' + bottomDynamic] = store.state.dictionariesData.find(item => item.id === form.dictionaryTransformation.id);
        data['columnNameDictionary' + bottomDynamic] = form.dictionaryTransformation.columnName;
      }
      if (form.addTimeStepTransformation && form.addTimeStepTransformation.arithmeticOperator) {
        data[assignValueDynamic] = 'addTenor';
        data['inputColumnName' + bottomDynamic] = form.addTimeStepTransformation.dateColumnName;
        data['operator' + bottomDynamic] = form.addTimeStepTransformation.arithmeticOperator;
        let sections = {
          tenorReference: {
            radioGroup: 'radioGroupAddTenor',
            options: {
              COLUMN_NAME: 'nameOfTenorTypeColumn',
              INPUT_VALUE: 'fixedValueForTenor',
              WILDCARD: 'wildcardOption'
            }
          },
          limitDateReference: {
            radioGroup: 'radioGroupAddTenorTypeDate',
            options: {
              COLUMN_NAME: 'nameOfDateTypeColumn',
              INPUT_VALUE: 'fixedValueForDate',
              WILDCARD: bottomDynamic === 'BottomDynamic' ? 'wildcardForDate' : 'wildcardOptionDate'
            }
          }
        };
        Object.keys(sections).forEach(section => {
          if (form.addTimeStepTransformation[section] && (form.addTimeStepTransformation[section].value || form.addTimeStepTransformation[section].value === '')) {
            Object.keys(sections[section].options).forEach(option => {
              if (form.addTimeStepTransformation[section].option === option) {
                data[sections[section]['radioGroup' + bottomDynamic]] = sections[section].options[option] + bottomDynamic;
                data[sections[section].options[option] + bottomDynamic] = form.addTimeStepTransformation[section].value;
              }
            });
          }
        })
      }
      if (form.joinColumns && form.joinColumns.length) {
        data[assignValueDynamic] = 'concatenateValues';
        let joinColumnsDynamic = [];
        form.joinColumns.forEach(value => {
          let item = this.tranformDataColumnEdit(value, assignValueJoinColumns ? assignValueJoinColumns : 'assignValueDynamic', null,  assignValueJoinColumns ? 'BottomDynamic' : '');
          joinColumnsDynamic.push(item)
        })
        data.joinColumns = joinColumnsDynamic;
      }
      return data;
    }
    transformDataColumns(item, atribute = null) {
      let objectToReturn;
      switch (atribute || item.assignValueDynamic) {
        case "conditions":
          return {
            initialColumnName: item.firstTerm,
            comparisonOperator: item.conditional,
            endValueReference: ['EMPTY','NOT_EMPTY'].includes(item.conditional) 
              ? null
              : {
                value: item.secondTerm === 'fixedValue' && item[item.secondTerm] === null 
                  ? '' 
                  : item[item.secondTerm],
                option: item.secondTerm === 'inputColumnName' 
                  ? 'COLUMN_NAME' 
                  : item.secondTerm === 'fixedValue'
                    ? 'INPUT_VALUE' 
                    : 'WILDCARD'
              }
          }
        case "columnReference":
        case "columnReferencedBottomDynamic":
          return {alias: item.columnReferenced || item.columnReferencedBottomDynamic ? item.columnReferenced || item.columnReferencedBottomDynamic : null} 
        case "fixedValue":
        case "wildcard":
        case "fixedValueBottomDynamic":
        case "wildcardBottomDynamic":
          return (!item.columnReferenced && !item.columnReferencedBottomDynamic && !['addTenor', 'dictionary'].includes(item.assignValue) && !['addTenor', 'dictionary'].includes(item.assignValueConditions) && !['addTenor', 'dictionary'].includes(item.assignValueBottomDynamic) ) && (item[atribute] || (['fixedValue', 'fixedValueBottomDynamic'].includes(atribute) && (item[atribute] === null || item[atribute] === ''))) || item[item.assignValueDynamic] || (['fixedValue', 'fixedValueBottomDynamic'].includes(item.assignValueDynamic) && (item[item.assignValueDynamic] === null || item[item.assignValueDynamic] === '')) ? {
            columnReference: {
              value: atribute && item[atribute] 
                ? item[atribute]
                : item[item.assignValueDynamic] || (([null, ''].includes(item[atribute]) || [null, ''].includes(item[item.assignValueDynamic])) && ''),
              option: (atribute && (atribute === "fixedValue" || atribute === 'fixedValueBottomDynamic')) || item.assignValueDynamic === "fixedValue" ? "INPUT_VALUE" : "WILDCARD",
            } 
          } : null
        case "dictionary":
        case "dictionaryBottomDynamic":
          return (atribute && item[atribute]) || (item.assignValueDynamic && item[item.assignValueDynamic]) ? {
            dictionaryTransformation: {id: atribute ?  item[atribute].id : item[item.assignValueDynamic].id, columnName: item[atribute && atribute === 'dictionaryBottomDynamic' ? 'columnNameDictionaryBottomDynamic' : 'columnNameDictionary'] }
          } : {dictionaryTransformation: null};
        case "addTenor":
          objectToReturn = {
            addTimeStepTransformation: item.inputColumnName || item.inputColumnNameBottomDynamic ? { 
              dateColumnName: item.inputColumnName || item.inputColumnNameBottomDynamic, 
              arithmeticOperator: item.operator || item.operatorBottomDynamic,
              tenorReference: 'radioGroupAddTenor' in item ? {
                  value: item.radioGroupAddTenor === 'nameOfTenorTypeColumn' 
                    ? item.nameOfTenorTypeColumn 
                    : item.radioGroupAddTenor === 'fixedValueForTenor' 
                      ? (item.fixedValueForTenor || ([null, ''].includes(item.fixedValueForTenor) && ''))
                      : (item.radioGroupAddTenor === 'wildcard' || item.radioGroupAddTenor === 'wildcardOption') 
                        ? item.wildcardOption 
                        : null,
                  option: item.radioGroupAddTenor === "nameOfTenorTypeColumn" 
                    ? 'COLUMN_NAME' 
                    : item.radioGroupAddTenor === 'fixedValueForTenor' 
                      ? 'INPUT_VALUE' 
                      : (item.radioGroupAddTenor === 'wildcard' || item.radioGroupAddTenor === 'wildcardOption') 
                        ? "WILDCARD" 
                        : null
              } : 
              {
                value: item.radioGroupAddTenorBottomDynamic === "nameOfTenorTypeColumnBottomDynamic" 
                  ? item.nameOfTenorTypeColumnBottomDynamic 
                  : item.radioGroupAddTenorBottomDynamic === 'fixedValueForTenorBottomDynamic' 
                    ? item.fixedValueForTenorBottomDynamic || ([null, ''].includes(item.fixedValueForTenorBottomDynamic) && '')
                    :  item.radioGroupAddTenorBottomDynamic === 'wildcardOptionBottomDynamic' 
                      ? item.wildcardOptionBottomDynamic 
                      : null,
                option: item.radioGroupAddTenorBottomDynamic === "nameOfTenorTypeColumnBottomDynamic" 
                  ? 'COLUMN_NAME'
                  : item.radioGroupAddTenorBottomDynamic === 'fixedValueForTenorBottomDynamic'
                    ? 'INPUT_VALUE' 
                    : item.radioGroupAddTenorBottomDynamic === 'wildcardOptionBottomDynamic' 
                      ? "WILDCARD" 
                      : null
              },
              limitDateReference: 'radioGroupAddTenor' in item ? {
                option: item.radioGroupAddTenorTypeDate === 'nameOfDateTypeColumn' && item.nameOfDateTypeColumn 
                  ? 'COLUMN_NAME' 
                  : item.radioGroupAddTenorTypeDate === 'fixedValueForDate' && (item.fixedValueForDate || (item.fixedValueForDate === null || item.fixedValueForDate === ''))
                    ? 'INPUT_VALUE'
                    :  item.radioGroupAddTenorTypeDate === 'wildcardOptionDate' && item.wildcardOptionDate 
                      ? "WILDCARD" 
                      : null,
                value: item.radioGroupAddTenorTypeDate === 'nameOfDateTypeColumn' 
                  ? item.nameOfDateTypeColumn 
                  : item.radioGroupAddTenorTypeDate === 'fixedValueForDate' 
                    ? item.fixedValueForDate || ([null, ''].includes(item.fixedValueForDate) && '')
                    : item.radioGroupAddTenorTypeDate === 'wildcardOptionDate' 
                      ? item.wildcardOptionDate 
                      : null,
              } :
              {
                option: item.radioGroupAddTenorTypeDateBottomDynamic === 'nameOfDateTypeColumnBottomDynamic' && item.nameOfDateTypeColumnBottomDynamic 
                  ? 'COLUMN_NAME' 
                  : item.radioGroupAddTenorTypeDateBottomDynamic === 'fixedValueForDateBottomDynamic' && (item.fixedValueForDateBottomDynamic || (item.fixedValueForDateBottomDynamic === null || item.fixedValueForDateBottomDynamic === '') )
                    ? 'INPUT_VALUE' 
                    : item.radioGroupAddTenorTypeDateBottomDynamic === "wildcardForDateBottomDynamic" 
                      ? "WILDCARD" 
                      : null,
                value: item.radioGroupAddTenorTypeDateBottomDynamic === 'nameOfDateTypeColumnBottomDynamic' 
                  ? item.nameOfDateTypeColumnBottomDynamic 
                  : item.radioGroupAddTenorTypeDateBottomDynamic === 'fixedValueForDateBottomDynamic' 
                    ? item.fixedValueForDateBottomDynamic || ([null, ''].includes(item.fixedValueForDateBottomDynamic) && '')
                    : item.radioGroupAddTenorTypeDateBottomDynamic === 'wildcardForDateBottomDynamic' 
                      ? item.wildcardForDateBottomDynamic 
                      : null,
              }
            } : null
          }

          if (objectToReturn?.addTimeStepTransformation?.limitDateReference && (!objectToReturn?.addTimeStepTransformation?.limitDateReference?.value && objectToReturn?.addTimeStepTransformation?.limitDateReference?.value !== '')) delete objectToReturn.addTimeStepTransformation.limitDateReference;
          return objectToReturn;
        default:
          break;
      }
    }
  }