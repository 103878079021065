<template>
  <div>
    <v-dialog :value="itemToAddEditStepper" :width="900" v-click-outside="cancel" content-class="dialog containerStepper">
      <v-card v-resize="onResize" ref="containerModal">
        <v-card-title v-if="itemToAddEditStepper && itemToAddEditStepper.config && itemToAddEditStepper.config.title" ref="title" class="stepper-modal-title">
          {{ itemToAddEditStepper.config.title }}
        </v-card-title>
        <v-card-text ref="content" class="text-content">
          <div class="d-flex contenedor" v-if="itemToAddEditStepper && steps && steps.length">
            <div class="mr-1 left-stepper" min-height="380" ref="leftStepper">
            <v-stepper v-model="currentStep" vertical flat>
              <div v-for="(step, index) in steps" :key="index" class="step-item" :ref="step.dynamicFormGroup ? 'dynamicFormGroup' : 'step' + index">
                <v-stepper-step
                  :complete="currentStep > index + 1"
                  :step="index + 1"
                  @click="selectStep(index + 1); defaultAlert();"
                  :style="{cursor: 'pointer'}"
                  :class="currentStep === index + 1 && step.dynamicFormGroup ? 'addDynamicFormsGroup' : ''">
                  <div :class="currentStep === index + 1 && step.dynamicFormGroup ? 'addDynamicFormsGroup' : ''">
                    {{step.title}}
                    <v-icon v-if="currentStep === index + 1 && step.dynamicFormGroup" @click="addDynamicForm()" :color="!alerts[currentStep-1]?.some((el,i)=>setDynamicFormAlert(i)) ? envTheme['--primary'] : '#999999'" :style="{cursor:!alerts[currentStep-1]?.some((el,i)=>setDynamicFormAlert(i)) ? 'pointer' : 'default'}">mdi-plus-circle</v-icon>
                  </div>
                  <div v-if="currentStep > index + 1  && !step.dynamicFormGroup" class="resumeStep">
                    <div v-for="(resume, indexResume) in step.resume.fields" :key="indexResume" class="itemResumeStep">
                      <span>{{resume.label}} </span> {{setResumeValues(step.resume.from, resume.value, resume.translateValue ? resume.translateValue : null)}} 
                    </div>
                  </div>
                  <div v-if="currentStep === index + 1 && step.dynamicFormGroup" class="formGroup" :style="{ 'max-height': maxHeighResumeForms + 'px', 'overflow-y': 'auto',  'overflow-x': 'hidden', 'padding-right': '5px'}">
                    <div v-for="(formGroup, indexFormGroup) in steps[currentStep - 1].dynamicFormGroup.forms" :key="indexFormGroup" @click="selectFormGroup(indexFormGroup)" class="itemGroup" :class="indexFormGroup === actualDynamicFormGroup ? 'selectedItemGroup' : ''" >
                      <span :style="{color: setDynamicFormAlert(indexFormGroup) ? 'var(--red)' : indexFormGroup === actualDynamicFormGroup ? envTheme['--primary'] : envTheme['--font']}">
                        <v-icon color="error" class="mr-1" v-if="setDynamicFormAlert(indexFormGroup)">mdi-alert-circle</v-icon>
                        {{ formGroup.title }}
                      </span>
                      <v-icon @click.native.stop="deleteItemGroup(indexFormGroup)" :color="steps[currentStep - 1].dynamicFormGroup.forms.length > 1 ? envTheme['--primary'] : '#999999 !important' " :style="{cursor: steps[currentStep - 1].dynamicFormGroup.forms.length > 1 ? 'pointer' : 'default'}">mdi-delete</v-icon>
                    </div>
                  </div>
                </v-stepper-step>
                <v-stepper-content :complete="currentStep > index + 1" :step="index + 1">
                </v-stepper-content>
              </div>
            </v-stepper>
          </div>
          <v-divider vertical></v-divider>
            <div class="ml-1 right-stepper" :style="{ 'max-height': heightRightStepper + 'px' }">
              <v-stepper :v-model="currentStep" vertical flat :style="{ 'max-height': heightRightStepper + 'px', 'overflow': 'auto', 'padding-right': '5px'}">
                <v-stepper-step
                  :complete="false"
                  :step="currentStep"
                >
                 {{steps[currentStep - 1].title}}
                </v-stepper-step>
                <v-alert
                  v-if="showAlert"
                  class="alert-container"
                  :class="showAlert && !alertEmptyForm && !alertInfoNewFormEmpty ? 'alert-margin' : ''"
                  icon="mdi-alert-circle"
                  outlined
                  type="error"
                  >{{ alertMsg }}</v-alert>
                   <v-alert
                  class="alert-container"
                  v-if="alertEmptyForm"
                  icon="mdi-alert-circle"
                  outlined
                  type="error"
                  >{{alertEmptyForm}}</v-alert>
                 <v-alert
                  class="alert-container"
                  v-if="alertInfoNewFormEmpty && !showExpansionPanelDynamicForm"
                  color="var(--blue)"
                  icon="mdi-alert-circle"
                  outlined
                  type="info"
                  >{{alertInfoNewFormEmpty}}</v-alert>
                <v-stepper-content :step="currentStep">
                   <Form
                    v-if="steps[currentStep - 1].fields && !steps[currentStep - 1].nativeForm"
                    ref="normalForm"
                    :key="fieldsNormalForm.length + steps[currentStep - 1].title"
                    :fields="fieldsNormalForm"
                    @updatedForm="getStatus = false; submitForm = false; updateForm($event, steps[currentStep - 1].fields)"
                    :globalValues="globalValues"
                    :getStatus="getStatus"
                    :currentDataValues="currentDataValues"
                    @formStatus="formSubmitted"
                    @formSubmitted="formSubmit($event, 'normalForm')"
                    :formContainerToScroll="formContainer"
                    primaryColor="var(--primary)"
                    :submitForm="submitForm"
                    :resetForm="resetForm"
                    :returnAlerts="true"
                    @alerts="alerts = $event"
                    @isValidForm="disabled = !$event.valid"
                    />
                    <div v-else-if="steps[currentStep - 1].nativeForm && steps[currentStep - 1].fields" class="ml-4">
                      <v-form ref="nativeForm">
                        <div 
                          v-for="field of steps[currentStep - 1].fields" 
                          :key="field.name">
                          <v-radio-group 
                            v-if="field.fieldType === 'radioGroup'" 
                            @change="updateForm({[field.name]: $event}, steps[currentStep - 1].fields, true)"
                            :value="valueRadioButton"
                          >
                            <v-radio 
                              v-for="option of field.dataSource" 
                              :label="option.name" 
                              :value="option.value" 
                              :key="option.name"></v-radio>
                          </v-radio-group>
                        </div>
                      </v-form>
                    </div>
                  <div v-if="(steps[currentStep - 1].dynamicForm && fieldsDynamicForm && fieldsDynamicForm.length) || (steps[currentStep - 1].dynamicFormGroup)" class="ml-4">
                    <div v-for="(group, indexGroup) in (steps[currentStep - 1].dynamicForm  ? steps[currentStep - 1].dynamicForm.forms : steps[currentStep - 1].dynamicFormGroup.forms)" :key="actualDynamicFormGroup + '-' + indexGroup"  >
                      <template v-if="indexGroup === actualDynamicFormGroup">
                        <div class="addDynamicForms mt-6 mb-2">
                          <span class="titleDynamicForm">
                            {{steps[currentStep - 1].dynamicFormGroup ? steps[currentStep - 1].dynamicFormGroup.forms[actualDynamicFormGroup].dynamicForm.title + ' ' + (actualDynamicFormGroup + 1) : steps[currentStep - 1].dynamicForm.title}}
                          </span>
                          <v-icon :key="availableAddForm + showExpansionPanelDynamicForm" :style="{ cursor: availableAddForm || !showExpansionPanelDynamicForm ? 'pointer' : 'default' }" @click="addForm()" :color="availableAddForm || !showExpansionPanelDynamicForm ? envTheme['--primary'] : '#999999'">mdi-plus-circle</v-icon>
                        </div>
                        <v-expansion-panels v-model="openedPanel" v-if="showExpansionPanelDynamicForm">
                          <v-expansion-panel v-for="(formName, index) in (steps[currentStep - 1].dynamicForm ? steps[currentStep - 1].dynamicForm.forms : steps[currentStep - 1].dynamicFormGroup.forms[actualDynamicFormGroup].dynamicForm.forms)" :key="index">
                              <v-expansion-panel-header class="headerButton">
                                <div class="dynamicForms">
                                  <div>
                                    <template v-if="alerts[currentStep-1]?.length && alerts[currentStep-1][actualDynamicFormGroup] && alerts[currentStep-1][actualDynamicFormGroup][`dynamicForms${actualDynamicFormGroup}-${index}`]">
                                      <v-icon color="error" class="mr-1">
                                        mdi-alert-circle
                                      </v-icon>
                                    </template> 
                                    <span :key="updateChips" class="titleItemForm" :style="{'color': alerts[currentStep-1]?.length && alerts[currentStep-1][actualDynamicFormGroup] && alerts[currentStep-1][actualDynamicFormGroup][`dynamicForms${actualDynamicFormGroup}-${index}`] === true ? 'var(--red)' : ''}" v-if="!chips[index] || !chips[index].length">{{formName}}</span>
                                    <v-chip :key="updateChips" v-if="chips[index] && chips[index].length">
                                      {{chips[index].join(' ')}}
                                    </v-chip>
                                  </div>
                                  <v-icon @click.native.stop="deleteForm(index)" :color="dynamicForms[actualDynamicFormGroup].length > 1 ? envTheme['--primary'] : '#999999'" :style="{cursor: dynamicForms[actualDynamicFormGroup].length > 1 ? 'pointer' : 'default'}">mdi-delete</v-icon>
                                </div>
                              </v-expansion-panel-header>
                            <v-expansion-panel-content :eager="true">
                              <Form
                                :ref="'dynamicForms' + actualDynamicFormGroup + '-' + index"
                                :key="actualDynamicFormGroup + formKey"
                                :fields="fieldsDynamicForm"
                                @updatedForm="getStatus = false; submitForm = false; updateDynamicForms($event, index)"
                                :globalValues="globalValues"
                                :getStatus="getStatus"
                                @formStatus="formSubmitted"
                                :currentDataValues="dynamicForms[actualDynamicFormGroup][index]"
                                @formSubmitted="formSubmit($event, 'dynamicFormsFormGroup')"
                                :formContainerToScroll="formContainer"
                                :submitForm="submitForm"
                                :resetForm="resetForm"
                                :returnAlerts="true"
                                @alerts="alerts = $event"
                                @isValidForm="disabled = !$event.valid"
                                primaryColor="var(--primary)"
                                />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-else-if="labelNewFormEmpty" class="labelNewFormEmpty py-5">{{labelNewFormEmpty}}</div>
                         <Form
                          v-if="group.bottomFields"
                          :ref="'bottomFields' + actualDynamicFormGroup"
                          :key="fieldsNormalBottomForm.length + steps[currentStep - 1].title + formKey"
                          :fields="fieldsNormalBottomForm"
                          @updatedForm="getStatus = false; submitForm = false; updateBottomForm($event)"
                          :globalValues="globalValues"
                          :getStatus="getStatus"
                          :currentDataValues="bottomFieldsForm[actualDynamicFormGroup]"
                          @formStatus="formSubmitted"
                          @formSubmitted="formSubmit($event, 'bottomFields')"
                          :formContainerToScroll="formContainer"
                          :submitForm="submitForm"
                          :resetForm="resetForm"
                          :returnAlerts="true"
                          @alerts="alerts = $event"
                          @isValidForm="disabled = !$event.valid"
                          primaryColor="var(--primary)"
                          class="bottom-fields"
                          />
                          <div v-if="group.dynamicBottomForm && fieldsDynamicBottomForm && fieldsDynamicBottomForm.length" >
                             <div class="addDynamicForms mt-6 mb-2">
                          <span class="titleDynamicForm">
                            {{group.dynamicBottomForm.title}}
                          </span>
                          <v-icon :key="availableAddFormBottom" :style="{ cursor: availableAddFormBottom ? 'pointer' : 'default' }" @click="addForm(true)" :color="availableAddFormBottom ? envTheme['--primary'] : '#999999'">mdi-plus-circle</v-icon>
                        </div>
                        <v-expansion-panels v-model="openedPanelBottomForm">
                          <v-expansion-panel v-for="(form, index) in steps[currentStep - 1].dynamicFormGroup.forms[actualDynamicFormGroup].dynamicBottomForm.forms" :key="index">
                              <v-expansion-panel-header class="headerButton">
                                <div class="dynamicForms">
                                  <div>
                                    <template v-if="alerts[currentStep-1]?.length &&  alerts[currentStep-1][actualDynamicFormGroup] && alerts[currentStep-1][actualDynamicFormGroup][`bottomDynamicForms${actualDynamicFormGroup}-${index}`] === true">
                                      <v-icon color="error" class="mr-1">
                                        mdi-alert-circle
                                      </v-icon>
                                    </template> 
                                    <span :key="updateChips" class="titleItemForm" :style="{'color': alerts[currentStep-1]?.length &&  alerts[currentStep-1][actualDynamicFormGroup] && alerts[currentStep-1][actualDynamicFormGroup][`bottomDynamicForms${actualDynamicFormGroup}-${index}`] === true ? 'var(--red)'  : envTheme['--primary']}">{{form}}</span>
                                  </div>
                                  <v-icon @click.native.stop="deleteForm(index, true)" :color="bottomDynamicFieldsForm[actualDynamicFormGroup] && bottomDynamicFieldsForm[actualDynamicFormGroup].length > 1 ? envTheme['--primary'] : '#999999'" :style="{cursor: bottomDynamicFieldsForm[actualDynamicFormGroup] &&  bottomDynamicFieldsForm[actualDynamicFormGroup].length > 1 ? 'pointer' : 'default'}">mdi-delete</v-icon>
                                </div>
                              </v-expansion-panel-header>
                            <v-expansion-panel-content :eager="true">
                              <Form
                                :ref="'bottomDynamicForms' + actualDynamicFormGroup + '-' + index"
                                :key="'bottomDynamicForms' + actualDynamicFormGroup + formKey"
                                :fields="fieldsDynamicBottomForm"
                                @updatedForm="getStatus = false; submitForm = false; updateDynamicForms($event, index, true)"
                                :globalValues="globalValues"
                                :getStatus="getStatus"
                                @formStatus="formSubmitted"
                                :currentDataValues="bottomDynamicFieldsForm[actualDynamicFormGroup] && bottomDynamicFieldsForm[actualDynamicFormGroup].length ? bottomDynamicFieldsForm[actualDynamicFormGroup][index] : null"
                                @formSubmitted="formSubmit($event, 'bottomDynamicForms')"
                                :formContainerToScroll="formContainer"
                                :submitForm="submitForm"
                                :resetForm="resetForm"
                                :returnAlerts="true"
                                @alerts="alerts = $event"
                                @isValidForm="disabled = !$event.valid"
                                primaryColor="var(--primary)"
                                />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                          </div>
                      </template>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="itemToAddEditStepper" ref="actions">
          <slot name="actions">
            <v-btn
              rounded
              outlined
              v-if="itemToAddEditStepper.config.secondaryButton && currentStep === 1"
               @click="cancel('buttonCancel')"
            >
              <v-icon left>{{
                itemToAddEditStepper.config.secondaryButton.icon
              }}</v-icon>
              {{ itemToAddEditStepper.config.secondaryButton.text }}
            </v-btn>
            <v-btn
              rounded
              outlined
              v-else
               @click="selectStep(currentStep-1)"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              {{ traduceItem('back') }}
            </v-btn>

            <v-btn
              @click="currentStep === steps.length ? submit() : nextStep()"
              color="primary"
              rounded
              depressed
              v-if="itemToAddEditStepper.config.primaryButton"
            >
              <template v-if="currentStep === steps.length">
                <v-icon left>{{itemToAddEditStepper.config.primaryButton.icon}}</v-icon>
                {{ itemToAddEditStepper.config.primaryButton.text }}
              </template>
              <template v-else>
                {{ traduceItem("continue") }}
                <v-icon right>mdi-chevron-right</v-icon>
              </template>
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tools } from "@/mixins/tools";
import { mapState, mapActions } from "vuex";
import FormatData from "@/mixins/formatData-class"
import i18n from "@/plugins/i18n";
import editableDataFieldsStepper from "@/mixins/editable-data-fields-stepper";

export default {
  name: "StepperAddEditModal",
  data: () => ({
    getStatus: false,
    dirty: false,
    clickedBtnSubmit: {},
    submitForm: false,
    formContainer: ".stepperAddEditModal .v-navigation-drawer__content",
    resetForm: false,
    currentStep: 1,
    form: {},
    updateFormKey: 1,
    dynamicForms: [[]],
    bottomDynamicFieldsForm: [[]],
    bottomFieldsForm: [],
    alerts: {},
    disabled: false,
    heightRightStepper: 0,
    updateChips: false,
    openedPanel: 0,
    openedPanelBottomForm: 0,
    chips: [],
    showAlert: false,
    alertMsg: null,
    availableForms: {},
    availableAddForm: false,
    availableAddFormBottom: false,
    formKey: 0,
    actualDynamicFormGroup: 0,
    actualElement: undefined,
    maxHeighResumeForms: 0,
    fieldsDynamicBottomForm: undefined,
    copyRefs: {}
  }),
  mixins: [tools],
  computed: {
    ...mapState(["itemToAddEditStepper", "envTheme", "actualView"]),
    valueRadioButton() {
      let fieldRadioGroup = this.steps[this.currentStep - 1].fields.find(field => field.fieldType === 'radioGroup');
      return this.itemToAddEditStepper?.edit ? this.itemToAddEditStepper.currentDataValues[fieldRadioGroup.name] : this.form[fieldRadioGroup.name] || fieldRadioGroup.dataSource[0].value;
    },
    alertEmptyForm() {
      if (this.steps[this.currentStep - 1].dynamicFormGroup && this.steps[this.currentStep - 1].dynamicFormGroup.forms.length) {
        let emptyFormsWhenEdit = this.itemToAddEditStepper?.edit && this.itemToAddEditStepper.currentDataValues.dynamicFormsFormGroup && this.itemToAddEditStepper.currentDataValues.dynamicFormsFormGroup.filter(item => !item.length).length > 1;
        let findElement = this.steps[this.currentStep - 1].dynamicFormGroup.forms.find((item, index) => item.formGroupWhitEmptyForm && ((this.itemToAddEditStepper?.edit && emptyFormsWhenEdit) || !this.itemToAddEditStepper?.edit) && index !== this.actualDynamicFormGroup && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].formGroupWhitEmptyForm);
        return findElement && this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm && this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm.alertErrorNewFormEmpty ? this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm.alertErrorNewFormEmpty : null;
      } else return null;
    },
    showExpansionPanelDynamicForm() {
      if (this.steps && this.steps[this.currentStep - 1] && this.steps[this.currentStep - 1].dynamicForm && this.steps[this.currentStep - 1].dynamicForm.forms.length) return true;
      else if (this.steps && this.steps[this.currentStep - 1] && this.steps[this.currentStep - 1].dynamicFormGroup && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.forms.length) return true;
      else return false;
    },
    labelNewFormEmpty() {
      if (this.steps && this.steps[this.currentStep - 1].dynamicForm && this.steps[this.currentStep - 1].dynamicForm.labelNewFormEmpty) return this.steps[this.currentStep - 1].dynamicForm && this.steps[this.currentStep - 1].dynamicForm.labelNewFormEmpty;
      else if (this.steps && this.steps[this.currentStep - 1].dynamicFormGroup && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.labelNewFormEmpty) return this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.labelNewFormEmpty;
      else return null;
    },
    alertInfoNewFormEmpty() {
      if (this.steps && this.steps[this.currentStep - 1] && this.steps[this.currentStep - 1].dynamicForm && this.steps[this.currentStep - 1].dynamicForm.alertInfoNewFormEmpty) return this.steps[this.currentStep - 1].dynamicForm && this.steps[this.currentStep - 1].dynamicForm.alertInfoNewFormEmpty;
      else if (this.steps && this.steps[this.currentStep - 1] && this.steps[this.currentStep - 1].dynamicFormGroup && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup] && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm && this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.alertInfoNewFormEmpty) return this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.alertInfoNewFormEmpty;
      else return null;
    },
    steps() {
      let stepsToShow;
      if (this.itemToAddEditStepper) {
        stepsToShow = this.itemToAddEditStepper.steps;
        if (this.itemToAddEditStepper && this.itemToAddEditStepper.steps && this.itemToAddEditStepper?.currentDataValues) {
          stepsToShow = this.itemToAddEditStepper.steps.filter(step => !step.dependsOn || (step.dependsOn && step.dependsOn.every(item => this.itemToAddEditStepper.currentDataValues[item.step] === item.value)))
        }
      }
      return stepsToShow;
    },
    globalValues() {
      if (this.itemToAddEditStepper && this.itemToAddEditStepper.globalValues)
        return this.itemToAddEditStepper.globalValues;
      else return null;
    },
    currentDataValues(){
      return this.itemToAddEditStepper?.currentDataValues || null;
    },
    fieldsDynamicForm() {
      return this.updateFormKey ? this.setFields('dynamic') : {};
    },
    fieldsNormalForm() {
      return this.setFields();
    },
    fieldsNormalBottomForm() {
      return this.setFields('bottomForm');
    }
  },
  watch: {
      currentStep() {
        this.fillEmptyStep(this.currentStep-1, this.itemToAddEditStepper?.edit)
         this.$nextTick(() => {
           if (Object.keys(this.alerts).length && this.alerts[this.currentStep - 1] && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup] && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup].length) this.validateForms();
           this.defaultAlert();
           this.onResize()
         });
      },
     itemToAddEditStepper() {
      this.resetForm = this.itemToAddEditStepper ? false : true;
      this.submitForm = false;
      this.getStatus = false;
      this.dirty = false;
      this.clickedBtnSubmit = {};
      this.currentStep = 1;
      this.form = {};
      this.chips = [];
      this.actualDynamicFormGroup = 0;
      this.dynamicForms = [[]];
      this.bottomDynamicFieldsForm = [[]];
      this.bottomFieldsForm = [];
      this.openedPanel = 0;
      this.openedPanelBottomForm = 0;
      this.updateFormKey = 1;
      this.showAlert = false;
      this.availableForms = {};
      this.availableAddForm = false;
      this.availableAddFormBottom = false;
      this.disabled = false;
      if (!this.itemToAddEditStepper) this.alerts = {};
      this.actualElement = this.itemToAddEditStepper && this.itemToAddEditStepper.actualElement ? this.deepCopyFunction(this.itemToAddEditStepper.actualElement) : null;
      this.copyRefs = {}
      if (this.itemToAddEditStepper && this.steps[0].nativeForm) this.form = {...this.form, ...this.itemToAddEditStepper.currentDataValues}
    },
    submitForm() {
      if (this.submitForm) this.validateForms();
      this.defaultAlert();
    }
  },
  methods: {
    ...mapActions(["setItemToAddEditStepper"]),
    selectFormGroup(indexFormGroup) {
      if (this.actualDynamicFormGroup !== indexFormGroup) {
        if (this.validateForms()) {
          this.actualDynamicFormGroup = indexFormGroup; 
          this.setChips();
        }
      }
  },
    formSubmit(event, ref) {
      if (event && this.currentStep === this.steps.length) {
        if (this.steps[this.currentStep - 1].submitForm && ref === this.steps[this.currentStep - 1].submitForm) this.sendForm(event);
        else if (!this.steps[this.currentStep - 1].submitForm) this.sendForm(event);
      }
      else this.nextStep();
    },
    deleteItemGroup(index) {
      if (this.steps[this.currentStep - 1].dynamicFormGroup.forms.length === 1) return;
      else {
        this.steps[this.currentStep - 1].dynamicFormGroup.forms.splice(index, 1);
        this.dynamicForms.splice(index, 1);
        this.bottomFieldsForm.splice(index, 1); 
        this.bottomDynamicFieldsForm.splice(index, 1);
        if (this.alerts && this.alerts[this.currentStep-1] && this.alerts[this.currentStep-1].length && this.alerts[this.currentStep-1][index]) this.alerts[this.currentStep-1].splice(index, 1)
        Object.keys(this.copyRefs).forEach(key => {
          const indexKey  = +(key.split('dynamicForms').join('').split('-')[0]);
          const subIndexKey = +(key.split('-')[1])
          if(indexKey > index) this.copyRefs[`dynamicForms${indexKey-1}-${subIndexKey}`] = this.copyRefs[`dynamicForms${indexKey}-${subIndexKey}`]
          else if(indexKey === 0 && index === 0) this.copyRefs[`dynamicForms0-${subIndexKey}`] = this.copyRefs[`dynamicForms1-${subIndexKey}`]
          if(indexKey >= index) delete this.copyRefs[key]
        })
        Object.keys(this.copyRefs).forEach(key => {
          const indexKey  = +(key.split('bottomFields')[1]);
          if(indexKey > index) this.copyRefs[`bottomFields${indexKey-1}`] = this.copyRefs[`bottomFields${indexKey}`];
          else if(indexKey === 0) this.copyRefs['bottomFields0'] = this.copyRefs['bottomFields1'];
          if(indexKey >= index) delete this.copyRefs[key]
        })
      }

      for (let i = index; i < this.steps[this.currentStep - 1].dynamicFormGroup.forms.length; i++) {
        this.steps[this.currentStep - 1].dynamicFormGroup.forms[i].title = this.steps[this.currentStep - 1].dynamicFormGroup.namesFormGroup + ' ' + (i+1)
      }
      
      if (index === this.actualDynamicFormGroup)  this.actualDynamicFormGroup = 0;
      else if (this.actualDynamicFormGroup !== 0) this.actualDynamicFormGroup -- ;
      else this.actualDynamicFormGroup = 0;
      this.validateForms()
      this.formKey += 1
    },
    addDynamicForm() {
      this.clickedBtnSubmit[this.currentStep - 1] = false;
      if(this.validateForms()) {
        let copyForm = this.deepCopyFunction(this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm);
        if (this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm.newFormEmpty) copyForm.forms = [];
        let formsDynamicBottomForm = this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicBottomForm ? this.deepCopyFunction(this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicBottomForm) : null;
        if (formsDynamicBottomForm && formsDynamicBottomForm.forms && formsDynamicBottomForm.forms.length) formsDynamicBottomForm.forms = [this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicBottomForm.forms[0]]
        this.steps[this.currentStep - 1].dynamicFormGroup.forms.push(
          {
            title: this.steps[this.currentStep - 1].dynamicFormGroup.namesFormGroup + ' ' + (this.steps[this.currentStep - 1].dynamicFormGroup.forms.length+1), 
            dynamicForm: copyForm,
            bottomFields: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].bottomFields,
            dynamicBottomForm: formsDynamicBottomForm,
            formGroupWhitEmptyForm: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm.newFormEmpty ? true : false
          }
        );
        this.dynamicForms[this.steps[this.currentStep - 1].dynamicFormGroup.forms.length - 1] = [];
        this.bottomDynamicFieldsForm[this.steps[this.currentStep - 1].dynamicFormGroup.forms.length - 1] = [];
        this.bottomFieldsForm[this.steps[this.currentStep - 1].dynamicFormGroup.forms.length - 1] = {};
        this.actualDynamicFormGroup = this.steps[this.currentStep - 1].dynamicFormGroup.forms.length - 1;
        this.setChips()
      }
    },
    setFields(type = null) {
      const itemStep = this.steps[this.currentStep-1];
      let fields = !type ? itemStep.fields : type === 'dynamicBottomForm' ? itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.fields : type === 'bottomForm' ? itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].bottomFields : itemStep.dynamicForm ? itemStep.dynamicForm.fields : itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.fields;
      if (type === 'dynamic' && itemStep.dynamicForm && itemStep.dynamicForm.dependsOn) {
        itemStep.dynamicForm.dependsOn.forEach(dependsOnField => {
          if (!this.currentDataValues[dependsOnField.field] || (this.currentDataValues[dependsOnField.field] && this.currentDataValues[dependsOnField.field] !== dependsOnField.value)) fields = [];
        });
      } else if (type === 'dynamicBottomForm' && itemStep.dynamicFormGroup && itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm && itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.dependsOn) {
        itemStep.dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.dependsOn.forEach(dependsOnField => {
          if ((!this.bottomFieldsForm[this.actualDynamicFormGroup] || !this.bottomFieldsForm[this.actualDynamicFormGroup][dependsOnField.field]) || (this.bottomFieldsForm[this.actualDynamicFormGroup] && this.bottomFieldsForm[this.actualDynamicFormGroup][dependsOnField.field] !== dependsOnField.value)) {
            fields = [];
          }
        });
      }
      if(this.itemToAddEditStepper.dataSource && fields && fields.length) {
        fields.forEach((field,index) => {
          if(field.responseDataSource) fields[index].dataSource = this.itemToAddEditStepper.dataSource[field.responseDataSource]

          else if(field.componentDataSource){
            let fieldDataSource = this.itemToAddEditStepper.dataView;
            field.componentDataSource.forEach(({name,containedIn})=> {
              if(containedIn === 'object') fieldDataSource = fieldDataSource[name]
              else if(containedIn === 'objectArray') fieldDataSource = fieldDataSource?.length ?  fieldDataSource.map(el=>el[name]) : []
            })

            fields[index].dataSource = fieldDataSource
          }
          if (field.fieldTypeIfNotDatasource && (!field.dataSource || (field.dataSource && !field.dataSource.length))) field.fieldType = field.fieldTypeIfNotDatasource;
        });
      }
      return fields
    },
    onResize() {
      this.$nextTick(() => {
        if (this.$refs && Object.keys(this.$refs).length && this.$refs.containerModal && this.$refs.actions && this.$refs.title) {
           this.heightRightStepper = this.$refs.containerModal.$el.clientHeight - (this.$refs.actions.clientHeight + this.$refs.title.clientHeight);
           let heightsSteps = 0;
           Object.keys(this.$refs).forEach(ref => {
            if (ref.includes('step') && this.$refs[ref][0]) {
              heightsSteps = heightsSteps + this.$refs[ref][0].clientHeight;
            }
           })
           this.maxHeighResumeForms = this.$refs.leftStepper.clientHeight - (this.$refs.actions.clientHeight + heightsSteps);
        } 
      });
    },
    submit() {
     this.clickedBtnSubmit[this.currentStep - 1] = true;
     this.submitForm = true;
     if(this.currentStep === this.steps.length) {
        const sameValues = this.itemToAddEditStepper?.edit ? this.areObjectsEqual(this.removeEmpty(this.actualElement), this.removeEmpty(this.currentDataValues)) : false;
        if(sameValues){
          this.showAlert = true
          this.alertMsg = i18n.t('alertNoChanges')
          return this.submitForm = !sameValues;
        }
      }
    },
    setAlertColor(index) {
      return Object.keys(this.alerts).length && this.alerts[this.currentStep - 1] && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup] &&  this.alerts[this.currentStep - 1][this.actualDynamicFormGroup].length && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup][index] ?  this.envTheme['--red'] : this.envTheme['--primary'];
    },
    setResumeValues(from, value, translateValue) {
      let valueToShow = this[from][value] ? this[from][value] : this.itemToAddEditStepper.currentDataValues[value];
      return translateValue ? i18n.t(valueToShow) : valueToShow;
    },
    setChips(event, index, fieldsRequires) {
      let configStep = this.steps[this.currentStep - 1].dynamicForm ? this.steps[this.currentStep - 1].dynamicForm : this.steps[this.currentStep - 1].dynamicFormGroup ? this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm : undefined;
      if (configStep && configStep.showChips) {
        let chipsTransform = configStep && configStep.chipsTransform;
        if (fieldsRequires?.length) {
          let chipsValue = [];
          fieldsRequires.forEach(item => {
            if (event && event[item]) {
              let fieldTransform = chipsTransform && chipsTransform.length ? chipsTransform.find(element => element.field === item) ? chipsTransform.find(element => element.field === item) : null : null;
              let itemToPush = fieldTransform ? this.itemToAddEditStepper.dataSource[fieldTransform.transformFromDataSource].find(element => element.value === event[item]).name : event[item];
              chipsValue.push(itemToPush)
            }
          })
          if (chipsValue && chipsValue.length === fieldsRequires.length) this.chips[index] = chipsValue;
          else this.chips[index] = [];
        } else this.chips = [];
        this.updateChips = !this.updateChips;
      }
    },
    defaultAlert() {
        this.showAlert = Object.keys(this.alerts).length && 
          this.alerts[this.currentStep - 1] &&
            this.alerts[this.currentStep - 1][this.actualDynamicFormGroup] && 
              Object.keys(this.alerts[this.currentStep - 1][this.actualDynamicFormGroup])?.length &&
                this.clickedBtnSubmit[this.currentStep - 1] 
                  ? Object.values(this.alerts[this.currentStep - 1][this.actualDynamicFormGroup]).some(alert => alert === true) 
                  : false;
        
        this.alertMsg = this.showAlert && this.steps && this.steps[this.currentStep - 1].dynamicForm 
          ? this.steps[this.currentStep - 1].dynamicForm.alertLabel 
          : this.steps && this.steps[this.currentStep - 1].dynamicFormGroup 
            ? this.steps[this.currentStep - 1].dynamicFormGroup.alertLabel 
            : null;
      },
    formSubmitted(form){
      let sameValues;
      this.dirty = this.currentStep !== 1 || Object.values(this.form).some(el=>
        el !== null && 
          (typeof el === 'object' ? (
            Object.keys(el).length || (Array.isArray(el) && el.length)
            )
            : true
            ))
        ? true 
        : form.dirty;
        if (this.itemToAddEditStepper?.edit)  sameValues = this.areObjectsEqual(this.removeEmpty(this.actualElement), this.removeEmpty(this.currentDataValues));
      this.executeAction({ dirty: sameValues ? false : this.dirty, action: "cancel"});
      this.getStatus = false;
    },
    dynamicQty() {
      let currentDynamic = this.itemToAddEditStepper.currentDataValues[this.itemToAddEditStepper.dynamicFormAtribute];
      if (this.steps[this.currentStep - 1].dynamicForm && currentDynamic && currentDynamic.length) {
        let forms = this.steps[this.currentStep - 1].dynamicForm.forms;
        for (let i = forms.length; i < currentDynamic.length; i++) {
          forms.push(this.steps[this.currentStep - 1].dynamicForm.namesForm + ' ' + (forms.length + 1));
        }
        this.dynamicForms[this.actualDynamicFormGroup] = currentDynamic;
      } else if (this.steps[this.currentStep - 1].dynamicFormGroup && this.itemToAddEditStepper.currentDataValues.formsGroup && this.itemToAddEditStepper.currentDataValues.formsGroup.length) {
         let formsGroups = this.deepCopyFunction(this.steps[this.currentStep - 1].dynamicFormGroup.forms);
         for (let i = 0; i < this.itemToAddEditStepper.currentDataValues.formsGroup.length; i++) {
          if (i !== 0) formsGroups.push(
            {
              title: this.steps[this.currentStep - 1].dynamicFormGroup.namesFormGroup + ' ' + (i+1), 
              dynamicForm: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm,
              bottomFields: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].bottomFields,
              dynamicBottomForm: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicBottomForm,
              formGroupWhitEmptyForm: this.steps[this.currentStep - 1].dynamicFormGroup.forms[0].dynamicForm.newFormEmpty ? true : false
            }
          );
        }
        let copyFormGroups = this.deepCopyFunction(formsGroups)
        copyFormGroups.forEach((form, index) => {
          form.dynamicForm.forms = this.itemToAddEditStepper.currentDataValues.dynamicFormsFormGroup[index].map((element, indexCon) => form.dynamicForm.namesForm + ' ' + (indexCon + 1));
          form.dynamicBottomForm.forms = this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit.length && this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit[index] ? this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit[index].map((element, indexCon) => form.dynamicBottomForm.namesForm + ' ' + (indexCon + 1)) : form.dynamicBottomForm.forms;
        })
        this.steps[this.currentStep - 1].dynamicFormGroup.forms = copyFormGroups;
        this.dynamicForms = this.itemToAddEditStepper.currentDataValues.dynamicFormsFormGroup;
        this.bottomFieldsForm = this.itemToAddEditStepper.currentDataValues.bottomFieldsFormEdit;
        this.bottomDynamicFieldsForm = this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit && this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit.length ? this.itemToAddEditStepper.currentDataValues.bottomDynamicFieldsFormEdit : [[]];
      }
    },
    nextStep() {
      if (this.validateForms()) this.currentStep = this.currentStep + 1;
      if (this.itemToAddEditStepper?.edit && !this.resetVars) this.dynamicQty();
      this.defaultAlert();
    },
    updateForm(event, fields, resetVars = false) {
      this.resetVars = resetVars;
      this.updateCopyRefs();
      this.updateFormKey = this.updateFormKey + 1;
      if (resetVars) {
        this.dynamicForms = [[]];
        this.bottomDynamicFieldsForm = [[]];
        this.bottomFieldsForm = [];
        this.availableAddFormBottom = false;
        this.availableAddForm = false;
        if(this.clickedBtnSubmit[this.currentStep])this.clickedBtnSubmit[this.currentStep] = false
        if (this.steps[this.currentStep] && this.steps[this.currentStep].dynamicFormGroup) {
          let stepsEditable = this.deepCopyFunction(editableDataFieldsStepper.computed[this.itemToAddEditStepper.section]()[this.itemToAddEditStepper.actionEditable]?.steps);
          stepsEditable.forEach((step, index) => {
            if (index >= this.currentStep && step.dynamicFormGroup) this.steps[this.currentStep].dynamicFormGroup.forms = step.dynamicFormGroup.forms;
          })
          this.actualDynamicFormGroup = 0;
          this.chips = [];
        }
        this.itemToAddEditStepper.currentDataValues = {...this.itemToAddEditStepper.currentDataValues, ...event};
        this.form = {...this.form, ...event}
      }
      if (event && Object.keys(event).length && fields && this.itemToAddEditStepper?.edit) {
          fields.forEach(field => {
        if (!(field.name in event)) {
          this.itemToAddEditStepper.currentDataValues[field.name] = null;
          this.form[field.name] = null;
        } else {
          this.itemToAddEditStepper.currentDataValues[field.name] = event[field.name];
          this.form[field.name] = event[field.name];
        }
      })
      } else {
        this.itemToAddEditStepper.currentDataValues = {...this.itemToAddEditStepper.currentDataValues, ...event}
        this.form = {...this.form, ...event}
      }
      this.defaultAlert();
    },
    updateDynamicForms(event, index, isBottomDynamicFieldsForm = false) {
      this.updateCopyRefs();
      let valuesNullEdit = Object.keys(event).find(item => event[item]) ? false : true;
      if (!valuesNullEdit) {
        if (isBottomDynamicFieldsForm && this.bottomDynamicFieldsForm[this.actualDynamicFormGroup]) this.bottomDynamicFieldsForm[this.actualDynamicFormGroup][index] = event;
        else if (isBottomDynamicFieldsForm && !this.bottomDynamicFieldsForm[this.actualDynamicFormGroup]) this.bottomDynamicFieldsForm[this.actualDynamicFormGroup] = [event]
        else this.dynamicForms[this.actualDynamicFormGroup][index] = event;
      }
      if (this.clickedBtnSubmit[this.currentStep - 1]) this.validateForms();
      this.defaultAlert();
      if (!valuesNullEdit || index > 0 ) this.setIfIsAvailableAddForm(index, event, isBottomDynamicFieldsForm);
    },
    updateBottomForm(event) {
      this.updateCopyRefs();
      this.bottomFieldsForm[this.actualDynamicFormGroup] = event;
      this.fieldsDynamicBottomForm = this.setFields('dynamicBottomForm');
       if (this.clickedBtnSubmit[this.currentStep - 1]) this.validateForms();
      this.defaultAlert();
    },
    setIfIsAvailableAddForm(index, event, bottomDynamicForms = false) {
      this.$nextTick(() => {
        let actualForm = bottomDynamicForms ? 'bottomDynamicForms' : 'dynamicForms';
        let formVar = bottomDynamicForms ? 'bottomDynamicFieldsForm' : 'dynamicForms';
        if (this.$refs && Object.keys(this.$refs).length && this.$refs[actualForm + this.actualDynamicFormGroup + '-' + index][0] && this.$refs[actualForm + this.actualDynamicFormGroup + '-' + index][0].form) {
          let formValid = true;
          let fieldsRequires = [];
          let showChipFields = [];
          const actualDynamicFormGroupRef = this.$refs[actualForm + this.actualDynamicFormGroup + '-' + index][0].form
          Object.keys(actualDynamicFormGroupRef).forEach(field => {
            if (actualDynamicFormGroupRef[field].validators.required || actualDynamicFormGroupRef[field].validators.showInChips) {
              if (actualDynamicFormGroupRef[field].validators.dependsOn) {
                actualDynamicFormGroupRef[field].validators.dependsOn.forEach(item => {
                  if (actualDynamicFormGroupRef[field].validators.required && this[formVar][this.actualDynamicFormGroup] && this[formVar][this.actualDynamicFormGroup].length && this[formVar][this.actualDynamicFormGroup][index] && this[formVar][this.actualDynamicFormGroup][index][item.field] && this[formVar][this.actualDynamicFormGroup][index][item.field] === item.value) fieldsRequires.push(field)
                  if (actualDynamicFormGroupRef[field].validators.showInChips && this[formVar][this.actualDynamicFormGroup] && this[formVar][this.actualDynamicFormGroup].length && this[formVar][this.actualDynamicFormGroup][index] && this[formVar][this.actualDynamicFormGroup][index][item.field] && this[formVar][this.actualDynamicFormGroup][index][item.field] === item.value) showChipFields.push(field)
                } )
              } else {
                if(actualDynamicFormGroupRef[field]?.validators?.showInChips) showChipFields.push(field);
                fieldsRequires.push(field)
              }
            }
          })
          if (!bottomDynamicForms) this.setChips(event, index, showChipFields);
          fieldsRequires.forEach(fieldRequire => {
            if (this[formVar][this.actualDynamicFormGroup] && this[formVar][this.actualDynamicFormGroup].length && this[formVar][this.actualDynamicFormGroup][index] && !this[formVar][this.actualDynamicFormGroup][index][fieldRequire]) formValid = false;
          })
          if (!this.availableForms[this.currentStep - 1]) this.availableForms[this.currentStep - 1] = [];
          this.availableForms[this.currentStep - 1][index] = formValid;
        }
        this[bottomDynamicForms ? 'availableAddFormBottom' : 'availableAddForm'] = this.availableForms[this.currentStep - 1] && this.availableForms[this.currentStep - 1].length && this.availableForms[this.currentStep - 1].filter(item => !item).length ? false : true;
      });
    },
    addForm(bottomDynamicForms) {
      if (this[bottomDynamicForms ? 'availableAddFormBottom' : 'availableAddForm'] || !this.showExpansionPanelDynamicForm) {
        this.clickedBtnSubmit[this.currentStep - 1] = false;
        let forms = this.steps[this.currentStep - 1].dynamicForm ? this.steps[this.currentStep - 1].dynamicForm.forms : bottomDynamicForms ? this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.forms  : this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.forms;
        let namesForm = this.steps[this.currentStep - 1].dynamicForm ? this.steps[this.currentStep - 1].dynamicForm.namesForm : bottomDynamicForms ? this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.namesForm : this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.namesForm;
        forms.push(namesForm + ' ' + (forms.length + 1));
        if (bottomDynamicForms) {
          this.bottomDynamicFieldsForm[this.actualDynamicFormGroup].push({});
          this.openedPanelBottomForm = this.bottomDynamicFieldsForm[this.actualDynamicFormGroup].length -1;
        } else {
          if (this.steps[this.currentStep - 1].dynamicFormGroup && 'formGroupWhitEmptyForm' in this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup]) this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].formGroupWhitEmptyForm = false;
          this.dynamicForms[this.actualDynamicFormGroup].push({})
          this.openedPanel = this.dynamicForms[this.actualDynamicFormGroup].length -1;
        }
        this[bottomDynamicForms ? 'availableAddFormBottom' : 'availableAddForm'] = false;
        this.onResize()
      }
    },
    deleteForm(index, bottomDynamicForms) {
      if (!(this[bottomDynamicForms ? 'bottomDynamicFieldsForm' : 'dynamicForms'][this.actualDynamicFormGroup].length > 1)) return;
      this.formKey += 1
      let form = bottomDynamicForms ? this.steps[this.currentStep -1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.forms : this.steps[this.currentStep -1].dynamicFormGroup ? this.steps[this.currentStep -1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.forms : this.steps[this.currentStep -1].dynamicForm.forms;
      
      if(this.copyRefs['dynamicForms' + this.actualDynamicFormGroup + '-' + index]) {
        Object.keys(this.copyRefs).forEach(key => {
          const indexKey = +(key.split('-')[1])
          if(key.includes('dynamicForms' + this.actualDynamicFormGroup + '-')) {
            if(indexKey > index) this.copyRefs[`dynamicForms${this.actualDynamicFormGroup}-${indexKey-1}`] = this.copyRefs[`dynamicForms${this.actualDynamicFormGroup}-${indexKey}`]
            else if(indexKey === 0 && index === 0) this.copyRefs[`dynamicForms${this.actualDynamicFormGroup}-0`] = this.copyRefs[`dynamicForms${this.actualDynamicFormGroup}-1`]
            if(indexKey >= index) delete this.copyRefs['dynamicForms' + this.actualDynamicFormGroup + '-' + indexKey]
          }
        })
      }

      form.splice(index, 1);
      this[bottomDynamicForms ? 'bottomDynamicFieldsForm' : 'dynamicForms'][this.actualDynamicFormGroup].splice(index, 1);
      if (form.length) {
        let namesForm = this.steps[this.currentStep - 1].dynamicForm ? this.steps[this.currentStep - 1].dynamicForm.namesForm : bottomDynamicForms ? this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicBottomForm.namesForm : this.steps[this.currentStep - 1].dynamicFormGroup.forms[this.actualDynamicFormGroup].dynamicForm.namesForm;
        for (let i = index; i < form.length; i++) {
          form[i]= namesForm + ' ' + (i+1)
        }
      }
      if (Object.keys(this.alerts).length && this.alerts[this.currentStep - 1] && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup] && this.alerts[this.currentStep - 1][this.actualDynamicFormGroup].length) this.alerts[this.currentStep - 1][this.actualDynamicFormGroup].splice(index, 1);
      if (Object.keys(this.availableForms).length && this.availableForms[this.currentStep - 1] && this.availableForms[this.currentStep - 1].length) this.availableForms[this.currentStep - 1].splice(index, 1);
       this[bottomDynamicForms ? 'availableAddFormBottom' : 'availableAddForm'] = this.availableForms[this.currentStep - 1] && this.availableForms[this.currentStep - 1].length && this.availableForms[this.currentStep - 1].filter(item => !item).length ? false : true;
       this.setChips()
       this.onResize()
    },
    setData(form = this.form, dynamicForms, classView = this.itemToAddEditStepper.class) {
      let isDynamicFormGroup = 'dynamicFormGroup' in this.steps[this.currentStep -1];
      let dataDynamicForms = !dynamicForms ? isDynamicFormGroup ? this.dynamicForms : this.dynamicForms[this.actualDynamicFormGroup] : dynamicForms;
      const myClass = new FormatData(form, dataDynamicForms, classView, isDynamicFormGroup, this.bottomFieldsForm, this.bottomDynamicFieldsForm, this.itemToAddEditStepper.globalValues ? this.deepCopyFunction(this.itemToAddEditStepper.globalValues) : null, this.itemToAddEditStepper.indexesToUpdate);
      const data = myClass.setData();
      return data;
    },
    removeEmpty(obj) {
      return Object.keys(obj)
        .filter( (k) => {
          return obj[k] != null;
        })
        .reduce((acc, k) => {
          acc[k] = typeof obj[k] === "object" ? this.removeEmpty(obj[k]) : obj[k];
          return acc;
        }, {});
    },
    areObjectsEqual(obj1, obj2) {
      if (obj1 === obj2) {
        return true;
      }

      if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }

        const val1 = obj1[key];
        const val2 = obj2[key];

        if (!this.areObjectsEqual(val1, val2)) {
          return false;
        }
      }

      return true;
    },
    validateForms() {
      
      if (this.steps[this.currentStep - 1].dynamicForm || (this.steps[this.currentStep - 1]?.dynamicFormGroup && (this.steps[this.currentStep - 1]?.dynamicFormGroup.forms[0].dynamicForm || this.steps[this.currentStep - 1]?.dynamicFormGroup.forms[0].dynamicBottomForm || this.steps[this.currentStep - 1]?.dynamicFormGroup.forms[0].bottomFields))) {
        let formRefs = {}
        Object.keys(this.copyRefs).forEach(key=>{
          if(
            key.includes('dynamicForms' + this.actualDynamicFormGroup + '-') ||
            key.includes('bottomDynamicForms' + this.actualDynamicFormGroup + '-') ||
            key.includes('normalForm') ||
            key.includes('bottomFields' + this.actualDynamicFormGroup)
          ) formRefs[key] = this.copyRefs[key]
        })
        if(!this.alerts[this.currentStep - 1]) this.alerts[this.currentStep - 1] = [];
        this.alerts[this.currentStep - 1][this.actualDynamicFormGroup] = {};
        Object.keys(formRefs).forEach((key) => {
            if (formRefs[key] && formRefs[key].length) {
              let inputs = formRefs[key][0].$children[0].inputs;
                this.alerts[this.currentStep - 1][this.actualDynamicFormGroup][key] = false
              inputs.forEach(field => {
                field.hasFocused = true;
                if (!field.validate()) {
                  this.alerts[this.currentStep - 1][this.actualDynamicFormGroup][key] = true;
                }
              })
            }
          })
          
          if(this.alertEmptyForm) this.alerts[this.currentStep-1][this.alerts[this.currentStep-1].length-1] = {alertEmptyForm: true}
        return (!this.alerts[this.currentStep-1].some((el,i)=>this.setDynamicFormAlert(i)) && !this.alertEmptyForm);
      } else {
        let inputs = this.copyRefs.normalForm?.$children[0]?.inputs || [];
        let validate = true;
        inputs.forEach(field => {
          field.hasFocused = true;
          if (!field.validate()) validate = false;
        });
         return validate;
      }
    },
    cancel(event) {
      if (!((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") || !this.itemToAddEditStepper) return;
      if (this.steps[this.currentStep - 1].nativeForm) this.formSubmitted({dirty: this.itemToAddEditStepper?.edit ? this.areObjectsEqual(this.removeEmpty(this.actualElement), this.removeEmpty(this.currentDataValues)) : true})
      else this.getStatus = true;
    },
    selectStep(targetStep){
      if (targetStep === this.currentStep) return;
      const prevStep = this.deepCopyFunction(this.currentStep)
      if (targetStep < this.currentStep)this.currentStep = targetStep;
      else if (this.validateForms()) this.currentStep = targetStep;

      if(this.alerts[prevStep-1]) delete this.alerts[prevStep-1]
      if(this.alerts[targetStep-1])this.alerts[targetStep-1] = []

      if (this.itemToAddEditStepper?.edit && !this.resetVars) this.dynamicQty();
      else if (this.resetVars) this.resetVars = false;
    },
    sendForm($event){
      let sameValues;
      let validate = this.validateForms();
      this.defaultAlert();
      let values = this.setData();

      if (validate) {
        sameValues = this.itemToAddEditStepper?.edit ? this.areObjectsEqual(this.removeEmpty(this.actualElement), this.removeEmpty(this.currentDataValues)) : false;
        sameValues ? this.setItemToAddEditStepper(undefined) : this.executeAction({ ...$event, action: this.itemToAddEditStepper.action ? this.itemToAddEditStepper.action : $event.action,  section: this.itemToAddEditStepper.section, data: this.itemToAddEditStepper.data ? this.itemToAddEditStepper.data : null, values: values});
      }
    },
    fillEmptyStep(stepToFill, isEdit){
      if(this.steps?.length && this.steps[stepToFill]?.nativeForm) {
          this.steps[stepToFill].fields.forEach(field => {
            if(!this.form[field.name] && field?.dataSource?.length) this.updateForm({[field.name]: isEdit ? this.itemToAddEditStepper.currentDataValues[field.name] : field.dataSource[0].value},  isEdit ? this.steps[this.currentStep - 1].fields : null)
          })
        }
    },
    setDynamicFormAlert(indexFormGroup) {
      let returnAlert = false;
      if(this.alerts[this.currentStep-1] && this.alerts[this.currentStep-1][indexFormGroup]){
        if(Object.values(this.alerts[this.currentStep - 1][indexFormGroup]).some(alert => alert === true)) returnAlert = true
      }
      return returnAlert; 
    },
    updateCopyRefs() {
      if(this.$refs) Object.keys(this.$refs).forEach(key => {
        if(
          this.$refs[key]?.length && 
            (
              key.includes('dynamicForms' + this.actualDynamicFormGroup + '-') ||
              key.includes('bottomFields' + this.actualDynamicFormGroup) || 
              key.includes('bottomDynamicForms' + this.actualDynamicFormGroup + '-')
            )
          ) this.copyRefs[key] = [...this.$refs[key]]
        if(this.$refs[key] && Object.keys(this.$refs[key])?.length && key.includes('normalForm')) this.copyRefs[key] = {...this.$refs[key]}
      })
      Object.keys(this.copyRefs).forEach(key => {
        if(!this.$refs[key]) delete this.copyRefs[key]
      })
      if(this.alerts[this.currentStep-1]?.length && this.alerts[this.currentStep-1].some((el,i)=>this.setDynamicFormAlert(i))) this.validateForms()
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }
  .text-content {
    height: calc(95vh - 135px);
  }
  .v-card > .v-card__title.stepper-modal-title {
    font-weight: 400;
  }
  .v-card__actions {
    padding: 20px 16px;
  }
}
.left-stepper {
  width: 45%;
  height: calc(95vh - 135px);
  .v-stepper__step {
      padding-left: 20px;
      align-items: baseline;
    }
  .v-stepper__step--complete {
    .resumeStep {
      font-size: 14px;
      margin-bottom: -31px;
      color: var(--fontColor);
      margin-left: -26px !important;
      margin-top: 10px;
      border-left: 1px solid var(--borderGray) !important;
      min-height: 30px;
      padding: 25px 50px 15px 23px;
      span {
        color: var(--fontColorTerciary);
      }
      .itemResumeStep:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .step-item:not(:last-child) {
    ::v-deep {
      .v-stepper__content {
          margin-left: 10px !important;
          border-left: 1px solid var(--borderGray) !important;
          min-height: 40px;
        }

    }
  }
}
.right-stepper {
  ::v-deep {
    .v-expansion-panel {
      margin-bottom: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid var(--borderGray);;
      border-radius: 0px !important;
      &:not(:first-child):after {
        border: none !important;
      }
    }
    ::-webkit-scrollbar {
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f2f5;
      transition: background-color .3s ease-out;
    }
    
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f1f2f5;
      transition: background-color .3s ease-out;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 120px;
      background-color: #a2a2a2;
      transition: background-color .3s ease-out;
    }
    .labelNewFormEmpty {
      color: var(--fontColorTerciary);
    }
  }
  .alert-container {
    margin-top: 17px;
    margin-left: 16px;
  }
  .alert-margin {
    margin-bottom: 17px;
  }
  width: 51%;
  .titleItemForm {
    color: var(--primary);
    font-weight: 500;
  }
  ::v-deep {
    .v-expansion-panel-header__icon .mdi {
    color: var(--primary) !important;
    }
  }
  .bottom-fields.formContainer, .v-expansion-panel-content .formContainer {
    ::v-deep {
      form {
       margin-left: 0px;
      }
     }
  }
  .formContainer {
    min-height: 80px;
    ::v-deep {
      .v-input {
        min-height: 77px;
      }
      form {
       margin-left: 16px;
      }
    }
  }
}
.v-stepper--vertical .v-stepper__step { 
  padding: 5px 16px 5px 0px;
}
.right-stepper {
  .v-stepper--vertical .v-stepper__step { 
    padding: 5px 16px 5px 16px;
  }
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content{
  margin: 0;
  padding: 0;
}
.addDynamicForms {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .titleDynamicForm {
    color: var(--secondary);
  }
}
.contenedor {
  width: 100%;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .headerButton {
  padding: 0;
}
.dynamicForms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addDynamicFormsGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.itemGroup {
  padding: 20px 0 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.formGroup {
  margin-top: 20px;
}
.selectedItemGroup {
  background: rgba(0, 0, 0, 0.05);
  color: var(--primary);
  transition: all .3s;
}
::v-deep {
  .bottom-fields .v-form .row .py-0.col-12:first-child {
    padding-bottom: 10px !important;
  }
  .v-stepper__step {
    &.v-stepper__step--active .v-stepper__label {
      font-weight: 400;
    }
    
    &.v-stepper__step--active .v-stepper__label, &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }
  
    &.v-stepper__step--inactive .v-stepper__label {
      color: var(--fontColorTerciary);
    }
  }

}


</style>
