<template>
  <div>
    <v-dialog :value="itemToImport" :width="900" v-click-outside="cancel" content-class="dialog containerStepper">
      <v-card>
        <v-card-title v-if="itemToImport && itemToImport.config && itemToImport.config.title" ref="title">
          {{ itemToImport.config.title }}
        </v-card-title>
        <v-card-text ref="content" class="text-content">
          <div class="d-flex contenedor" v-if="itemToImport && steps && steps.length">
            <div class="mr-1 left-stepper" min-height="380" ref="leftStepper">
              <v-stepper v-model="currentStep" vertical flat>
                <div v-for="(step, index) in steps" :key="index" class="step-item" :ref="'step' + index">
                  <v-stepper-step
                    :complete="currentStep > index + 1"
                    :step="index + 1"
                    @click="selectStep(index + 1)"
                    :style="{ cursor: 'pointer' }"
                  >
                    <div>
                      {{ step.title}}
                    </div>
                    <div v-if="currentStep > index + 1" class="resumeStep">
                      <template v-if="step.resume">
                        <div
                          v-for="(resume, indexResume) in step.resume.fields"
                          :key="indexResume"
                          class="itemResumeStep"
                        >
                          <span>{{ resume.label }} </span>
                          {{
                            setResumeValues(
                              step.resume.from,
                              resume.value,
                              resume.translateValue ? resume.translateValue : null,
                              resume.lowerCase,
                            )
                          }}
                        </div>
                      </template>
                      <template v-else-if="step.chipResume">
                        <span class="mr-1">{{ step.chipResume.label }} </span>
                        <span
                          v-if="setChipResume(step.chipResume) === step.chipResume.fallBack"
                          style="color: var(--fontColor)"
                          >{{ setChipResume(step.chipResume) }}</span
                        >
                        <v-chip v-else style="color: var(--fontColor)">{{ setChipResume(step.chipResume) }}</v-chip>
                      </template>
                    </div>
                  </v-stepper-step>
                  <v-stepper-content :complete="currentStep > index + 1" :step="index + 1"> </v-stepper-content>
                </div>
              </v-stepper>
            </div>
            <v-divider vertical></v-divider>
            <div class="ml-1 right-stepper">
              <v-stepper :v-model="currentStep" vertical flat :style="{ overflow: 'auto', 'padding-right': '5px' }">
                <v-stepper-step :complete="false" :step="currentStep" class="ml-2" >
                  {{ steps[currentStep - 1].title }}
                </v-stepper-step>
                <v-stepper-content :step="currentStep">
                  <template v-if="currentStep !== 4 && steps[currentStep - 1]">
                    <Form
                      :key="formKey"
                      :ref="'normalForm-' + currentStep"
                      :fields="steps[currentStep - 1].fields"
                      @updatedForm="
                        getStatus = false;
                        submitForm = false;
                        updateForm($event, steps[currentStep - 1].fields);
                      "
                      :getStatus="getStatus"
                      :currentDataValues="currentDataValues"
                      primaryColor="var(--primary)"
                      :submitForm="submitForm"
                      :resetForm="resetForm"
                      :returnAlerts="true"
                      @alerts="alerts = $event"
                      @isValidForm="disabled = !$event.valid"
                      class="py-2 px-4"
                    />
                    <span
                      v-if="
                        steps[currentStep - 1]?.chipResume &&
                        currentDataValues[steps[currentStep - 1]?.chipResume.dependsOn.key] ===
                          steps[currentStep - 1]?.chipResume.dependsOn.value
                      "
                      class="ml-4"
                      >{{ traduceItem("filter") }}
                      <v-chip>{{
                        setChipResume({ ...steps[currentStep - 1].chipResume, fallBack: "--" })
                      }}</v-chip></span
                    >
                  </template>
                  <div v-else ref="importWrp">
                    <ImportSourceSelector
                      class="pt-2 pb-4"
                      :wrpClasses="wrpClasses"
                      color="var(--primary)"
                      :selectedSource="selectedImportSource"
                      :googleDeveloperKey="googleDeveloperKey"
                      :googleClientId="googleClientId"
                      :googleAppId="googleAppId"
                      @sourceSelected="changedSelectedImportSource"
                      @fileSelectedDrive="fileSelectedDrive"
                    />
                    <v-alert
                      v-if="errorFileImport || showAlert"
                      outlined
                      :type="'error'"
                      :icon="'mdi-alert-circle'"
                      color="red"
                      class="ml-5"
                    >
                      {{ errorFileImport || showAlert }}
                    </v-alert>
                    <div class="import-wrp">
                      <Import
                        v-if="selectedImportSource === 'yourPc'"
                        class="importFileArea"
                        :title="traduceItem('importFileFromYourPc')"
                        :subtitle="traduceItem('clickOrDragToImport')"
                        :icon="'mdi-laptop'"
                        @file="fileSelected"
                      />
                      <FileToUpload
                        v-if="selectedFile"
                        :file="selectedFile"
                        :wrpClasses="wrpClasses"
                        @clearFile="clearFile"
                        class="clearFileContainer"
                      />
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="itemToImport" ref="actions">
          <slot name="actions">
            <v-btn
              rounded
              outlined
              v-if="itemToImport.config.secondaryButton && currentStep === 1"
              @click="cancel('buttonCancel')"
            >
              <v-icon left>{{ itemToImport.config.secondaryButton.icon }}</v-icon>
              {{ itemToImport.config.secondaryButton.text }}
            </v-btn>
            <v-btn rounded outlined v-else @click="selectStep(currentStep - 1)">
              <v-icon left>mdi-chevron-left</v-icon>
              {{ traduceItem("back") }}
            </v-btn>
            <v-btn
              @click="currentStep === steps.length ? submit() : selectStep(currentStep + 1)"
              color="primary"
              rounded
              depressed
              v-if="itemToImport.config.primaryButton"
            >
              <template v-if="currentStep === steps.length">
                <v-icon left>{{ itemToImport.config.primaryButton.icon }}</v-icon>
                {{ itemToImport.config.primaryButton.text }}
              </template>
              <template v-else>
                {{ traduceItem("continue") }}
                <v-icon right>mdi-chevron-right</v-icon>
              </template>
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import { tools } from "@/mixins/tools";

export default {
  name: "ImportModal",
  components: { ImportSourceSelector, FileToUpload },
  mixins: [tools],
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    dirty: false,
    getStatus: false,
    resetForm: false,
    submitForm: false,
    emptyObject: false,
    selectedImportSource: null,
    selectedFile: null,
    errorFileImport: null,
    showErrorSelectAtLeastOneFilePackage: false,
    showAlert: undefined,
    currentStep: 1,
    alerts: {},
    formKey: 1,
    form: {},
    copyRefs: {},
    steps: [
      {
        title: i18n.t("selectImportFile"),
        resume: {
          from: "currentDataValues",
          fields: [
            {
              label: i18n.t("fileType"),
              value: "fileType",
              lowerCase: true,
            },
          ],
        },
        fields: [
          {
            name: "fileType",
            hideTitle: true,
            resetVars: true,
            fieldType: "radioGroup",
            dataSource: [
              { value: "CSV", name: "csv" },
              { value: "XLSX", name: "xlsx" },
            ],
            optionsToCheck: [
              { field: "CSV", value: "CSV" },
              { field: "XLSX", value: "XLSX" },
            ],
            validators: {
              required: { msg: i18n.t("required") },
            },
          },
          {
            name: "sheetName",
            label: i18n.t("sheetName"),
            fieldType: "textBox",
            validators: {
              dependsOn: [
                {
                  field: "fileType",
                  type: "radioGroup",
                  value: "XLSX",
                },
              ],
            },
          },
        ],
      },
      {
        title: i18n.t("columnsToRead"),
        resume: {
          from: "currentDataValues",
          fields: [
            {
              label: i18n.t("inputAlias"),
              value: "alias",
            },
            {
              label: i18n.t("positionColumn"),
              value: "position",
            },
            {
              label: i18n.t("mandatoryColumn"),
              value: "nullable",
            },
            {
              label: i18n.t("dataTypeColumn"),
              value: "dataType",
            },
          ],
        },
        fields: [
          {
            name: "alias",
            label: i18n.t("inputAlias"),
            fieldType: "textBox",
            validators: {
              required: { msg: i18n.t("required") },
            },
          },
          {
            name: "position",
            label: i18n.t("positionColumn"),
            fieldType: "textBox",
            validators: {
              required: { msg: i18n.t("required") },
            },
          },
          {
            name: "nullable",
            label: i18n.t("mandatoryColumn"),
            fieldType: "textBox",
          },
          {
            name: "dataType",
            label: i18n.t("dataTypeColumn"),
            fieldType: "textBox",
            validators: {
              required: { msg: i18n.t("required") },
            },
          },
        ],
      },
      {
        title: i18n.t("rowsToRead"),
        chipResume: {
          label: i18n.t("filter"),
          from: "currentDataValues",
          chipFields: ["filterColumnName", "filterValue"],
          fallBack: i18n.t("withoutFilters"),
          dependsOn: {
            key: "filter",
            value: true,
          },
        },
        fields: [
          {
            name: "filter",
            preventUpdate: true,
            resetVars: true,
            hideTitle: true,
            fieldType: "radioGroup",
            dataSource: [
              { value: "all", name: i18n.t("all") },
              { value: true, name: i18n.t("filterByColumn") },
            ],
            optionsToCheck: [
              { field: "all", value: "all" },
              { field: true, value: true },
            ],
            validators: {
              required: { msg: i18n.t("required") },
            },
          },
          {
            name: "firstTermSection",
            label: i18n.t("filterColumnNameSection"),
            fieldType: "sectionTitle",
            validators: {
              dependsOn: [
                {
                  field: "filter",
                  type: "radioGroup",
                  value: true,
                },
              ],
            },
          },
          {
            name: "filterColumnName",
            label: i18n.t("filterColumnName"),
            fieldType: "textBox",
            validators: {
              dependsOn: [
                {
                  field: "filter",
                  type: "radioGroup",
                  value: true,
                },
              ],
              required: { msg: i18n.t("required") },
            },
          },
          {
            name: "filterValueSection",
            label: i18n.t("filterColumnValue"),
            fieldType: "sectionTitle",
            validators: {
              dependsOn: [
                {
                  field: "filter",
                  type: "radioGroup",
                  value: true,
                },
              ],
            },
          },
          {
            name: "filterValue",
            label: i18n.t("value"),
            fieldType: "textBox",
            validators: {
              dependsOn: [
                {
                  field: "filter",
                  type: "radioGroup",
                  value: true,
                },
              ],
              required: { msg: i18n.t("required") },
            },
          },
        ],
      },
      {
        title: i18n.t("selectFileToImport"),
      },
    ],
  }),
  computed: {
    ...mapState(["itemToImport", "envTheme", "userLogged", "loadingData"]),
    currentDataValues() {
      return this.itemToImport?.currentDataValues || null;
    },
    wrpClasses() {
      const importWrpWidth =
        this.isIntersecting && this.modal && this.$refs && this.$refs.importWrp && this.$refs.importWrp.offsetWidth;
      const vuetifyBreakpoint = this.$vuetify.breakpoint.width;
      const parentWidth = importWrpWidth || vuetifyBreakpoint;

      return {
        small: parentWidth < 499,
        medium: parentWidth >= 500 && parentWidth < 960,
        large: parentWidth >= 960 && parentWidth < 1253,
        xLarge: parentWidth >= 1253,
      };
    },
  },
  methods: {
    ...mapActions(["setItemToImport", "setShowSnackBar", "setLoadingData"]),
    selectStep(targetStep) {
      let valid = true;
      for (let i = this.currentStep; i < targetStep; i++) {
        if (!this.copyRefs[`normalForm-${i}`] || !this.validateForm(i)) valid = false;
        if (!valid) break;
      }
      if (this.selectedFile) this.changedSelectedImportSource();
      if (targetStep < this.currentStep || valid) {
        this.updateFormKey();
        this.updateCopyRefs();
        this.currentStep = targetStep;
      } else return;
    },
    cancel(event) {
      if (
        !((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") ||
        !this.itemToImport
      )
        return;
      this.executeAction({
        action: "cancel",
        section: "ImportColumns",
        dirty: this.dirty,
      });
    },
    updateForm(event, fields) {
      if (Object.keys(event).length) {
        const emptyValues =
          Object.values(event).every((el) => el === null) && fields.some(({ fieldType }) => fieldType === "radioGroup");
        if (emptyValues && fields[0]?.fieldType === "radioGroup") {
          const firstEventKey = Object.keys(event)[0];
          const firstEventValue =
            this.steps[this.currentStep - 1].fields[
              this.steps[this.currentStep - 1].fields.findIndex(({ name }) => name === firstEventKey)
            ].dataSource[0].value;
          event[firstEventKey] = firstEventValue;
        }
        this.updateCopyRefs();
        if (this.copyRefs[`normalForm-${this.currentStep}`].dirty || emptyValues) {
          this.dirty = true;
          this.itemToImport.currentDataValues = { ...this.itemToImport.currentDataValues, ...event };
        } else {
          const targetRef = this.copyRefs[`normalForm-${this.currentStep}`];
          if (fields.some(({ fieldType }) => fieldType === "radioGroup"))
            fields.forEach(({ fieldType, name }) => {
              if (fieldType === "radioGroup") targetRef.form[name].optionChecked = this.currentDataValues[name];
              if (fieldType === "textBox") targetRef.currentValues[name] = this.currentDataValues[name];
            });
        }
      }
    },
    setResumeValues(from, value, translateValue, lowerCase) {
      let valueToReturn = translateValue ? i18n.t(this[from][value]) : this[from][value];
      if (lowerCase) valueToReturn = valueToReturn.toLowerCase();
      return valueToReturn;
    },
    setChipResume({ from, chipFields, fallBack }) {
      if (this[from][chipFields[0]] && this[from][chipFields[1]])
        return this[from][chipFields[0]] + " = " + this[from][chipFields[1]];
      return fallBack;
    },
    updateFormKey() {
      this.formKey += 1;
    },
    updateCopyRefs() {
      Object.keys(this.$refs).forEach((key) => {
        if (Array.isArray(this.$refs[key]) && this.$refs[key]?.length) this.copyRefs[key] = [...this.$refs[key]];
        if (this.$refs[key] && !Array.isArray(this.$refs[key]) && Object.keys(this.$refs[key]).length)
          this.copyRefs[key] = { ...this.$refs[key] };
      });
    },
    changedSelectedImportSource(selectedSource) {
      this.selectedImportSource = selectedSource;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.showAlert = null;
      this.showErrorSelectAtLeastOneFilePackage = false;
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const blob = await fetch(`https://www.googleapis.com/drive/v3/files/${fileData.id}?alt=media`, fetchOptions).then(
        (res) => {
          this.loading = false;
          return res.blob();
        },
      );
      const fileOptions = {
        type: fileData.mimeType,
        lastModified: fileData.lastEditedUtc,
        size: fileData.size,
      };

      this.selectedFile = new File([blob], fileData.name, fileOptions);
    },
    fileSelected(event) {
      if (event) {
        this.errorFileImport = null;
        this.selectedFile = event;
      }
    },
    clearFile() {
      if (this.selectedImportSource === "drive") this.selectedImportSource = null;
      this.selectedFile = null;
      const inputFile = document.querySelector(".import-wrp input[type='file']");
      if (inputFile) inputFile.value = "";
    },
    validateForm(index = this.currentStep) {
      let inputs = this.copyRefs[`normalForm-${index}`]?.$children[0]?.inputs;
      this.alerts[index - 1] = [];
      inputs.forEach((ipt, i) => {
        ipt.hasFocused = true;
        if (!ipt.validate()) {
          this.alerts[index - 1][i] = true;
        }
      });
      return !this.alerts[index - 1].some((el) => el === true);
    },
    submit() {
      if (!this.selectedImportSource) this.showAlert = i18n.t("youMustSelectAnImportOption");
      else if (!this.selectedFile) this.showAlert = i18n.t("youMustSelectAtLeastOneFileToImport");

      if (!Object.keys(this.alerts).some((el) => this.alerts[el].some((el) => el === true)) && this.selectedFile) {
        let copyValues = global.structuredClone(this.currentDataValues);
        delete copyValues.filter;
        delete copyValues.filterValueSection;
        delete copyValues.firstTermSection;
        Object.keys(copyValues).forEach((key) => {
          if (copyValues[key] === null) copyValues[key] = "";
        });
        copyValues.descriptorId = this.$router.history.current.params.id;
        let params = new FormData();
        params.append("inputColumns", JSON.stringify(copyValues));
        params.append("lang", this.userLogged.lang);
        params.append("file", this.selectedFile);
        let selectedFile = global.structuredClone(this.selectedFile);
        this.setShowSnackBar({
          color: "info",
          icon: "mdi-information",
          msg: i18n.t("uploadedImport", { element: selectedFile.name }),
        });
        this.setLoadingData(true);
        this.httpRequest("post", [window.PROJECT_ENV.BASE_URL_SENDER + "import/columns", params], false)
          .then(async (res) => {
            if (res) {
              await this.setShowSnackBar(false)
              const error = res.level === "FAIL";
              this.setShowSnackBar({
                color: error ? "error" : "success",
                icon: error ? 'mdi-alert-circle' : "mdi-check-circle",
                msg: error ? res.msg : i18n.t("successfulImport", { element: selectedFile.name }),
              });
              if(error) this.setLoadingData(false)
            } else {
              this.setLoadingData(false);
              this.loading = false;
            }
          })
          .catch((error) => {
            this.setLoadingData(false);
            this.handleHttpStatus(error);
            this.loading = false;
          })
        this.setItemToImport();
      }
    },
  },
  watch: {
    selectedFile() {
      this.showAlert = null;
      if (
        this.selectedFile &&
        this.selectedFile.name.split(".").pop().toUpperCase() !== this.currentDataValues.fileType
      ) {
        this.errorFileImport = i18n.t("extensionErrorFile", { element: this.currentDataValues.fileType.toLowerCase() });
        this.selectedFile = null;
      } else if (this.selectedFile) this.errorFileImport = null;
    },
    itemToImport() {
      this.resetForm = this.itemToImport ? false : true;
      this.dirty = false;
      this.getStatus = false;
      this.resetForm = false;
      this.submitForm = false;
      this.emptyObject = false;
      this.selectedImportSource = null;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.showErrorSelectAtLeastOneFilePackage = false;
      this.currentStep = 1;
      this.alerts = {};
      this.formKey = 1;
      this.form = {};
      this.copyRefs = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }
  .text-content {
    height: calc(95vh - 135px);
  }
  .v-card__actions {
    padding: 20px 16px;
  }
}
.left-stepper {
  width: 45%;
  height: calc(95vh - 135px);
  .v-stepper__step {
    padding-left: 20px;
    align-items: baseline;
  }
  .v-stepper__step--complete {
    .resumeStep {
      font-size: 14px;
      margin-bottom: -31px;
      color: var(--fontColor);
      margin-left: -26px !important;
      margin-top: 10px;
      border-left: 1px solid var(--borderGray) !important;
      min-height: 30px;
      padding: 25px 50px 15px 23px;
      span {
        color: var(--fontColorTerciary);
      }
      .itemResumeStep:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .step-item:not(:last-child) {
    ::v-deep {
      .v-stepper__content {
        margin-left: 10px !important;
        border-left: 1px solid var(--borderGray) !important;
        min-height: 40px;
      }
    }
  }
}
.right-stepper {
  ::v-deep {
    .v-expansion-panel {
      border-bottom: 1px solid var(--borderGray);
      border-radius: 0px !important;
      &:not(:first-child):after {
        border: none !important;
      }
    }
    ::-webkit-scrollbar {
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 120px;
      background-color: #a2a2a2;
      transition: background-color 0.3s ease-out;
    }
    .labelNewFormEmpty {
      color: var(--fontColorTerciary);
    }
  }
  .alert-container {
    margin-top: 17px;
  }
  width: 51%;
  .titleItemForm {
    color: var(--primary);
    font-weight: 400;
  }
  ::v-deep {
    .v-expansion-panel-header__icon .mdi {
      color: var(--primary) !important;
    }
  }
  .formContainer {
    min-height: 80px;
    ::v-deep {
      .v-input {
        min-height: 77px;
      }
    }
  }
}
.v-stepper--vertical .v-stepper__step {
  padding: 5px 16px 5px 0px;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}
.addDynamicForms {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .titleDynamicForm {
    color: var(--secondary);
  }
}
.contenedor {
  width: 100%;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap,
.headerButton {
  padding: 0;
}
.dynamicForms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addDynamicFormsGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.itemGroup {
  padding: 20px 0 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.formGroup {
  margin-top: 20px;
}
.selectedItemGroup {
  background: rgba(0, 0, 0, 0.05);
  color: var(--primary);
  transition: all 0.3s;
}
::v-deep {
  .importFileArea + .component-container {
    margin-top: 20px;
  }
  .v-treeview-node__label {
    font-size: 14px;
  }
  .v-treeview-node__checkbox.v-icon {
    color: #ccc;
  }

  .selectFileStep {
    .v-stepper__wrapper {
      margin: -20px 0 0 -20px;
    }
  }

  .v-stepper__step {
    &.v-stepper__step--active .v-stepper__label {
      font-weight: 400;
    }
    
    &.v-stepper__step--active .v-stepper__label, &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }
  
    &.v-stepper__step--inactive .v-stepper__label {
      color: var(--fontColorTerciary);
    }
  }


  .import-wrp {
    margin: 20px 0 0 20px;
    .dragFile {
      font-weight: 400;
    }
    .clickImport {
      color: var(--fontColorTerciary);
      font-weight: 400;
    }
    .mdi-laptop {
      color: var(--primary);
      margin: 27px 0 6px 0;
    }
  }
}

.formContainer {
  form {
    margin-left: 16px;
  }
}

.right-stepper {
  .v-stepper--vertical .v-stepper__step { 
    padding: 5px 16px 5px 16px;
    padding-left: 8px;
  }
}
</style>
