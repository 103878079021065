<template>
  <v-navigation-drawer
    :value="isExecuteProcessModalOpen"
    absolute
    temporary
    right
    stateless
    :width="$vuetify.breakpoint.xs ? '90%' : '500'"
    v-click-outside="cancel"
    class="executeModal"
  >
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
    <div
      v-else
      v-intersect="{
        handler: onIntersect,
        options: { threshold: [1.0, 1.0, 1.0, 1.0] },
      }"
      ref="importWrp"
      class="page-container"
      :style="{ ...cssProps }"
      :class="wrpClasses"
    >
      <div class="header">
        <h3>{{ traduceItem("runProcess") }}</h3>      
      </div>

      <div class="content-container">
         <div class=" d-flex justify-space-between align-center px-5 pb-5">      
          <v-alert v-if="validationFileData" class="mt-4 mb-0" outlined type="error" icon="mdi-alert-circle">
            <span> {{ traduceItem("errorExecuteProcess") }} </span>
            <span 
              :style="{cursor: 'pointer', 'text-decoration': 'underline' }"
              @click="downloadErrorsFile(validationFileData.file)"
            >{{ validationFileData.msg }}</span>
            <div class="d-inline-block" :style="{ 'vertical-align': 'middle' }" v-if="loading" >
              <v-progress-circular indeterminate color="primary" class="ml-3"></v-progress-circular>
            </div>
          </v-alert>
        </div>

        <div
          class="d-flex flex-grow-1 justify-center align-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        
        <template v-else>
          <v-stepper v-model="currentStep" vertical flat>
            <template>      
              <v-stepper-step
                :color="primaryColor"
                :complete="
                  currentStep >
                  steps.findIndex((i) => i === 'selectFileToImport') + 1
                "
                :step="steps.findIndex((i) => i === 'selectFileToImport') + 1"
                @click="selectStep(1)"
                :style="{cursor: 'pointer'}"
              >
                {{ traduceItem("selectFile") }}

                <div
                v-if="currentStep > 1 && selectedFile"
                class="infoFileType"
                >
                   <FileToUpload
                    v-if="selectedFile"
                    :file="selectedFile"
                    :wrpClasses="wrpClasses"
                    @clearFile="clearFileFromOtherStep"
                  />             
                </div>
              </v-stepper-step>
              <v-stepper-content
                class="selectFileStep"
                :complete="
                  currentStep >=
                  steps.findIndex((i) => i === 'selectFileToImport') + 1
                "
                :step="steps.findIndex((i) => i === 'selectFileToImport') + 1 "
              >
              
                <ImportSourceSelector
                  :wrpClasses="wrpClasses"
                  :color="primaryColor"
                  :selectedSource="selectedImportSource"
                  :googleDeveloperKey="googleDeveloperKey"
                  :googleClientId="googleClientId"
                  :googleAppId="googleAppId"
                  @sourceSelected="changedSelectedImportSource"
                  @fileSelectedDrive="fileSelectedDrive"
                />                

                <template v-if="errorFileImport">
                  <v-alert
                    outlined
                    :type="'error'"
                    :icon="'mdi-alert-circle'"
                    color="red"
                    >{{ traduceItem(errorFileImport) }}
                  </v-alert>
                </template>
                <div class="import-wrp">
                  <Import
                    v-if="selectedImportSource === 'yourPc'"
                    class="importFileArea"
                    :title="traduceItem('importFileFromYourPc')"
                    :subtitle="traduceItem('clickOrDragToImport')"
                    :icon="'mdi-laptop'"
                    @file="fileSelected"
                  />
                  <FileToUpload
                    v-if="selectedFile"
                    :file="selectedFile"
                    :wrpClasses="wrpClasses"
                    @clearFile="clearFile"
                    class="clearFileContainer"
                  />
                </div>
              </v-stepper-content>
            </template>

            <!-- INPUT DEFINITIONS -->

            <template v-if="steps.includes('selectInputDefinition')">
              <v-stepper-step
              :color="primaryColor"
              :complete="
                currentStep > steps.findIndex((i) => i === 'selectInputDefinition') + 1
              "
              :step="steps.findIndex((i) => i === 'selectInputDefinition') + 1"
              @click="selectStep(2)"
              :style="{cursor: 'pointer'}"
            >
              {{ traduceItem("selectInputDefinition") }}
              <div
                v-if="currentStep > 2 && inputDefinition"
                class="infoFileType"
              >        
                <span>{{ traduceItem("selectedInputDefinition") }}</span>
                {{ inputDefinitionName }}
              </div>
            </v-stepper-step>
              <v-stepper-content
                class="selectFileStep"
                :step="
                  steps.findIndex((i) => i === 'selectInputDefinition') + 1
                "
              >
                <v-form
                  v-model="valid"
                  ref="inputDefinitionsForm"
                  @submit.prevent
                >
                  <v-col v-if="inputDefinitionsData" cols="12">
                    <v-autocomplete
                      v-model="inputDefinition"
                      :items="inputDefinitionsData"
                      :item-text="['name']"
                      :item-value="['id']"
                       dense
                       clearable
                      :label="traduceItem('inputDefinitions')"
                      :rules="[(v) => !!(v && v.length) || traduceItem('required')]"
                    ></v-autocomplete>
                  </v-col>
                </v-form>
              </v-stepper-content>
            </template>
            <!-- INPUT DEFINITIONS FILTERS-->

            <template v-if="steps.includes('selectFilters')">
              <v-stepper-step
                :color="primaryColor"
                :complete="
                  currentStep >
                  steps.findIndex((i) => i === 'selectFilters') + 1
                "
                :step="
                  steps.findIndex((i) => i === 'selectFilters') + 1
                "
                @click="selectStep(3)"
                :style="{cursor: 'pointer'}"
              >             
              <span>{{ traduceItem("selectFilters") }}</span>
              <div 
                  class="d-flex flex-wrap align-center infoFileType" 
                  v-if="currentStep > 3 && selectedFilters && selectedFilters.length"
                  >
                  <span class="mr-2">{{ traduceItem("filters") }}</span>
                  <v-chip class="mr-2 mb-2" v-for="(filter,index) in selectedFilters" :key="index">{{ filter }}</v-chip>
                </div>
              </v-stepper-step>

              <v-stepper-content
                class="selectFileStep"
                :class="[(currentStep === 3 && 'v-stepper__wrapper__outputDefinition'),(currentStep > 4 && 'selectedFilters__inactive')]"
                :step="
                  steps.findIndex((i) => i === 'selectFilters') + 1
                "
              >             
                <v-alert 
                  outlined 
                  :type="'info'" 
                  v-if="!filtersToSelect"
                  >{{ traduceItem("definitionHasNoFilters") }}
                </v-alert>
                  <Form
                    class="outputDefinition-container"
                    @updatedForm="updateFormValues($event), getStatus = false, submitForm = false"
                    :submitForm="submitForm"
                    :getStatus="false"
                    :resetForm="resetForm"
                    :currentDataValues="currentDataValues"
                    ref="filtersToSelect"
                    primaryColor="var(--primary)"
                    :textProps='{
                      allLabel: traduceItem("all"),
                      selectAllLabel: traduceItem("selectAll"),
                      unselectAllLabel: traduceItem("unselectAll"),
                      alertNoChanges: traduceItem("alertNoChanges")
                    }'
                    :fields="[
                      {
                        name: 'radioGroupFilters',
                        hideTitle: true,
                        fieldType: 'radioGroup',
                        dataSource: [{name: traduceItem('filtersOff'), value: 'filtersOff'}, {name: traduceItem('filtersOn'),value: true}],
                        optionsToCheck: [{field: traduceItem('filtersOff'), value: 'filtersOff'}, {field: traduceItem('filtersOn'),value: true}],
                        required: {msg: 'required'}
                      },
                      {
                        name: 'filtersToSelect',
                        label: traduceItem('filters'),
                        fieldType: 'tagBox',
                        returnObject: true,
                        optionalChipsAmount: true,
                        dataSource: filtersToSelect,
                        validators: {
                          dependsOn:[{
                            field: 'radioGroupFilters',
                            type: 'radioGroup',
                            value: true
                          }],
                          required: {msg: traduceItem('required')}
                        }
                      }]" 
                  />
              </v-stepper-content>
            </template>

            <!-- OUTPUT DEFINITIONS -->

            <template v-if="steps.includes('selectOutputDefinition')">
              <v-stepper-step
                :color="primaryColor"
                :complete="
                  currentStep >
                  steps.findIndex((i) => i === 'selectOutputDefinition') + 1
                "
                :step="
                  steps.findIndex((i) => i === 'selectOutputDefinition') + 1
                "
                @click="selectStep(4)"
                :style="{cursor: 'pointer'}"
              >             
                <div class="d-flex justify-space-between align-center">
                  <span>{{ traduceItem("selectOutputDefinition") }}</span>
                </div>
              </v-stepper-step>

              <v-stepper-content
                class="selectFileStep"
                :class="currentStep === 4 && 'v-stepper__wrapper__outputDefinition'"
                :step="
                  steps.findIndex((i) => i === 'selectOutputDefinition') + 1
                "
              >             
                <v-alert outlined :type="'info'"
                  >{{ traduceItem("selectOutputDefinitions") }}
                </v-alert>
                  <Form
                    class="outputDefinition-container"
                    @updatedForm="updateFormValues($event), getStatus = false, submitForm = false"
                    :currentDataValues="currentDataValues"
                    :submitForm="submitForm"
                    :getStatus="false"
                    :resetForm="resetForm"
                    ref="outputDefinitionsForm"
                    primaryColor="var(--primary)"
                    :textProps='{
                      allLabel: traduceItem("all"),
                      selectAllLabel: traduceItem("selectAll"),
                      unselectAllLabel: traduceItem("unselectAll"),
                      alertNoChanges: traduceItem("alertNoChanges")
                    }'
                    :fields="[{
                        name: 'outputDefinition',
                        label: traduceItem('outputDefinitions'),
                        fieldType: 'tagBox',
                        returnObject:true,
                        optionalChipsAmount: true,
                        dataSource: outputDefinitionsData
                      }]" 
                  />
                <div class="component-container">
                  <div
                    v-for="(definition, index) in outputDefinition"
                    :key="index"
                    class="fileToUpload d-flex align-center"
                  >
                    <v-icon>mdi-file-link-outline</v-icon>
                    <div class="d-flex flex-column">
                      <v-tooltip top allow-overflow>
                        <template v-slot:activator="{ on }">
                          <span
                            v-on="on"
                            :ref="definition + 'Selection'"
                            class="fileName"
                            >{{
                              definition.name
                            }}</span
                          >
                        </template>
                        <span class="ellipsisTooltip">{{
                          definition.name
                        }}</span>
                      </v-tooltip>
                      <div class="d-flex">
                        <span class="fileInfo size">
                          {{
                            outputDefinitionsData.find(
                              (definition) => definition.id === definition.id
                            ).fileName
                          }}
                        </span>
                      </div>
                    </div>
                    <v-btn
                      class="clearFile"
                      @click="clearOutputFile(index)"
                      depressed
                      plain
                      x-small
                      min-width="24px"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>
            </template>
          </v-stepper>
        </template>
      </div>

      <div class="footer">
        <v-btn
          v-if="currentStep - 1 === 0 || loading"
          outlined
          rounded
          class="cancelButton"
          @click="cancel('buttonCancel')"
        >
          <template>
            <v-icon left>mdi-close</v-icon>{{ traduceItem("cancel") }}
          </template>
        </v-btn>
        <v-btn
          v-else
          outlined
          rounded
          class="backButton"
          @click="currentStep = previousStep()"
        >
          <template>
            <v-icon left>mdi-chevron-left</v-icon>{{ traduceItem("back") }}
          </template>
        </v-btn>
        <v-btn
          rounded
          depressed
          :loading="loading"
          :disabled="loading"
          :color="primaryColor"
          @click="currentStep = nextStep()"
        >
          <template v-if="currentStep === steps.length">
            <v-icon left>mdi-play</v-icon>{{ traduceItem("execute") }}
          </template>
          <template v-else>
            {{ traduceItem("continue") }}<v-icon right>mdi-chevron-right</v-icon>
          </template>
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import { mapState, mapActions } from "vuex";
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import { theme } from "@/mixins/theme.js";
import i18n from "@/plugins/i18n";
import { uploadBytesResumable } from "firebase/storage";

export default {
  name: "ExecuteProccessModal",
  components: { ImportSourceSelector, FileToUpload },
  mixins: [tools, firebaseTools, theme],
  props: {
    modal: { type: [Boolean], default: false },
    primaryColor: { type: String, default: "#000" },
    height: { type: [String, Number], default: "100vh" },
  },
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    selectedFile: undefined,
    errorFileImport: undefined,
    onStep: ["selectFileToImport"],
    selectedImportSource: undefined,
    steps: [
      "selectFileToImport",
      "selectInputDefinition",
      "selectFilters",
      "selectOutputDefinition",
    ],
    defaultSteps: [
      "selectFileToImport",
      "selectInputDefinition",
      "selectFilters",
      "selectOutputDefinition",
    ],
    loading: false,
    currentStep: 1,
    isIntersecting: false,
    inputDefinition: undefined,
    inputDefinitionName: undefined,
    outputDefinition: [],
    selectedFilters: null,
    valid: false,
    validationFileData: undefined,
    formValues: {},
    submitForm: false,
    resetForm: false 
  }),
  computed: {
    ...mapState(["isExecuteProcessModalOpen", "actualGroup", "userLogged", "actualView", "inputDefinitionsData", "outputDefinitionsData"]),
    cssProps() {
      return {
        "--primary": this.primaryColor,
        "--height":
          typeof this.height === "number" ? this.height + "px" : this.height,
      };
    },
    currentDataValues() {
      return this.formValues
    },
    wrpClasses() {
      const importWrpWidth =
        this.isIntersecting &&
        this.modal &&
        this.$refs &&
        this.$refs.importWrp &&
        this.$refs.importWrp.offsetWidth;
      const vuetifyBreakpoint = this.$vuetify.breakpoint.width;
      const parentWidth = importWrpWidth || vuetifyBreakpoint;

      return {
        small: parentWidth < 499,
        medium: parentWidth >= 500 && parentWidth < 960,
        large: parentWidth >= 960 && parentWidth < 1253,
        xLarge: parentWidth >= 1253,
      };
    },
    filtersToSelect(){
      return this.inputDefinitionsData?.find(({id}) => id === this.inputDefinition)?.filters?.map(({name}) => ({ name: name })) || []
    }
  },
  watch: {
    inputDefinition() {
      this.selectedFilters = null;
      this.$refs.filtersToSelect.form.radioGroupFilters.optionChecked = 'filtersOff'
      this.updateFormValues({ radioGroupFilters: 'filtersOff', filtersToSelect: null, outputDefinition: [] })
      this.$refs.outputDefinitionsForm.$children[0].reset()
      this.resetForm = this.submitForm = true
      this.resetForm = this.submitForm = false
    },
    isExecuteProcessModalOpen() {
      this.$refs.inputDefinitionsForm.reset();
      this.outputDefinition = [];
      this.selectedFilters = 
      this.validationFileData = 
      this.errorFileImport =
      this.selectedImportSource =
      this.selectedFile =
      this.inputDefinition =
      this.inputDefinitionName =
        undefined;

      this.currentStep = 1;
      this.submitForm = false
      this.resetForm = this.isExecuteProcessModalOpen ? false : true;
      this.formValues = {}
    },
    selectedFile() {
      if (
        this.selectedFile &&
        this.selectedFile.name.split(".").pop() !== "csv"
      ) {
        this.errorFileImport = i18n.t("extensionErrorFile", {element: 'csv'});
        this.selectedFile = undefined;
      } else if (this.selectedFile) this.errorFileImport = undefined;
    },
  },
  methods: {
    ...mapActions(["setIsExecuteProcessModalOpen", "setShowSnackBar"]),
    clearOutputFile(index) {
      this.outputDefinition.splice(index, 1);
    },
    nextStep() {     
      this.showDropDownErrors = false;
      this.showDropDownNamePatterns = false;

      if (!this.selectedImportSource) {
        this.errorFileImport = "youMustSelectAnImportOption";
        return this.currentStep;
      }
      if (!this.selectedFile) {
        this.errorFileImport = "youMustSelectAtLeastOneFileToImport";
        return this.currentStep;
      }
      if (this.fileNamePatternError || this.extensionFileNameError) {
        return this.currentStep;
      }
     
      if(this.currentStep === 1){
        this.$refs.inputDefinitionsForm.reset();
      }else if (this.currentStep === 2 && !this.inputDefinition) {       
        this.$refs.inputDefinitionsForm.validate();
        return this.currentStep;
      }else if(this.currentStep === 2 && this.inputDefinition && !this.inputDefinitionName ){
        this.inputDefinitionName = this.inputDefinitionsData.find(
          (definition) => definition.id === this.inputDefinition).name
      } else if(this.currentStep === 3 && !this.valid) return this.currentStep
      
      if (this.currentStep === this.steps.length) {     
        if (this.selectedFile) {
          
           this.loading = true;           
           this.uploadFileToStorage(this.selectedFile);
        }
      }
      if (
        this.steps[this.currentStep - 1] ===
          this.defaultSteps[this.defaultSteps.length - 1] &&
        this.steps.includes(this.selectNodesStep)
      ) {
        this.submitFile(this.selectedFile, true);
      }

      return (this.currentStep === 1 &&
        this.$refs.fileTypeForm &&
        !this.$refs.fileTypeForm.validate()) ||
        this.currentStep === this.steps.length
        ? this.currentStep
        : this.currentStep + 1;
    },
    async uploadFileToStorage (file) {
      const { name } = file || 'Untitled'     
      /*
        https://firebase.google.com/docs/storage/web/upload-files?authuser=0
        documentation link
      */
      let inputDescriptorInfo = [{
        key:this.inputDefinition,
        value:this.inputDefinitionName
      }];         

      let outputDescriptorsInfo = [];
      this.outputDefinition.forEach(el => {
        outputDescriptorsInfo.push({key:el.id, value: el.name})
      });
      let process = {
        inputDescriptorInfo : inputDescriptorInfo,      
        inputFileName : this.selectedFile.name,      
        outputDescriptorInfo : this.currentDataValues.outputDefinition?.map(({id,name})=>({key: id, value: name})) || null,
        selectedFilterNames: this.currentDataValues.filtersToSelect?.map(({name})=>name) || null,
        status : "UPLOADING",
        startDate : new Date() ,
        endDate: null,
        numberReadPackages: 0,
        totalNumberPackages: -1,
        user : {
          email: this.userLogged.email,
          group: this.actualGroup.id,
          language: this.userLogged.lang,
          name: this.userLogged.displayName,
          photoUrl: this.userLogged.photoURL
        }
      };
      this.setIsExecuteProcessModalOpen(false);
      this.loading = false;
      let processId = await this.insertProcessInDB(process); 

      const fileToUploadRef = this.createStorageReference(processId + '/' + name);
      this.uploadTask = uploadBytesResumable(fileToUploadRef, file)

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      this.uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume     

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.log('ERROR: ', error)
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              this.handleFireBaseRequests(error.code)
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.log('CANCELLED')              
              this.loading = false
              break;

            default:              
              this.loading = false
              break;
          }
        }, 
        () => {
          // Handle successful uploads on complete
          this.updateDocument("process", processId, {...process, status: 'RUNNING'}).then(() => {
            this.executeProcess(processId);          
          })
        }
      );
    },
    insertProcessInDB(process){
      this.submitForm = true
      return this.insertDocument("process", process).then(
         (response) => {
           return response.id;
          }
          )
      .catch(err=>console.log(err))
    },
    executeProcess(processId) {
      let params = new FormData();        
      params.append("processId", processId)
      params.append("userLanguage",this.userLogged.lang)      
      params.append("userGroup", this.actualGroup.id)
      this.httpRequest(
        "post",
        [window.PROJECT_ENV.BASE_URL_SENDER + "execute", params],
        false
      )
      .then(response => {
          const validationError = response?.level === "VALIDATION_ERROR";
            this.setShowSnackBar({ 
              color: validationError ? 'error' : 'info', icon: "mdi-information", 
              msg: i18n.t(validationError ? 'VALIDATION_ERROR' : 'runningProcess'), 
              link: {
                to: 'ProcessHistory', 
                text: i18n.t('seeProcesses')
              }
            });
      })
      .catch((error) => {
        this.handleHttpStatus(error);
        this.loading = false;
      })
    },
    previousStep() {
      this.errorFileImport = null;
      this.showErrorSelectAtLeastOneFilePackage = false;    
      this.showDropDownErrors = false;
      this.showDropDownNamePatterns = false;
      return this.currentStep === 1 ? this.currentStep : this.currentStep - 1;
    },
    clearFile() {
      if (this.selectedImportSource === "drive")
        this.selectedImportSource = null;
      this.selectedFile = null;
      const inputFile = document.querySelector(
        ".import-wrp input[type='file']"
      );
      if (inputFile) inputFile.value = "";
    },
    clearFileFromOtherStep(){      
      this.clearFile();
      this.inputDefinition = null;
      this.outputDefinition = [];
      this.currentStep = 1;
    },
    changedSelectedImportSource(selectedSource) {
      this.selectedImportSource = selectedSource;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.showErrorSelectAtLeastOneFilePackage = false;
    },
    fileSelected(event) {
      if (event) {
        this.errorFileImport = null;
        this.selectedFile = event;
      }
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const blob = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileData.id}?alt=media`,
        fetchOptions
      ).then((res) => {
        this.loading = false;
        return res.blob();
      });
      const fileOptions = {
        type: fileData.mimeType,
        lastModified: fileData.lastEditedUtc,
        size: fileData.size,
      };

      this.selectedFile = new File([blob], fileData.name, fileOptions);
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    cancel(event) {
      if (!((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") || !this.isExecuteProcessModalOpen)
        return;

      const dirty = this.selectedFile || undefined;
      this.executeAction({
        action: "cancel",
        section: "executeProccess",
        dirty,
      });
    },
    downloadErrorsFile(file) {
      this.loading = true;
      const url = `${window.PROJECT_ENV.BASE_URL_SENDER}download?file=${encodeURIComponent(file)}&lang=${this.userLogged.lang}`;
      const method = "GET";
       this.downloadFile(
          url,
          method,
          file
        ).finally(() => (this.loading = false))    
    },
    selectStep(targetStep){
          if(targetStep > 1) {
            if (!this.selectedImportSource) {
            this.errorFileImport = "youMustSelectAnImportOption";
            return this.currentStep;
            }
            if (!this.selectedFile && targetStep > this.currentStep) {
              this.errorFileImport = "youMustSelectAtLeastOneFileToImport";
              return this.currentStep;
            }
            if (this.fileNamePatternError || this.extensionFileNameError) {
              return this.currentStep;
            }
          }
          if(targetStep > 2 && !this.inputDefinition) {
            this.$refs.inputDefinitionsForm.validate();
            return this.currentStep;
          } else if(this.currentStep === 2 && this.inputDefinition && !this.inputDefinitionName ) {
            this.inputDefinitionName = this.inputDefinitionsData.find((definition) => definition.id === this.inputDefinition).name
          }
          if(targetStep > 3 && !this.valid){
            return;
          }
          return this.currentStep = targetStep
    },
    updateOutputDefinition(event){
      const { outputDefinition } = event
      if(this.currentStep == 4 && outputDefinition) this.outputDefinition = outputDefinition.map(({id,name})=>({id,name}))
    },
    updateSelectedFilters({filtersToSelect, radioGroupFilters}) {
      if(this.currentStep == 3 && radioGroupFilters === true && filtersToSelect?.length) this.selectedFilters = filtersToSelect.map(({name})=>name), this.valid = true
      else if(this.currentStep == 3 && radioGroupFilters === true && !filtersToSelect?.length) this.valid = false
      else if(radioGroupFilters === false) this.selectedFilters = null, this.valid = true
    },
    updateFormValues(event) {
      this.formValues = { ...this.formValues, ...event }
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .importFileArea + .component-container  {
    margin-top: 20px;
  }
  .v-treeview-node__label {
    font-size: 14px;
  }
  .v-treeview-node__checkbox.v-icon {
    color: #ccc;
  }

  .selectFileStep {
    .v-stepper__wrapper {
      margin: -20px 0 0 -20px;
    }
  }

  .v-stepper__step--active .v-stepper__label {
    font-weight: 500;
  }

  .import-wrp {
    margin: 20px 0 0 20px;
    .dragFile {
      font-weight: 400;
    }
    .clickImport {
      color: var(--fontColorTerciary);
      font-weight: 500;
    }
    .mdi-laptop {
      color: var(--primary);
      margin: 27px 0 6px 0;
    }
  }
  .importFileArea {
    margin-bottom: 20px;
  }
  .content-container .v-stepper {
    min-height: 100%;
    .v-stepper__step--complete .v-stepper__label,
    .v-stepper__step--active .v-stepper__label {
      color: var(--fontColor);
    }

    .v-stepper__step--inactive .v-stepper__label {
      color: var(--fontColorTerciary);
    }
  }

  .dropArea {
    padding: 0 15px;
    max-height: 200px;
  }
}
.selectFileStep {
  .v-alert {
    margin: 20px 0 0 20px;
  }
  ::v-deep {
    .v-alert__icon {
      align-self: center;
    }
  }
}
.downloadButtonWrp {
  button.v-btn {
    letter-spacing: inherit;
    font-size: 14px;
    font-weight: 400;
    border-color: var(--borderGray);
    text-transform: none;
  }
}

#selectAllButton {
  font-weight: 400;
  text-transform: none;
  border-color: var(--borderGray);
}

.nodeTree {
  margin-top: 20px;
}
.page-container {
  display: flex;
  flex-direction: column;
  height: var(--height);
  position: relative;

  .v-alert {
    border-radius: 10px;
  }

  .content-container {
    overflow-y: auto;
    height: calc(var(--height) - 152px);

    .v-stepper__step {
      padding-left: 20px;
      align-items: baseline;
    }

    .v-stepper__step:first-child {
      padding-top: 0;
    }

    .v-stepper__step--complete {
      .infoFileType {
        font-size: 14px;
        margin-bottom: -48px;
        padding-top: 20px;
        color: var(--fontColor);
        span {
          color: var(--fontColorTerciary);
        }
      }

      &:first-child + .v-stepper__content {
        padding: 30px 60px 30px 23px;
      }
    }

    .v-stepper--vertical {
      .v-stepper__content {
        margin-left: 32px;
      }

      .v-stepper__content:not(:last-child) {
        border-left: 1px solid var(--borderGray);
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: var(--fontColor);
    width: 100%;
    height: 76px;
    align-items: center;

    h3 {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.footer {
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 20px;

  .cancelButton,
  .backButton {
    border-color: var(--borderGray);
    color: var(--fontColorSecondary);
  }

  .v-btn.v-btn--has-bg {
    color: white;
  }

  button {
    flex: 1;
    text-transform: none;
    font-weight: 400;
    i {
      font-size: 20px;
    }
    + button {
      margin-left: 10px;
    }
  }
}

/*Media queries*/

.page-container {
  &.small {
    .header,
    .footer {
      padding: 16px;
      height: 68px;
    }

    .content-container {
      overflow-y: auto;
      height: calc(var(--height) - 136px);
    }

    .v-stepper--vertical .v-stepper__step {
      padding: 16px;
    }

    .v-stepper--vertical .v-stepper__content {
      margin-left: 28px;
    }

    .downloadButtonWrp {
      #downloadFileIcon {
        margin: 0;
      }
      #downloadFileText {
        display: none;
      }
      > button#downloadButton {
        height: 36px;
        width: 36px;
        min-width: fit-content;
        padding: 0;
      }
    }
  }

  &.large {
    ::v-deep.dropArea {
      padding: 37px 0;
    }
  }

  &.xLarge {
    ::v-deep.dropArea {
      padding: 62px 0;
    }
  }
}
::v-deep {
  .fileToUpload {
    .clearFile {
      .v-btn__content {
        opacity: 1;
      }
    }
  }
}

.ellipsisTooltip {
  word-break: normal;
  overflow-wrap: anywhere;
}

.component-container {
  .fileToUpload {
    margin-bottom: 1rem;
    padding: 8px 10px;
    border: solid 1px var(--primary);
    border-radius: 10px;
    width: fit-content;
    color: var(--primary);
    max-width: 100%;
    > div {
      min-width: 0;
    }
    .mdi-file-link-outline {
      color: var(--primary);
    }
    .mdi-close-circle::before {
      font-size: 14px;
      color: var(--lightGray);
    }

    > * {
      padding: 0 5px;
    }
    .fileName {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .fileType {
      font-style: italic;
    }
    .fileInfo {
      font-size: 12px;
    }
    .size {
      font-weight: 500;
      margin-left: 3px;
    }
    button.clearFile {
      padding: 0;
      background-color: transparent !important;
    }
  }
}

/*Media queries*/
.component-container {
  &.small {
    .fileToUpload {
      .mdi-close-circle::before {
        font-size: 24px;
      }
      .fileName {
        font-size: 12px;
      }
    }
  }
}
.v-stepper__wrapper__outputDefinition {
  ::v-deep {
    .v-stepper__wrapper{
      display: contents;
    }
    .v-alert {
      margin: 0px;
    }

  }
}

</style>
