export default {
  "user": "Usuario",
  "password": "Contraseña",
  "loginUserError": "Usuario incorrecto",
  "loginPswdError": "Contraseña incorrecta",
  "loginEmailError": "Email incorrecto",
  "login": "Entrar",
  "loginWithGoogle": "Iniciar sesión con Google",
  "required": "Este campo es requerido",
  "internalServerError":
    "Error interno del servidor. Contacta con tu administrador",
  "updateAvailable": 'Hay una actualización disponible',
  "cancel": "Cancelar",
  "clone": "Clonar",
  "clon": "Clon de {element}",
  "save": "Guardar",
  "add": "Añadir",
  "edit": "Editar",
  "delete": "Eliminar",
  "deleteAll": "Eliminar todo",
  "update": "Actualizar",
  "more": "Más",
  "next": "Siguiente",
  "back": "Anterior",
  "filters": "Filtros",
  "columns": "Columnas",
  "importColumns": "Importar columnas",
  "loggedOut": "Has cerrado sesión correctamente",
  "clickToGoLogin": "Ir al login",
  "goBack": "Volver",
  "goProcessHistory": "Ir al histórico de procesos",
  "close": "Cerrar",
  "logout": "Cerrar sesión",
  "userConfiguration": "Configuración de usuario",
  "email": "Email",
  "ok": "Sí",
  "openMenu": "Abrir menú",
  "closeMenu": "Cerrar menú",
  "deleteLists": "Eliminar esta lista",
  "deleteListsQuestion": "¿Estás seguro de eliminar esta lista?",
  "deleteDictionaries": "Eliminar este diccionario",
  "deleteDictionariesQuestion": "¿Estás seguro de eliminar este diccionario?",
  "deleteInputDefinitions": "Eliminar esta definición de entrada",
  "deleteInputDefinitionsQuestion": "¿Estás seguro de eliminar esta definición de entrada?",
  "deleteOutputDefinitions": "Eliminar esta definición de salida",
  "deleteOutputDefinitionsQuestion": "¿Estás seguro de eliminar esta definición de salida?",
  "deleteInputDefinitionsDetailComponent": "Eliminar esta columna",
  "deleteInputDefinitionsDetailComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deleteOutputDefinitionsDetailComponent": "Borrar esta columna",
  "deleteOutputDefinitionsDetailComponentQuestion": "¿Estas seguro de eliminar esta columna?",
  "deleteInputDefinitionsDetail": "Eliminar esta definición de entrada",
  "deleteInputDefinitionsDetailQuestion": "¿Estás seguro de eliminar esta definición de entrada?",
  "deleteOutputDefinitionsDetail": "Eliminar esta definición de salida",
  "deleteOutputDefinitionsDetailQuestion": "¿Estás seguro de eliminar esta definición de salida?",
  "deletecolumnsInComponent": "Eliminar columna",
  "deletecolumnsInComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deletecolumnsOutComponent": "Eliminar columna",
  "deletecolumnsOutComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deletefiltersComponent": "Eliminar filtro",
  "deletefiltersComponentQuestion": "¿Estas seguro de eliminar este filtro?",
  "deletedeleteSplitConfigurationComponent": "Eliminar toda la configuración",
  "deletedeleteSplitConfigurationComponentQuestion": "¿Estás seguro de que deseas eliminar toda la configuración?",
  "deletedeleteRowComponent": "Eliminar fila",
  "deletedeleteRowComponentQuestion": "¿Estás seguro de que deseas eliminar esta fila?",
  "deletedeleteHeaderCellComponent": "Eliminar columna",
  "deletedeleteHeaderCellComponentQuestion": "¿Estás seguro de que deseas eliminar esta columna?",
  "deletedeleteColumnComponent": "Eliminar columna",
  "deletedeleteColumnComponentQuestion": "¿Estás seguro de que deseas eliminar esta columna?",
  "deleteProcessHistory": "Eliminar proceso",
  "deleteProcessHistoryQuestion": "¿Estas seguro de eliminar este proceso?",
  "cloneInputDefinitions": "Clonar definicion de entrada",
  "cloneOutputDefinitions": "Clonar definicion de salida",
  // MENU
  "Home": "Inicio",
  "ProcessHistory": "Histórico de procesos",
  "InputDefinitions": "Definiciones de entrada",
  "InputDefinitionsDetail": "Definiciones de entrada",
  "OutputDefinitions": "Definiciones de salida",
  "Dictionaries": "Diccionarios",
  "Lists": "Listas",
  "error": {
    "sorry": "Lo sentimos",
    "notAllowed": "este usuario no está permitido",
    "notFound": "esta página no existe",
    "noWorries": "Pero no te preocupes, siempre puedes",
    "contactAdmin": "Por favor, contacta con tu administrador",
    "problem": "hubo un problema...",
    "canNotTellWhy": "y no puedo decirte porqué...",
    "emailBlocked": "Este usuario no está autorizado. Solo pueden acceder usuarios con una cuenta de correo corporativa de NFQ",
    "add": {
      "Dictionaries": "No se ha podido añadir el diccionario {element}",
      "Lists": "No se ha podido añadir la lista {element}",
      "InputDefinitions": "No se ha podido añadir la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido añadir la definición de salida {element}",
      "InputDefinitionsDetail": "No se ha podido añadir la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido añadir la definición de salida {element}",
      "ProcessHistory": "No se ha podido ejecutar el proceso"
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "No se ha podido añadir el valor"
    },
    "editCell": {
      "OutputDefinitionsDetail": "No se ha podido modificar el valor"
    },
    "edit": {
      "Dictionaries": "No se ha podido modificar el diccionario {element}",
      "Lists": "No se ha podido modificar la lista {element}",
      "InputDefinitions": "No se ha podido modificar la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido modificar la definición de salida {element}"
    },
    "delete": {
      "Dictionaries": "No se ha podido eliminar el diccionario {element}",
      "Lists": "No se ha podido eliminar la lista {element}",
      "InputDefinitions": "No se ha podido eliminar la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido eliminar la definición de salida {element}",
      "InputDefinitionsDetail": "No se ha podido eliminar la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido eliminar la definición de salida {element}"
    },
    "clone": {
      "InputDedinitions": "No se ha podido clonar la definición de entrada {element}",
      "OutputDedinitions": "No se ha podido clonar la definición de entrada {element}"
    },
    "addColumn": {
      "InputDefinitionsDetail": "No se ha podido añadir la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido añadir la columna {element}"
    },
    "editColumn": {
      "InputDefinitionsDetail": "No se ha podido modificar la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido modificar la columna {element}"
    },
    "editParent": {
      "InputDefinitionsDetail": "No se ha podido modificar la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido modificar la definición de salida {element}"
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "No se ha podido eliminar la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido eliminar la columna {element}"
    },
    "dragging": {
      "OutputDefinitionsDetail": "No se ha podido modificar la posición de la columna {element}"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "No se ha(n) podido añadir la(s) columna(s)"
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "No se ha podido modificar la columna {element}",
    },
    "addRow": {
      "OutputDefinitionsDetail": "No se ha podido añadir la fila"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "No se ha podido eliminar la columna {element}",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "No se ha podido eliminar la fila",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "No se ha podido eliminar toda la configuración",
    },
  },
  "noData": {
    "Dictionaries": "No hay diccionarios. Por favor, recuerda importar o crear un diccionario",
    "Lists": "No hay listas. Por favor, recuerda importar o crear una lista",
    "InputDefinitions": "No hay definiciones de entrada. Por favor, recuerda importar o crear una definición de entrada",
    "OutputDefinitions": "No hay definiciones de salida. Por favor, recuerda importar o crear una definición de salida",
    "InputDefinitionsDetail": "No hay columnas. Por favor, recuerda crear una columna",
    "OutputDefinitionsDetail": "No hay columnas. Por favor, recuerda crear una columna",
    "ProcessHistory": "No hay procesos ejecutados. Por favor, recuerde ejecutar un proceso",
    "allowedValuesValidation": {
      "lists": "No hay listas creadas",
      "dictionaries": "No hay diccionarios creados"
    },
    "columnsIn": "No hay columnas. Por favor, recuerda crear una columna",
    "columnsOut": "No hay columnas. Por favor, recuerda crear una columna",
    "filters": "No hay filtros. Por favor, recuerda crear un filtro",
    "splitConfiguration": "No hay separación de filas. Por favor, recuerda crear una columna primero para poder separar filas",
    "filtersNoActions": "No hay filtros. Por favor, recuerda crear una columna primero para poder crear un filtro"
  },
  "searchLabel": {
    "Dictionaries": "Buscar diccionario",
    "Lists": "Buscar lista",
    "InputDefinitions": "Buscar definición de entrada",
    "ProcessHistory": "Buscar proceso",
    "OutputDefinitions": "Buscar definición de salida",
    "InputDefinitionsDetail": "Buscar definición de columna",
    "OutputDefinitionsDetail": "Buscar definición de columna",
  },
  "success": {
    "add": {
      "Dictionaries": "Se ha añadido el diccionario {element} correctamente",
      "Lists": "Se ha añadido la lista {element} correctamente",
      "InputDefinitions": "Se ha añadido la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha añadido la definición de salida {element} correctamente",
      "InputDefinitionsDetail": "Se ha añadido la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha añadido la definición de salida {element} correctamente",
      "ProcessHistory": "El proceso se ha iniciado correctamente. Accede a la pantalla Histórico de procesos para descargar el fichero"
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "Se ha añadido el valor correctamente"
    },
    "editCell": {
      "OutputDefinitionsDetail": "Se ha modificado el valor correctamente"
    },
    "edit": {
      "Dictionaries": "Se ha modificado el diccionario {element} correctamente",
      "Lists": "Se ha modificado la lista {element} correctamente",
      "InputDefinitions": "Se ha modificado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha modificado la definición de salida {element} correctamente",
      "ProcessHistory": "El proceso se ha iniciado correctamente. Accede a la pantalla Histórico de procesos para descargar el fichero"
    },
    "delete": {
      "Dictionaries": "Se ha eliminado el diccionario {element} correctamente",
      "Lists": "Se ha eliminado la lista {element} correctamente",
      "InputDefinitions": "Se ha eliminado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha eliminado la definición de salida {element} correctamente",
      "InputDefinitionsDetail": "Se ha eliminado la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha eliminado la definición de salida {element} correctamente",
      "process": "Se ha eliminado el proceso correctamente",
      "allProcess": "Se han eliminado todos los procesos correctamente"
    },
    "clone": {
      "InputDefinitions": "Se ha clonado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha clonado la definición de salida {element} correctamente"
    },
    "addColumn": {
      "InputDefinitionsDetail": "Se ha añadido la columna {element} correctamente",
      "OutputDefinitionsDetail": "Se ha añadido la columna {element} correctamente"
    },
    "addFilter": {
      "InputDefinitionsDetail": "Se ha añadido el filtro {element} correctamente",
    },
    "editColumn": {
      "InputDefinitionsDetail": "Se ha modificado la columna {element} correctamente",
      "OutputDefinitionsDetail": "Se ha modificado la columna {element} correctamente"
    },
    "editFilter": {
      "InputDefinitionsDetail": "Se ha modificado el filtro {element} correctamente",
    },
    "editParent": {
      "InputDefinitionsDetail": "Se ha modificado la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha modificado la definición de salida {element} correctamente"
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "Se ha eliminado {element} correctamente",
      "OutputDefinitionsDetail": "Se ha eliminado {element} correctamente"
    },
    "dragging": {
      "OutputDefinitionsDetail": "Se ha modificado la posición de la columna {element} correctamente"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "Se ha(n) añadido la(s) columna(s) correctamente",
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "Se ha modificado la columna {element} correctamente",
    },
    "addRow": {
      "OutputDefinitionsDetail": "Se ha añadido la fila correctamente"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "Se ha eliminado la columna {element} correctamente",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "Se ha eliminado la fila correctamente",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "Se ha eliminado toda la configuración correctamente",
    },
  },
  "addDictionary": "Añadir diccionario",
  "addList": "Añadir lista",
  "editList": "Editar lista",
  "editDictionary": "Editar diccionario",
  "addInputDefinition": "Añadir definición",
  "addOutputDefinition": "Añadir definición",
  "editInputDefinitions": "Editar definición de entrada",
  "addInputDefinitions": "Añadir definición de entrada",
  "addOutputDefinitions": "Añadir definición de salida",
  "editOutputDefinitions": "Editar definición de salida",
  "name": "Nombre",
  "selectAll": "Seleccionar todos",
  "unselectAll": "Deseleccionar todos",
  "noSearchData": "No hay resultados que coincidan con tu búsqueda",
  "discardChangesTitle": "Descartar cambios",
  "discardChangesText": "¿Estás seguro de que quieres descartar los cambios?",
  "fieldRequired": "Este campo es requerido",
  "all": 'Todos',
  "nameAlreadyExists": 'Este nombre está repetido',
  "hasBeenProblemTryAgain": 'Ha habido un problema y no podemos mostrarte los datos. Inténtalo de nuevo más tarde',
  "unexpectedError": "Lo sentimos, ha ocurrido un error inesperado. Si persiste, contacta con tu administrador",
  "resultsFound": "Resultados encontrados",
  "expiredSession": "Tu sesión ha caducado. Por favor, vuelve a iniciar sesión",
  "alertNoChanges": "Tienes que realizar algún cambio antes de guardar",
  "executeProcess": "Ejecutar proceso",
  "trafis-ui": "NFQ Trafis",
  "key": "Clave",
  "value": "Valor",
  "valueRepeated": "Este valor está repetido",
  "keyRepeated": "Esta clave está repetida",
  "columnRepeated": "Esta columna está repetida",
  "status": "Estado",
  "date": "Fecha",
  "userImage": "Usuario",
  "startTime": "Hora de inicio",
  "endTime": "Hora de fin",
  "inputFileName": "Nombre de fichero de entrada",
  "delimiterColumns": "Separador de columnas",
  "processId": "Id del proceso",
  "inputDefinitionName": "Definición de entrada",
  "outputDefinitionNames": "Definiciones de salida",
  "download-file": "Descargar fichero",
  "download-validations": " Descargar validaciones",
  "fileType": "Tipo de archivo",
  "headerRow": "Fila de la cabecera",
  "headerRowTooltip": "Si no informas este campo, se asumirá que los datos empiezan en la primera fila del fichero",
  "optionalParameters": "Parámetros opcionales",
  "defaultDatePattern": "Formato de fecha",
  "defaultDatePatternTooltip": "Si no informas este campo en una o varias columnas de tipo fecha, se asumirá el formato por defecto para poder leerlas",
  "defaultDecimalSeparator": "Separador decimal",
  "defaultDecimalSeparatorTooltip": "Si no informas este campo en una o varias columnas de tipo número, se asumirá el separador decimal por defecto para poder leerlas",
  "comma": "Coma",
  "dot": "Punto",
  "fileName": "Nombre del archivo",
  "alias": "Nombre de entrada",
  "position": "Posición",
  "dataType": "Tipo de dato",
  "nullable": "Acepta valores nulos",
  "COMMA": "Coma",
  "DOT": "Punto",
  "EMPTY": "Vacío",
  "NOT_EMPTY": "No vacío",
  "REGULAR_EXPRESSION": "Expresión regular",
  "columnsDefinitions": "Definición de columnas",
  "filtersDefinitions": "Definición de filtros",
  "addColumn": "Añadir columna",
  "addColumns": "Añadir columnas",
  "addFilter": "Añadir filtro",
  "positionTooltip": "Si no informas este campo, la posisición se rellenará automaticamente con su posición en la lista",
  "sumValues": "Suma valores",
  "optionalValidations": "Validaciones opcionales",
  "newAlias": "Nombre de salida",
  "transformationType": "Tipo de transformación",
  "OutputDefinitionsDetail": "Definición de salida",
  "datePattern": "Formato de fecha",
  "datePatternTooltip": "Este campo es obligatorio si seleccionas el tipo de dato Date y siempre que no hayas informado el valor por defecto al crear la definición",
  "decimalSeparator": "Separador decimal",
  "decimalSeparatorTooltip": "Este campo es obligatorio si seleccionas el tipo de dato Double o Percentage y siempre que no informes el valor por defecto al crear la definición",
  "sumValuesTooltip": "Si activas este switch, se realizará un acumulado de las filas válidas y no válidas de esta columna",
  "positionAlreadyExists": "La posición elegida ya está en uso",
  "editColumn": "Editar columna",
  "editFilter": "Editar filtro",
  "conditions": "Condiciones",
  "condition": "Condición",
  "selectedFilterNames": "Filtros",
  "selectFilters": "Selecciona, si quieres, uno o varios filtros",
  "definitionHasNoFilters": "Esta definición no tiene filtros asociados",
  "validationOptionalType": "Tipo de validación opcional",
  "allowedValuesValidation": "Valores permitidos",
  "filtersOff": "Sin filtros",
  "filtersOn": "Con filtros",
  "range": "Rango",
  "from": "Desde",
  "to": "Hasta",
  "rangeType": "Tipo de rango",
  "OPEN": "Abierto",
  "CLOSE": "Cerrado",
  "OPEN_CLOSE": "Abierto - Cerrado",
  "CLOSE_OPEN": "Cerrado - Abierto",
  "allowedValuesValidationTooltip": "En este campo puedes asociar un diccionario o una lista de valores para validar que tus valores pertenecen a ese diccionario (claves) o esa lista",
  "runProcess": "Ejecutar proceso",
  "selectFile": "Selecciona el fichero que deseas transformar",
  "yourPc": "Tu PC",
  "selectInputDefinition": "Selecciona una definición de entrada",
  "selectedInputDefinition": "Definición de entrada seleccionada",
  "selectOutputDefinition": "Selecciona una definición de salida",
  "youMustSelectAnImportOption": "Debes seleccionar una opción de importación",
  "importFileFromYourPc": "Importa ficheros desde tu PC",
  "clickOrDragToImport": "Haz click o arrastra para importar",
  "youMustSelectAtLeastOneFileToImport": "Debes seleccionar al menos un fichero para importar",
  "inputDefinitions": "Definición de entrada",
  "outputDefinitions": "Definiciones de salida",
  "splitConfiguration": "Separación filas",
  "splitConfigurationDefinitions": "Separación de filas",
  "selectOutputDefinitions": "Selecciona una o varias definiciones de salida sólo si deseas transformar el fichero",
  "noDataAutocomplete": "No hay datos disponibles",
  "extensionErrorFile": "La extensión de este fichero no es compatible. Debes seleccionar un fichero .{element}",
  "runningProcess": "El proceso se ha ejecutado correctamente.",
  "seeProcesses": "Accede a la pantalla Histórico de procesos para descargar el fichero",
  "addTenor": "Añadir tenor",
  "dictionary": "Diccionario",
  "dateColumnName": "Columna fecha",
  "timeStepColumnName": "Columna tenor",
  "tenor": "Tenor",
  "limitDate": "Fecha límite",
  "operator": "Operador",
  "aliasTooltip": "Este campo es obligatorio si quieres modificar el alias de entrada",
  "decimalSeparatorTooltipOutput": "Este campo es obligatorio si quieres modificar el separador decimal",
  "datePatternTooltipOutput": "Este campo es obligatorio si quieres modificar el formato de fecha",
  "limitDateTooltipOutput": "Si informas este campo, se sumará el tenor al valor de tu columna hasta que se alcance la fecha límite",
  "dictionaryName": "Nombre del diccionario",
  "finishProcess": "El proceso se ha ejecutado correctamente",
  "seeFileProcesses": "Accede a la pantalla Histórico de procesos para descargar el fichero",
  "INTEGER": "Integer",
  "DOUBLE": "Double",
  "DATE": "Date",
  "STRING": "String",
  "BOOLEAN": "Boolean",
  "PERCENTAGE": "Percentage",
  "MAIL": "Mail",
  "noDataInfoColumn": "No hay más información sobre esta columna",
  "rangeValidation": "Validación de rango",
  "validationOfAllowedValues": "Validación de valores permitidos",
  "parametersTransfDictionary": "Parámetros de la transformación de diccionario",
  "parametersTransfAddTenor": "Parámetros de la transformación añadir tenor",
  "timeStepValue": "Tenor",
  "arithmeticOperator": "Operador",
  "ADD": "+",
  "SUBTRACT": "-",
  "DICTIONARY": "Diccionario",
  "ADD_TIME_STEP": "Añadir tenor",
  "TENOR": "Tenor",
  "continue": "Continuar",
  "execute": "Ejecutar",
  "errorExecuteProcess": "No se ha podido ejecutar el proceso.",
  "modifyPosition": "Modificar posición",
  "keepPosition": "Mantener posición",
  "disabledDragTooltip": "Puedes modificar la posición haciendo click en el botón 'Modificar posición'",
  "disabledDragSearchingTooltip": "Esta acción no está disponible cuando aplicas filtros en el buscador",
  "disabledExpandTooltip": "Esta acción no está disponible cuando modificas la posición",
  "yes": "Sí",
  "no": "No",
  "valueGreaterThanZero": "El valor debe ser mayor que 0",
  "valueCombinationRepeated": "Esta combinación de alias de entrada y alias de salida ya existe",
  "addFilters": "Añadir filtro",
  "editFilters": "Editar filtro",
  "indicateFilterName": "Indica el nombre del filtro",
  "filterName": "Nombre del filtro",
  "defineConditions": " Define sus condiciones",
  "firstTerm": "Indica el primer término",
  "indicateConditional": "Indica el condicional",
  "conditional": "Condicional",
  "secondTerm": "Indica el segundo término",
  "columnAlias": "Alias de la columna",
  "indicateConditionalNotOk": "Indica el valor si la condición no se cumple",
  "reviewValidationsFilters": "Revisa las validaciones del formulario para poder añadir el filtro",
  "reviewValidationsColumns": "Revisa las validaciones del formulario para poder añadir la columna",
  "indicateColumnName": "Indica el nombre de la columna",
  "nameColumn": "Nombre de la columna",
  "defineCreateColumn": "Define como crear la columna",
  "withOutConditions": "Sin condiciones",
  "withConditions": "Con condiciones",
  "createColumn": "Crear columna",
  "defineHowSetValue": "Define cómo asignar el valor",
  "assignValue": "Asignar valor",
  "concatenateValues": "Concatenando valores",
  "defineCases": "Define los casos",
  "inputDefinitionAssociate": "Asocia, si quieres, una definición de entrada",
  "inputDescriptorReferenceTooltip": "Si asocias una definición de entrada te será más fácil crear las columnas de salida",
  "deleteAllProcess": "Eliminar procesos",
  "deleteAllProcessTitle": "Eliminar todos los procesos",
  "deleteAllProcessQuestion": "¿Estás seguro de eliminar todos los procesos? Los procesos que se están ejecutando no se eliminarán",
  "deleteProcessTitle": "Eliminar proceso",
  "deleteProcessQuestion": "¿Estás seguro de eliminar este proceso?",
  "downloadingFile": "El fichero se está descargando",
  "errorDownloadFile": "No se ha podido descargar el fichero",
  "inputColumnName": "Nombre columna de entrada",
  "nameOfTenorTypeColumn": "Nombre de una columna tipo Tenor",
  "fixedValueForTenor": "Valor fijo para el Tenor",
  "indicateDateToReach": "Indica, si quieres, la fecha que deseas alcanzar",
  "nameOfDateTypeColumn": "Nombre de una columna tipo Fecha",
  "fixedValueForDate": "Valor fijo para la Fecha",
  "columnReferenced": "Columna referenciada",
  "valuesToConcatenate": "Valores a concatenar",
  "caseConditions": "Condiciones caso",
  "firstTermField": "Primer término",
  "indicateHowToAssingValueCase": "Indica cómo asignar valor al caso",
  "case": "Caso",
  "notConditionsApllied": "Sin condiciones aplicadas",
  "alertInfoConditionsEmpty": "Si no añades ninguna condición, este caso se aplicará cuando las condiciones no se cumplan",
  "alertErrorConditionsEmpty": "No puedes añadir más de un caso sin condiciones aplicadas",
  "eachCase": "En cada caso",
  "addTimeStepTransformation": "Añadir tenor",
  "dictionaryTransformation": "Diccionario",
  "joinColumns": "Valores concatenados",
  "INPUT_VALUE": "Valor fijo",
  "COLUMN_NAME": "Referenciando una columna",
  "WILDCARD": "Wildcard",
  "assignedValue": "Valor asignado",
  "assignedValueBy": "Valor asignado mediante",
  "tenorValue": " para el tenor",
  "targetDate": "Fecha que deseas alcanzar",
  "columnsIn": "Buscar definición de columna",
  "columnsOut": "Buscar definición de columna",
  "noConditionsApplied": "Sin condiciones aplicadas",
  "inputDescriptorReference": "Definición de entrada",
  "conditionals": "Condicionales",
  "selectImportFile": "Elige el tipo de fichero que vas a importar",
  "successfulImport": "El fichero {element} se ha importado correctamente",
  "uploadedImport": "El fichero {element} se está importando",
  "sheetName": "Nombre pestaña documento xlsx",
  "columnsToRead": "Indica las columnas que se deben leer",
  "rowsToRead": "Indica las filas que se deben leer",
  "inputAlias": "Nombre columna Alias",
  "positionColumn": "Nombre columna Posición",
  "mandatoryColumn": "Nombre columna Obligatoriedad",
  "dataTypeColumn": "Nombre columna Tipo de dato",
  "filter": "Filtro",
  "filterColumnName": "Alias de la columna",
  "filterColumnNameSection": "Indica el alias de la columna sobre la que se aplica el filtro",
  "filterColumnValue": "Indica el valor",
  "selectFileToImport": "Selecciona el fichero a importar",
  "import": "Importar",
  "addRow": "Añadir fila",
  "rowColumnInformation": "Información Fila {index} - {column}",
  "valueAssigned": "Valor asignado mediante {item}",
  "row": "Fila",
  "rows": "Filas",
  "addCellValue": "Añadir valor {element}",
  "defineHowToCreateValue": "Define como crear el valor",
  "createValue": "Crear valor",
  "editCellValue": "Editar valor {element}",
  "shouldBeGreatherThan": "El valor debe ser mayor que {element}",
  "withoutFilters": "Sin filtros",
  "filterByColumn": "Filtradas a partir de una columna",
  "fixedValue": "Valor fijo",
  "wildcard": "Wildcard",
  "columnReference": "Referenciando una columna",
  "validationOf": "Validación de {validation}",
};
